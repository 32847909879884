/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { postPerfilAcesso } from '../../services/perfilAcesso'
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { Scrollbars } from 'react-custom-scrollbars';

export default function ModalLead(props) {
    let navigate = useNavigate();

    const {
        open,
        setOpen,
        tituloModal,
        lead
    } = props

    const descricao = '';
    const [salvando, setSalvando] = useState(false)

    async function handleSalvar() {
        if (!descricao.length) {
            toast.error('Preencha a descrição', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        }
        setSalvando(true)

        const novoPerfilAcesso = {
            descricao: descricao
        }

        const response = await postPerfilAcesso(novoPerfilAcesso)
        if (response.sucesso) {
            toast.success('Novo Perfil de Acesso adicionado', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            navigate(`/perfil-acesso/form`, { state: { "idPerfil": response.data } });
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setSalvando(false)
    }

    function handleCloseModal() {
        if (!salvando) {
            setOpen(false)
        }
    }

    const celularMask = (c) => {
        if (c.length < 11) {
            return c.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
        } else {
            return c.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
        }
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 overflow-hidden z-40" onClose={handleCloseModal}>
                <div className="absolute inset-0 overflow-hidden">
                    <Dialog.Overlay className="absolute inset-0" />

                    <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="w-screen max-w-md">
                                <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                                    <div className="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll">
                                        <div className="px-4 sm:px-6">
                                            <div className="flex items-start justify-between">
                                                <Dialog.Title className="text-lg font-medium text-gray-900">{tituloModal}</Dialog.Title>
                                                <div className="ml-3 h-7 flex items-center">
                                                    <button
                                                        type="button"
                                                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                                        onClick={handleCloseModal}
                                                    >
                                                        <span className="sr-only">Cancelar</span>
                                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-6 relative flex-1 flex px-4 sm:px-6">
                                            <div className="col-span-6 flex-1 gap-4 flex flex-col sm:col-span-3">

                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">Nome:</label>
                                                    <p>{lead.nome}</p>
                                                </div>
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">Email:</label>
                                                    <p>{lead.email}</p>
                                                </div>
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">Telefone:</label>
                                                    <p>{lead.telefone && celularMask(lead.telefone)}</p>
                                                </div>
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">Empresa:</label>
                                                    <p>{lead.empresa}</p>
                                                </div>


                                                <label className="block text-sm font-medium text-gray-700">Mensagem:</label>
                                                <div className="flex-1 h-0 overflow-y-hidden">
                                                    <Scrollbars
                                                        autoHideDuration={200}
                                                        thumbMinSize={30}
                                                        universal={true}
                                                    >
                                                        <p>{lead.mensagem}</p>
                                                    </Scrollbars>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                                        <button
                                            type="button"
                                            disabled={salvando}
                                            className={`${salvando ? "cursor-not-allowed" : "cursor-pointer"} bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                                            onClick={handleCloseModal}
                                        >
                                            Cancelar
                                        </button>
                                        <button
                                            type="submit"
                                            onClick={() => handleSalvar()}
                                            disabled={salvando}
                                            className={`${salvando ? "cursor-not-allowed" : "cursor-pointer"} ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                                        >
                                            {salvando && <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>}
                                            Salvar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog >
        </Transition.Root >
    )
}