import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import LayoutSwitch from "../../components/LayoutSwitch";
import Select from 'react-select';
import Dropzone from '../../components/Dropzone'
import verificaAcesso from '../../utils/verificaAcesso';
import ModalAlterarSenha from './modal';
import { toast } from 'react-toastify';
import useDebounce from "../../hooks/useDebounce";

import {
  ShieldCheckIcon,
  ShieldExclamationIcon,
  LockClosedIcon
} from '@heroicons/react/solid'

import LoadingPage from "../../components/LoadingPage";

import { alterarSenhaUsuario, getUsuarioById, postUsuario, putUsuario } from "../../services/usuario";
import { getListSelectPerfilAcesso } from "../../services/perfilAcesso";
import { downloadArquivo, uploadArquivo } from "../../services/arquivo";
import API_URL from "../../utils/api";
import { getSelectRevenda } from "../../services/revenda";
import { getSelectContador } from "../../services/contador";

export default function UsuarioForm() {
  let navigate = useNavigate();
  const location = useLocation();

  let idUsuario = location.state ? location.state.idUsuario : null;

  const salvarDebounce = useDebounce(handleSalvarUsuario, 500)

  //acessos
  const desc_funcionalidade = "USUARIO";
  const [acessoVerificado, setAcessoVerificado] = useState(false);
  const [listar, setListar] = useState('')
  const [editar, setEditar] = useState('')
  const [alterarSenha, setAlterarSenha] = useState('')

  const [loading, setLoading] = useState(false);
  const [salvandoCadastro, setSalvandoCadastro] = useState(false);
  const [salvandoSenha, setSalvandoSenha] = useState(false);

  const [foto, setFoto] = useState('')
  const [foto_url, setFotoUrl] = useState(null)
  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [ativo, setAtivo] = useState('')
  const [usuarioCadastro, setUsuarioCadastro] = useState(null)
  const [dataCadastro, setDataCadastro] = useState(null)
  const [usuarioUltimaAlteracao, setUsuarioUltimaAlteracao] = useState(null)
  const [dataUltimaAlteracao, setDataUltimaAlteracao] = useState(null)

  const [senha, setSenha] = useState(null)
  const [senhaConfirmada, setSenhaConfirmada] = useState(null)
  const [verificandoSenha, setVerificandoSenha] = useState(null)
  const [statusConfirmacao, setStatusConfirmacao] = useState(null)

  const [perfilAcesso, setPerfilAcesso] = useState('')
  const [opcoesPerfilAcesso, setOpcoesPerfilAcesso] = useState([])

  const [revenda, setRevenda] = useState('')
  const [opcoesRevenda, setOpcoesRevenda] = useState([]);
  const revendaDebounce = useDebounce(carregaOpcoesRevenda, 500)
  const [pesquisaRevenda, setPesquisaRevenda] = useState("")

  const [contador, setContador] = useState('')
  const [opcoesContador, setOpcoesContador] = useState([]);
  const contadorDebounce = useDebounce(carregaOpcoesContador, 500)
  const [pesquisaContador, setPesquisaContador] = useState("")

  const [modal, setModal] = useState(false)

  useEffect(() => {
    if (!acessoVerificado) {
      verificaAcesso(location.pathname.split("/")[1],
        { acao: setListar, nome: desc_funcionalidade + "_LISTAR" },
        { acao: setEditar, nome: desc_funcionalidade + "_EDITAR" },
        { acao: setAlterarSenha, nome: desc_funcionalidade + "_ALTERAR_SENHA" },
      )
      setAcessoVerificado(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acessoVerificado])

  useEffect(() => {
    if (acessoVerificado) {
      if (listar) {
        if (idUsuario) carregaUsuario()
      } else {
        toast.error(`Sem acesso a Visualização de ${desc_funcionalidade}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setTimeout(() => {
          navigate('/')
        }, 100)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listar])

  useEffect(() => {
    carregaOpcoesPerfilAcesso()
    carregaOpcoesRevenda()
    carregaOpcoesContador()

    if (idUsuario) {
      carregaUsuario()
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idUsuario])

  useEffect(() => {
    if (senha) {
      verificaForcaSenha()
      verificaConfirmacaoSenha()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [senha])

  useEffect(() => {
    if (senhaConfirmada) {
      verificaConfirmacaoSenha()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [senhaConfirmada])

  function verificaForcaSenha() {
    if (!senha) setVerificandoSenha(null)

    else {
      setVerificandoSenha("Verificando Senha")

      setTimeout(() => {
        if (senha.length > 7) setVerificandoSenha("Senha Forte")
        else if (senha.length <= 7) setVerificandoSenha("Senha Fraca")
      }, 300);
    }
  }

  function verificaConfirmacaoSenha() {
    if (!senhaConfirmada || !senha) setStatusConfirmacao(null)

    else {
      setStatusConfirmacao("Aguardando Confirmação")

      setTimeout(() => {
        if (senha === senhaConfirmada) setStatusConfirmacao("Senha Confirmada")
        else if (senha !== senhaConfirmada) setStatusConfirmacao("Senhas Diferentes")
      }, 300);
    }
  }

  async function carregaUsuario() {
    setLoading(true)

    const response = await getUsuarioById(idUsuario)

    if (response.sucesso) {
      setFoto(response.data.arquivoFoto)
      setNome(response.data.nome)
      setEmail(response.data.email)
      setPerfilAcesso({ value: response.data.idPerfilAcesso, label: response.data.descricaoPerfilAcesso })
      setAtivo(response.data.ativo)
      setUsuarioCadastro(response.data.usuarioCadastro)
      setDataCadastro(response.data.dataCadastro)
      setUsuarioUltimaAlteracao(response.data.usuarioUltimaAlteracao)
      setDataUltimaAlteracao(response.data.dataUltimaAlteracao)
      setContador({ value: response.data.idContador, label: response.data.descricaoContador })
      setRevenda({ value: response.data.idRevenda, label: response.data.descricaoRevenda })

      if (response.data.arquivoFoto) carregaFotoUsuario(response.data.arquivoFoto.id)
    } else {
      toast.error(response.mensagem, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setLoading(false)
  }

  async function carregaFotoUsuario(id) {
    const response = await downloadArquivo(id)

    if (response.sucesso) {
      const url = `${API_URL}/arquivo/download/${id}`
      setFotoUrl(url)
    }
  }

  async function handleSalvarUsuario() {
    setSalvandoCadastro(true)

    let response = { sucesso: false, mensagem: "Erro ao salvar Usuário" }

    if (idUsuario) {
      const usuarioEditado = {
        nome: nome,
        arquivoFotoId: foto ? foto.id : null,
        email: email,
        idPerfilAcesso: perfilAcesso.value,
        idContador: contador.value,
        idRevenda: revenda.value,
        ativo: ativo
      }

      response = await putUsuario(idUsuario, usuarioEditado)

    } else {
      const novoUsuario = {
        nome: nome,
        arquivoFotoId: foto ? foto.id : null,
        email: email,
        senha: senha,
        idPerfilAcesso: perfilAcesso.value,
        idContador: contador.value,
        idRevenda: revenda.value,
      }

      response = await postUsuario(novoUsuario)
    }

    if (response.sucesso) {
      toast.success('Usuário Salvo com sucesso!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      navigate(`/usuario`)
    } else {
      toast.error(response.mensagem, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setSalvandoCadastro(false)
  }

  const carregaOpcoesPerfilAcesso = async () => {
    setLoading(true)

    const response = await getListSelectPerfilAcesso()

    if (response.sucesso) {
      setPerfilAcesso(response.data[0].id)

      const lista = response.data.map(perfil => { return { value: perfil.id, label: perfil.descricao } })
      const opcaoNula = { value: null, label: 'Selecione' }
      setOpcoesPerfilAcesso([opcaoNula, ...lista])
    } else {
      toast.error(response.mensagem, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setLoading(false)
  }

  async function carregaOpcoesRevenda(pesquisa = pesquisaRevenda) {
    const response = await getSelectRevenda(pesquisa)
    if (response.sucesso) {
      const opcoes = response.data.map(revenda => {
        return {
          value: revenda.id,
          label: revenda.descricao
        }
      })
      setOpcoesRevenda([{ value: null, label: 'Selecione' }, ...opcoes])
    } else {
      toast.error(response.mensagem, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  async function carregaOpcoesContador(pesquisa = pesquisaContador) {
    const response = await getSelectContador(pesquisa)
    if (response.sucesso) {
      const opcoes = response.data.map(contador => {
        return {
          value: contador.id,
          label: contador.descricao
        }
      })
      setOpcoesContador([{ value: null, label: 'Selecione' }, ...opcoes])
    } else {
      toast.error(response.mensagem, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  function handleSelectPerfilAcesso(perfilSelecionado) {
    setPerfilAcesso(perfilSelecionado)
  }

  function handleSelectContador(perfilSelecionado) {
    setContador(perfilSelecionado)
  }

  function handleSelectRevenda(perfilSelecionado) {
    setRevenda(perfilSelecionado)
  }

  async function handleAlterarSenha() {
    if (!senha || !senhaConfirmada) {
      toast.error('Preencha todos os campos para alterar a senha!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    setSalvandoSenha(true)

    const novaSenha = {
      senha: senha,
      confirmacaoSenha: senhaConfirmada,
    }

    const response = await alterarSenhaUsuario(idUsuario, novaSenha)

    if (response.sucesso) {
      navigate(`/usuario/form`, { state: { "idUsuario": response.data.dados.id } })
      carregaUsuario()

      toast.success('Senha Alterada com sucesso!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(response.mensagem, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setSenha('')
    setSenhaConfirmada('')
    setStatusConfirmacao(null)
    setVerificandoSenha(null)

    setSalvandoSenha(false)
  }

  useEffect(() => {
    if (pesquisaRevenda) revendaDebounce()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pesquisaRevenda])

  useEffect(() => {
    if (pesquisaContador) contadorDebounce()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pesquisaContador])

  function handleChangeAtivo(clienteAtivo) {
    const { valor } = clienteAtivo
    setAtivo(valor)
  }

  const renderCamposDadosPessoais = () => {
    return (
      <>
        <div className="sm:col-span-1">
          <div className="col-span-6 sm:col-span-3">
            <label className="block text-sm font-medium text-gray-700">
              Nome
            </label>
            <input
              id="nome"
              name="nome"
              defaultValue={nome}
              disabled={salvandoCadastro || salvandoSenha}
              onChange={(e) => setNome(e.target.value)}
              type="text"
              autoComplete="given-name"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>
        <div className="sm:col-span-1">
          <div className="col-span-6 sm:col-span-3">
            <label className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              id="email"
              name="email"
              defaultValue={email}
              disabled={salvandoCadastro || salvandoSenha}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              autoComplete="email"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <div className="col-span-6 sm:col-span-3">
            <label className="block text-sm font-medium text-gray-700">
              Perfil de Acesso
            </label>
            <Select
              value={perfilAcesso}
              onChange={handleSelectPerfilAcesso}
              options={opcoesPerfilAcesso}
              isDisabled={salvandoCadastro || salvandoSenha}
              placeholder="Selecione"
              noOptionsMessage={() => "Nenhum perfil de acesso disponível"}
              className="mt-1 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="">
          <div className="col-span-6 sm:col-span-3">
            <label className="block text-sm font-medium text-gray-700">
              Contador
            </label>
            <Select
              value={contador}
              onChange={handleSelectContador}
              onInputChange={value => setPesquisaContador(value)}
              options={opcoesContador}
              isDisabled={salvandoCadastro || salvandoSenha}
              placeholder="Selecione"
              noOptionsMessage={() => "Nenhum contador disponível"}
              className="mt-1 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="">
          <div className="col-span-6 sm:col-span-3">
            <label className="block text-sm font-medium text-gray-700">
              Revenda
            </label>
            <Select
              value={revenda}
              onChange={handleSelectRevenda}
              onInputChange={value => setPesquisaRevenda(value)}
              options={opcoesRevenda}
              isDisabled={salvandoCadastro || salvandoSenha}
              placeholder="Selecione"
              noOptionsMessage={() => "Nenhuma revenda disponível"}
              className="mt-1 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>


      </>
    )
  }

  const renderALterarSenha = () => {
    return (
      <>
        <div className="sm:col-span-1">
          <div className="col-span-3 sm:col-span-2">
            <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
              Senha
            </label>
            <div className="mt-1 rounded-md shadow-sm flex">
              <input
                type="password"
                name="senha"
                disabled={salvandoCadastro || salvandoSenha}
                id="senha"
                onChange={(e) => setSenha(e.target.value)}
                className="py-2 px-4 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-l-md sm:text-sm"
              />
              <span title={verificandoSenha} className="bg-gray-50 border border-l-0 border-gray-300 rounded-r-md px-3 inline-flex items-center text-gray-500 sm:text-sm">

                {verificandoSenha === "Verificando Senha" && <svg className="animate-spin h-5 w-5 text-gray" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>}
                {verificandoSenha == null && <LockClosedIcon className="text-gray-400 w-6" />}
                {verificandoSenha === "Senha Forte" && <LockClosedIcon className="text-green-400 w-6" />}
                {verificandoSenha === "Senha Fraca" && <LockClosedIcon className="text-red-400 w-6" />}
              </span>
            </div>
          </div>
        </div>
        <div className="sm:col-span-1">
          <div className="col-span-3 sm:col-span-2">
            <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
              Confirmação de senha
            </label>
            <div className="mt-1 rounded-md shadow-sm flex">
              <input
                id="confirmacao-senha"
                name="confirmacao-senha"
                disabled={salvandoCadastro || salvandoSenha}
                type="password"
                onChange={(e) => setSenhaConfirmada(e.target.value)}
                className="py-2 px-4 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-l-md sm:text-sm"
              />
              <span title={statusConfirmacao} className="bg-gray-50 border border-l-0 border-gray-300 rounded-r-md px-3 inline-flex items-center text-gray-500 sm:text-sm">
                {statusConfirmacao === "Aguardando Confirmação" && <svg className="animate-spin h-5 w-5 text-gray" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>}
                {statusConfirmacao == null && <ShieldCheckIcon className="text-gray-400 w-6" />}
                {statusConfirmacao === "Senha Confirmada" && <ShieldCheckIcon className="text-green-400 w-6" />}
                {statusConfirmacao === "Senhas Diferentes" && <ShieldExclamationIcon className="text-red-400 w-6" />}
              </span>
            </div>
          </div>
        </div>
      </>
    )
  }
  async function handleChangeFotoUsuario(file) {
    if (file) {
      const formData = new FormData()
      formData.append('File', file)
      formData.append('Id', 0)
      formData.append('Descricao', 'FotoUsuario')

      const response = await uploadArquivo(formData)
      if (response.sucesso) {
        setFoto(response.data)
        setFotoUrl(`${API_URL}/arquivo/download/${response.data.id}`)
      } else {
        toast.error(response.mensagem, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  if (loading) {
    return <LoadingPage />
  }

  return (
    <main className="py-8 lg:flex flex-row">
      <div className="px-6 pb-8 ">
        <Dropzone
          className="grid grid-cols-1 gap-4 justify-items-center"
          anexo={foto}
          preview={false}
          texto="Clique ou arraste a Imagem desejada até aqui"
          setAnexo={handleChangeFotoUsuario} >
          <div className="flex items-center space-x-5">
            <div className="flex-shrink-0">
              <div className="relative">
                <span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true" />
                <img
                  className="object-contain h-20 w-20 rounded-full"
                  src={foto_url || require('../../assets/images/user.png')}
                  alt="Foto de Usuário"
                />
              </div>
            </div>
          </div>
        </Dropzone>
      </div>
      <div className="grid grid-cols-1 gap-6 sm:px-6 lg:grid-flow-col-dense lg:grid-cols-3 flex-1">
        <div className="space-y-6 lg:col-start-1 lg:col-span-3">
          <section aria-labelledby="applicant-information-title">
            <div className="bg-white shadow sm:rounded-lg">
              <div className="px-8 py-5 flex flex-row justify-between">
                <div>
                  <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                    Dados Pessoais
                  </h2>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">Cadastro de Usuário</p>
                </div>
                {idUsuario > 0 && <div>
                  <button
                    type="button"
                    onClick={() => setModal(true)}
                    disabled={(salvandoCadastro || salvandoSenha)}
                    className={`${(salvandoCadastro || salvandoSenha) ? "cursor-not-allowed" : "cursor-pointer"} inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500`}
                  >
                    Alterar Senha
                  </button>
                </div>}
              </div>

              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  {renderCamposDadosPessoais()}
                  {idUsuario &&
                    <LayoutSwitch
                      key="switchAtivo"
                      nome="Ativo"
                      disabled={salvandoCadastro || salvandoSenha}
                      descricao="Usuário ativo"
                      valor={ativo}
                      setValor={handleChangeAtivo} />
                  }
                </dl>
              </div>

              <div className="m-6 pb-6 flex flex-row flex-wrap justify-between space-y-4 items-center">
                {idUsuario && <div className="text-sm text-gray-600 grid sm:grid-cols-2 grid-cols-1 gap-4">
                  <div>
                    <span className="text-xs text-gray-400">Cadastro</span>
                    <p>{usuarioCadastro}, {new Date(dataCadastro).toLocaleDateString()} - {new Date(dataCadastro).toLocaleTimeString()}</p>
                  </div>
                  <div className="text-sm text-gray-600">
                    <span className="text-xs text-gray-400">Última alteração</span>
                    <p>{usuarioUltimaAlteracao}, {new Date(dataUltimaAlteracao).toLocaleDateString()} - {new Date(dataUltimaAlteracao).toLocaleTimeString()}</p>
                  </div>
                </div>}
                {idUsuario && editar && <button
                  type="button"
                  onClick={salvarDebounce}
                  disabled={(salvandoCadastro || salvandoSenha)}
                  className={`${(salvandoCadastro || salvandoSenha) ? "cursor-not-allowed" : "cursor-pointer"} inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500`}
                >
                  {salvandoCadastro && <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>}
                  Salvar Alterações
                </button>}
              </div>
            </div>
          </section>

          {!idUsuario && <section aria-labelledby="applicant-information-title">
            <div className="bg-white shadow sm:rounded-lg">
              <div className="px-8 py-5">
                <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                  Senha de Acesso
                </h2>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">Senha do Usuário</p>
              </div>

              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  {renderALterarSenha()}
                </dl>
              </div>

              <div className="m-6 pb-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                {idUsuario && alterarSenha && <button
                  type="button"
                  onClick={handleAlterarSenha}
                  disabled={(salvandoCadastro || salvandoSenha)}
                  className={`${(salvandoCadastro || salvandoSenha) ? "cursor-not-allowed" : "cursor-pointer"} inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500`}
                >
                  {salvandoSenha && <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>}
                  Alterar Senha
                </button>}
              </div>
            </div>
          </section>}

          {!idUsuario && <section aria-labelledby="applicant-information-title">
            <div className="bg-white shadow sm:rounded-lg">
              <div className="m-6 py-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                <button
                  type="button"
                  onClick={() => navigate(`/usuario`)}
                  className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  onClick={salvarDebounce}
                  disabled={salvandoCadastro}
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500"
                >
                  Salvar Usuário
                </button>
              </div>
            </div>
          </section>}
        </div>
      </div>
      <ModalAlterarSenha
        open={modal}
        setOpen={setModal}
        usuarioSelecionado={idUsuario}
      />
    </main>
  )
}
