import instance from '../configAxios';

export const getNcmById = async (id) => {
  const axios = await instance();

  try {
    const response = await axios.get(`/ncm/${id}`)
    return { sucesso: response.status, data: response.data}
  } catch (error) {
    return { sucesso: false, mensagem:error.response.data };
  }
}

export const getNcmLogado = async (id) => {
  const axios = await instance();

  try {
    const response = await axios.get(`/ncm/logado`)
    return { sucesso: response.status, data: response.data}
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const getListNcm = async (filtros) => {
  const axios = await instance();
  
  try {
    const response =  await axios.post(`/ncm/listagem`, filtros)
    return { sucesso: true, data: response.data}
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const postNcm = async novaNcm => {
  const axios = await instance();
  
  try {
    const response =  await axios.post(`/ncm`, novaNcm)
    return { sucesso: true, data: response.data}
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const putNcm = async (idNcm, ncmEditado) => {
  const axios = await instance();
  
  try {
    const response =  await axios.put(`/ncm/${idNcm}`, ncmEditado)
    return { sucesso: true, data: response.data}
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const alterarSenhaNcm = async (id, novaSenha) => {
  const axios = await instance();

  try {
    const response =  await axios.patch(`/ncm/alterarSenha/${id}`, novaSenha)
    return { sucesso: true, data: response.data}
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const deletarNcm = async idNcm => {
  const axios = await instance();
  
  try {
    const response =  await axios.delete(`/ncm/${idNcm}`)
    return { sucesso: true, data: response.data}
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const getListSelectVersaoNcm = async () => {
  const axios = await instance();

  try {
    const response = await axios.get(`/ncm/versao/select`)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}