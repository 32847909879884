/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import {
  ShieldCheckIcon,
  ShieldExclamationIcon,
  LockClosedIcon
} from '@heroicons/react/solid'
import { alterarSenhaUsuario } from "../../services/usuario";
import { toast } from 'react-toastify';

export default function ModalAlterarSenha(props) {
  const {
    open,
    setOpen,
    usuarioSelecionado
  } = props

  const [senha, setSenha] = useState(null)
  const [senhaConfirmada, setSenhaConfirmada] = useState(null)
  const [verificandoSenha, setVerificandoSenha] = useState(null)
  const [statusConfirmacao, setStatusConfirmacao] = useState(null)
  const [salvandoSenha, setSalvandoSenha] = useState(false);

  useEffect(() => {
    if (senha) {
      verificaForcaSenha()
      verificaConfirmacaoSenha()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [senha])

  useEffect(() => {
    if (senhaConfirmada) {
      verificaConfirmacaoSenha()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [senhaConfirmada])

  function verificaForcaSenha() {
    if (!senha) setVerificandoSenha(null)

    else {
      setVerificandoSenha("Verificando Senha")

      setTimeout(() => {
        if (senha.length > 7) setVerificandoSenha("Senha Forte")
        else if (senha.length <= 7) setVerificandoSenha("Senha Fraca")
      }, 300);
    }
  }

  function verificaConfirmacaoSenha() {
    if (!senhaConfirmada || !senha) setStatusConfirmacao(null)

    else {
      setStatusConfirmacao("Aguardando Confirmação")

      setTimeout(() => {
        if (senha === senhaConfirmada) setStatusConfirmacao("Senha Confirmada")
        else if (senha !== senhaConfirmada) setStatusConfirmacao("Senhas Diferentes")
      }, 300);
    }
  }

  async function handleAlterarSenha() {
    if (!senha || !senhaConfirmada) {
      toast.error('Preencha todos os campos para alterar a senha!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    setSalvandoSenha(true)

    const novaSenha = {
      senha: senha,
      confirmacaoSenha: senhaConfirmada,
    }

    const response = await alterarSenhaUsuario(usuarioSelecionado, novaSenha)

    if (response.sucesso) {
      setOpen(false)

      toast.success('Senha alterada com sucesso.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(response.mensagem, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setSenha('')
    setSenhaConfirmada('')
    setStatusConfirmacao(null)
    setVerificandoSenha(null)

    setSalvandoSenha(false)
  }

  function handleCloseModal() {
    setOpen(false)
  }

  const renderALterarSenha = () => {
    return (
      <>
        <div className="sm:col-span-2">
          <div className="col-span-3 sm:col-span-2">
            <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
              Senha
            </label>
            <div className="mt-1 rounded-md shadow-sm flex">
              <input
                type="password"
                name="senha"
                disabled={salvandoSenha}
                id="senha"
                onChange={(e) => setSenha(e.target.value)}
                className="py-2 px-4 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-l-md sm:text-sm"
              />
              <span title={verificandoSenha} className="bg-gray-50 border border-l-0 border-gray-300 rounded-r-md px-3 inline-flex items-center text-gray-500 sm:text-sm">

                {verificandoSenha === "Verificando Senha" && <svg className="animate-spin h-5 w-5 text-gray" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>}
                {verificandoSenha == null && <LockClosedIcon className="text-gray-400 w-6" />}
                {verificandoSenha === "Senha Forte" && <LockClosedIcon className="text-green-400 w-6" />}
                {verificandoSenha === "Senha Fraca" && <LockClosedIcon className="text-red-400 w-6" />}
              </span>
            </div>
          </div>
        </div>
        <div className="sm:col-span-2">
          <div className="col-span-3 sm:col-span-2">
            <label htmlFor="company-website" className="block text-sm font-medium text-gray-700">
              Confirmação de senha
            </label>
            <div className="mt-1 rounded-md shadow-sm flex">
              <input
                id="confirmacao-senha"
                name="confirmacao-senha"
                disabled={salvandoSenha}
                type="password"
                onChange={(e) => setSenhaConfirmada(e.target.value)}
                className="py-2 px-4 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 flex-grow block w-full min-w-0 rounded-none rounded-l-md sm:text-sm"
              />
              <span title={statusConfirmacao} className="bg-gray-50 border border-l-0 border-gray-300 rounded-r-md px-3 inline-flex items-center text-gray-500 sm:text-sm">
                {statusConfirmacao === "Aguardando Confirmação" && <svg className="animate-spin h-5 w-5 text-gray" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>}
                {statusConfirmacao == null && <ShieldCheckIcon className="text-gray-400 w-6" />}
                {statusConfirmacao === "Senha Confirmada" && <ShieldCheckIcon className="text-green-400 w-6" />}
                {statusConfirmacao === "Senhas Diferentes" && <ShieldExclamationIcon className="text-red-400 w-6" />}
              </span>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden z-40" onClose={handleCloseModal}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="h-full flex flex-col bg-white shadow-xl">
                  <div className="py-6 px-4 bg-indigo-700 sm:px-6">
                    <div className="flex items-center justify-between">
                      <Dialog.Title className="text-lg font-medium text-white">Alterar senha usuário</Dialog.Title>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                          onClick={handleCloseModal}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div className="mt-1">
                      <p className="text-sm text-indigo-300">
                        Preencha os campos abaixo para alterar a senha do usuário.
                      </p>
                    </div>
                  </div>

                  <div className="relative flex flex-1">
                    <div className="col-span-6 flex-1 flex flex-col sm:col-span-3">
                      <section aria-labelledby="alterar-senha">
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            {renderALterarSenha()}
                          </dl>
                        </div>

                        <div className="m-6 pb-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                          {usuarioSelecionado > 0 && <button
                            type="button"
                            onClick={handleAlterarSenha}
                            disabled={(salvandoSenha)}
                            className={`${(salvandoSenha) ? "cursor-not-allowed" : "cursor-pointer"} inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500`}
                          >
                            {salvandoSenha && <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>}
                            Alterar Senha
                          </button>}
                        </div>
                      </section>
                    </div>
                  </div>

                  <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                    <button
                      type="button"
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={handleCloseModal}
                    >
                      Voltar
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}