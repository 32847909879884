import instance from '../configAxios';

export const getContadorById = async (id) => {
  const axios = await instance();

  try {
    const response = await axios.get(`/contador/${id}`)
    return { sucesso: response.status, data: response.data}
  } catch (error) {
    return { sucesso: false, mensagem:error.response.data };
  }
}

export const getContadorLogado = async (id) => {
  const axios = await instance();

  try {
    const response = await axios.get(`/contador/logado`)
    return { sucesso: response.status, data: response.data}
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const getListContador = async (filtros) => {
  const axios = await instance();
  
  try {
    const response =  await axios.post(`/contador/listagem`, filtros)
    return { sucesso: true, data: response.data}
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const postContador = async novoContador => {
  const axios = await instance();
  
  try {
    const response =  await axios.post(`/contador`, novoContador)
    return { sucesso: true, data: response.data}
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const putContador = async (idContador, contadorEditado) => {
  const axios = await instance();
  
  try {
    const response =  await axios.put(`/contador/${idContador}`, contadorEditado)
    return { sucesso: true, data: response.data}
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const alterarSenhaContador = async (id, novaSenha) => {
  const axios = await instance();

  try {
    const response =  await axios.patch(`/contador/alterarSenha/${id}`, novaSenha)
    return { sucesso: true, data: response.data}
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const deletarContador = async idContador => {
  const axios = await instance();
  
  try {
    const response =  await axios.delete(`/contador/${idContador}`)
    return { sucesso: true, data: response.data}
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const getSelectContador = async (pesquisa) => {
  const axios = await instance();

  try {
    const response = await axios.get(`/contador/select?pesquisa=${pesquisa}`)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}