import { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { useNavigate, useLocation, Outlet, Link } from 'react-router-dom';
import {
    MenuAlt2Icon,
    XIcon,
} from '@heroicons/react/outline'

import { getUsuarioLogado } from "../../services/usuario";
import API_URL from '../../utils/api';
import { downloadArquivo } from '../../services/arquivo';
import { getItensMenu } from '../../services/menu';
import { HeroIcon } from '../HeroIcon';
import { Scrollbars } from 'react-custom-scrollbars';
import { Switch, useDarkreader } from 'react-darkreader';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function LayoutDashboard() {
    const theme = {
        brightness: 100,
        contrast: 100,
        grayscale: 0,
        sepia: false
    }
    const [isDark, { toggle }] = useDarkreader(false, theme);

    function handleDarkMode() {
        toggle();
        localStorage.setItem('@adminDarkMode', !isDark);
    }

    useEffect(() => {
        const darkMode = localStorage.getItem('@adminDarkMode');

        if (darkMode === 'true') {
            toggle();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const location = useLocation();
    let navigate = useNavigate();
    const [foto_url, setFotoUrl] = useState('')
    const [usuario, setUsuario] = useState({})
    const [navigation, setNavigation] = useState([])

    async function carregarItensMenu() {
        const caminho = location.pathname.split("/")[1]

        const menuAtual = caminho;
        const response = await getItensMenu(menuAtual)

        if (response.sucesso) {
            setNavigation(response.data.menu[0].menus)
        }
    }

    async function carregaUsuario() {

        const response = await getUsuarioLogado()

        if (response.sucesso) {
            setUsuario(response.data)
            if (response.data.arquivoFoto) carregaFotoUsuario(response.data.arquivoFoto.id)
        }
    }

    async function carregaFotoUsuario(id) {
        const response = await downloadArquivo(id)

        if (response.sucesso) {
            const url = `${API_URL}/arquivo/download/${id}`
            setFotoUrl(url)
        }
    }

    useEffect(() => {
        carregaUsuario()
        carregarItensMenu()
        setSidebarOpen(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    function handleSair() {
        localStorage.removeItem('@admin_Token');
        navigate(`/login`)
    }

    const [sidebarOpen, setSidebarOpen] = useState(false)

    return (
        <>
            {/*
        This example requires updating your template:

        ```
        <html className="h-full bg-gray-100">
        <body className="h-full">
        ```
      */}
            <div>
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog as="div" className="fixed inset-0 flex z-40" onClose={setSidebarOpen}>
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                        </Transition.Child>
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <div className="relative flex-1 flex flex-col max-w-xs w-full pb-4 bg-gray-800">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                                        <button
                                            type="button"
                                            className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                            onClick={() => setSidebarOpen(false)}
                                        >
                                            <span className="sr-only">Close sidebar</span>
                                            <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="flex-shrink-0 flex items-center px-4 pt-4">
                                    <div className='flex flex-row items-end flex-wrap'>
                                        <img
                                            className="h-8 object-contain filter grayscale contrast-200 invert"
                                            src="/logo.png"
                                            alt="Workflow"
                                        />
                                        <img
                                            className="h-5 mb-0.4 object-contain filter grayscale contrast-200 invert"
                                            src="/logo_nome.png"
                                            alt="Workflow"
                                        />
                                    </div>
                                    {/* <p className='text-white text-2xl font-bold'>CompSys</p> */}
                                </div>
                                <div className="mt-5 flex-1 h-0 overflow-y-hidden">
                                    <Scrollbars
                                        autoHide
                                        autoHideTimeout={1000}
                                        autoHideDuration={200}
                                        thumbMinSize={30}
                                        universal={true}
                                    >
                                        <nav className="px-2 space-y-1">
                                            {navigation.map((item, key) => (
                                                <Link
                                                    key={key}
                                                    to={item.link}
                                                    className={classNames(
                                                        item.ativo ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                        'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                                                    )}
                                                >
                                                    <HeroIcon
                                                        icon={item.icone}
                                                        outline={!item.ativo}
                                                        className={classNames(
                                                            item.ativo ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                                                            'mr-3 flex-shrink-0 h-6 w-6'
                                                        )}
                                                    />
                                                    {item.descricao}
                                                </Link>
                                            ))}
                                        </nav>
                                    </Scrollbars>
                                </div>
                            </div>
                        </Transition.Child>
                        <div className="flex-shrink-0 w-14" aria-hidden="true">
                            {/* Dummy element to force sidebar to shrink to fit close icon */}
                        </div>
                    </Dialog>
                </Transition.Root>

                <div className={`flex flex-col ${sidebarOpen ? "md:pl-80" : ''}`}>
                    <div className="sticky top-0 z-40 flex-shrink-0 flex h-16 bg-white shadow">
                        <button
                            type="button"
                            className={`${sidebarOpen ? 'hidden' : ''} px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500`}
                            onClick={() => setSidebarOpen(true)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                        <div className="flex-1 px-4 flex justify-between">
                            <div className="flex-1 flex">
                                {/* <form className="w-full flex md:ml-0" action="#" method="GET">
                  <label htmlFor="search-field" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                      <SearchIcon className="h-5 w-5" aria-hidden="true" />
                    </div>
                    <input
                      id="search-field"
                      className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                      placeholder="Search"
                      type="search"
                      name="search"
                    />
                  </div>
                </form> */}
                            </div>
                            <div className="ml-4 flex items-center md:ml-6">
                                {/* <button
                  type="button"
                  className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}
                                {/* Profile dropdown */}

                                <Switch checked={isDark} onChange={handleDarkMode} />

                                <Menu as="div" className="ml-3 relative">
                                    <div>
                                        <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                            <span className="sr-only">Abrir menu</span>
                                            <h1 className='mr-4 font-semibold text-indigo-600 '>{usuario?.nome}</h1>
                                            <img
                                                className="object-contain h-8 w-8 rounded-full"
                                                src={foto_url || require('../../assets/images/user.png')}
                                                alt={"usuário"}
                                            />
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <div
                                                        onClick={() => handleSair()}
                                                        className={classNames(
                                                            active ? 'cursor-pointer bg-gray-100' : '',
                                                            'block px-4 py-2 text-sm text-gray-700'
                                                        )}
                                                    >
                                                        Sair
                                                    </div>
                                                )}
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    </div>

                    <main className="flex-1 bg-gray-100">
                        <Outlet />
                    </main>
                </div>
            </div>
        </>
    )
}
