import React, { useState, useEffect } from 'react'
import { getListConsultaCliente } from '../../services/consultaCliente'
import { getSelectCliente } from '../../services/cliente'
import { useNavigate } from "react-router-dom";
import LayoutPaginacaoTabela from "../../components/LayoutPaginacaoTabela";
import EmptyPage from "../../components/EmptyPage";
import LoadingPage from "../../components/LoadingPage";
import verificaAcesso from '../../utils/verificaAcesso';
import { toast } from 'react-toastify';
import Select from 'react-select';
import useDebounce from '../../hooks/useDebounce';
import { SearchIcon } from '@heroicons/react/outline'
import { getListUf } from '../../services/endereco';
import { BanIcon, ChartBarIcon, CurrencyDollarIcon, ExclamationCircleIcon, KeyIcon, ShoppingCartIcon, UserIcon } from '@heroicons/react/solid';
import { formatarDataHora } from '../../utils/formartar';

export default function ConsultaClienteListagem(props) {
    let navigate = useNavigate();
    //acessos
    const desc_funcionalidade = "CONSULTA_CLIENTE";
    const [acessoVerificado, setAcessoVerificado] = useState(false);
    const [listar, setListar] = useState('')

    const [listaConsultaCliente, setListaConsultaCliente] = useState([])

    const [loading, setLoading] = useState(true)

    //Filtros
    const idRevenda = localStorage.getItem("@IdRevenda");
    const [filtroPesquisa, setFiltroPesquisa] = useState(null);
    const [filtroUf, setFiltroUf] = useState({ value: null, label: "Todas" })
    const [opcoesUf, setOpcoesUf] = useState([]);
    const ufDebounce = useDebounce(carregaOpcoesUf, 500)
    const [pesquisaUf, setPesquisaUf] = useState("")

    const [filtroCliente, setFiltroCliente] = useState({ value: null, label: "Todas" })
    const [opcoesCliente, setOpcoesCliente] = useState([]);
    const clienteDebounce = useDebounce(carregaOpcoesCliente, 500)
    const [pesquisaCliente, setPesquisaCliente] = useState("")

    useEffect(() => {
        filtroDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtroPesquisa, filtroUf, filtroCliente])

    async function carregaOpcoesUf(pesquisa = pesquisaUf) {
        const response = await getListUf(pesquisa)
        if (response.sucesso) {
            const opcoes = response.data.map(uf => {
                return {
                    value: uf.id,
                    label: uf.descricao
                }
            })
            setOpcoesUf([{ value: null, label: 'Todos' }, ...opcoes])
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    async function carregaOpcoesCliente(pesquisa = pesquisaCliente) {
        const response = await getSelectCliente(pesquisa, idRevenda)
        if (response.sucesso) {
            const opcoes = response.data.map(cliente => {
                return {
                    value: cliente.id,
                    label: cliente.descricao
                }
            })
            setOpcoesCliente([{ value: null, label: 'Todos' }, ...opcoes])
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    //Paginacao Tabela
    const [paginaAtual, setPaginaAtual] = useState(0)
    const [totalRegistros, setTotalRegistros] = useState(0)
    const [totalPaginas, setTotalPaginas] = useState(0)
    const registrosPorPagina = props.quantidadeResultados || 10

    const ClienteLinha = ({ cliente }) => {
        return (
            <div>
                <div className="text-sm font-medium text-gray-800 truncate flex capitalize">
                    <p className='truncate mr-1'>{cliente.razaoSocial.toLowerCase()}</p>
                </div>
                <div className="text-sm text-gray-500 truncate capitalize">
                    <p className='truncate mr-1'>{cliente.nomeFantasia.toLowerCase()}</p>
                </div>
            </div>
        )
    }

    const DadosConsultaLinha = ({ dadosLinha }) => {
        const {
            versaoSistema,
            sistema,
            uf,
            tipoConsulta,
            dataUltimaAlteracao,
            usuarioUltimaAlteracao,
            cliente
        } = dadosLinha

        return (
            <>
                {cliente && <div>
                    <div className={`text-sm font-medium text-gray-800 truncate flex ${tipoConsulta === "NCM" ? "text-yellow-600" : "text-blue-600"}`}>
                        <p className='truncate flex'>
                            {tipoConsulta === "NCM" && <CurrencyDollarIcon className='w-4 mr-1' />}
                            {tipoConsulta === "LICENÇA" && <KeyIcon className='w-4 mr-1' />}
                            {tipoConsulta}
                        </p>
                    </div>
                    {tipoConsulta === "NCM" &&
                        <div className="text-sm text-gray-500 truncate">
                            <p className='truncate'>UF: {uf}</p>
                        </div>
                    }
                </div>}
                <div>
                    <div className={`text-sm font-medium text-gray-800 flex truncate ${sistema === "ERP" ? "text-emerald-700" : sistema === "PDV" ? "text-rose-900" : "text-gray-600"}`}>
                        {sistema === "ERP" && <ChartBarIcon className='w-4 mr-1' />}
                        {sistema === "PDV" && <ShoppingCartIcon className='w-4 mr-1' />}
                        <p className='truncate mr-1'>{sistema || <i>Sistema</i>}</p>
                    </div>
                    <div className="flex text-sm text-gray-500 flex truncate">
                        <p className='truncate mr-1'>Versão: {versaoSistema}</p>
                    </div>
                </div>

                <div>
                    {usuarioUltimaAlteracao === "Login inválido" ?
                        <div className="text-sm font-medium text-red-600 truncate flex">
                            <ExclamationCircleIcon className='w-4 mr-1' />
                            <p className='truncate mr-1'>{usuarioUltimaAlteracao}</p>
                        </div>
                        :
                        usuarioUltimaAlteracao === "Tela Bloqueio" ?
                            <div className="text-sm font-medium text-orange-600 truncate flex">
                                <BanIcon className='w-4 mr-1' />
                                <p className='truncate mr-1'>{usuarioUltimaAlteracao}</p>
                            </div>
                            :
                            <div className="text-sm font-medium text-gray-800 truncate flex">
                                <UserIcon className='w-4 mr-1' />
                                <p className='truncate mr-1'>Usuário: {usuarioUltimaAlteracao}</p>
                            </div>
                    }
                    <div className="text-sm text-gray-500 truncate">
                        <p className='truncate mr-1'>Data: {formatarDataHora(dataUltimaAlteracao)}</p>
                    </div>
                </div>
            </>
        )
    }

    const LinhaTabela = ({ dadosLinha }) => {
        return (
            <li className="cursor-default">
                <div className="bg-white px-4 py-2 sm:px-6">
                    {props.idCliente ?
                        <div className="grid lg:grid-cols-3 grid-cols-1 gap-2">
                            <DadosConsultaLinha dadosLinha={dadosLinha} />
                        </div> :
                        <div className="grid lg:grid-cols-4 grid-cols-1 gap-4">
                            <ClienteLinha cliente={dadosLinha.cliente} />
                            <DadosConsultaLinha dadosLinha={dadosLinha} />
                        </div>
                    }
                </div>
            </li>
        )
    }

    useEffect(() => {
        if (!acessoVerificado) {
            verificaAcesso("consulta-cliente", //location.pathname.split("/")[1]
                { acao: setListar, nome: desc_funcionalidade + "_LISTAR" },
            )
            setAcessoVerificado(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acessoVerificado])

    useEffect(() => {
        if (acessoVerificado) {
            if (listar) {
                carregaConsultaClientes(registrosPorPagina, paginaAtual)
                carregaOpcoesUf()
                carregaOpcoesCliente()
            } else {
                toast.error(`Sem acesso a Listagem de ${desc_funcionalidade}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setTimeout(() => {
                    navigate('/')
                }, 100)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listar])

    const carregaConsultaClientes = async (pageSize = registrosPorPagina, currentPage = 0) => {
        const filtros = {
            pageSize,
            currentPage,
            pesquisa: filtroPesquisa,
            ufId: filtroUf.value,
            clienteId: props.idCliente || filtroCliente.value,
            revendaId: idRevenda
        }

        //caso esteja na página de cliente
        if (props.clienteId) filtros.clienteId = props.idCliente

        const response = await getListConsultaCliente(filtros)

        if (response.sucesso) {
            setPaginaAtual(response.data.currentPage)
            setTotalRegistros(response.data.totalRegisters)
            setTotalPaginas(response.data.totalPages)

            setListaConsultaCliente(response.data.dados)
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setLoading(false)
    }

    useEffect(() => {
        if (pesquisaUf) ufDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pesquisaUf])

    useEffect(() => {
        if (pesquisaCliente) clienteDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pesquisaCliente])

    function handleSelectUf(ufSelecionadda) {
        setFiltroUf(ufSelecionadda)
    }

    function handleSelectCliente(clienteSelecionado) {
        setFiltroCliente(clienteSelecionado)
    }

    const filtroDebounce = useDebounce(carregaConsultaClientes, 500)

    const renderLinhasTabela = () => {
        return listaConsultaCliente.map((dadosLinha, key) => {
            return <LinhaTabela key={key} dadosLinha={dadosLinha} />
        })
    }

    if (loading) {
        return <LoadingPage />
    }
    else {
        return (
            <>
                {!listaConsultaCliente ?
                    <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">
                        <div className="bg-white overflow-hidden sm:rounded-lg sm:shadow my-8">
                            <EmptyPage texto="Nenhuma Consulta Cadastrada" Icone={SearchIcon} />
                        </div>
                    </div>
                    :
                    <div className={`flex flex-col ${!props.idCliente && "mx-4 sm:mx-6 md:mx-8"}`}>
                        {!props.idCliente && <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 mt-8'>
                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700 flex items-center">
                                    Pesquisa
                                    <i className='text-xs text-gray-400 ml-4'>
                                        ( Id,
                                        Cnpj Empresa,
                                        Nome Usuário,
                                        Versão Sistema,
                                        Sistema )
                                    </i>
                                </label>
                                <input
                                    defaultValue={filtroPesquisa}
                                    onChange={e => setFiltroPesquisa(e.target.value)}
                                    type="text"
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Pesquisa " />
                            </div>
                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                                    Cliente
                                </label>
                                <Select
                                    isDisabled={loading}
                                    name="cliente"
                                    value={filtroCliente}
                                    onChange={handleSelectCliente}
                                    onInputChange={value => setPesquisaCliente(value)}
                                    options={opcoesCliente}
                                    placeholder="Selecione"
                                    noOptionsMessage={() => "Nenhuma Cliente disponível"}
                                    className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                                    Uf
                                </label>
                                <Select
                                    isDisabled={loading}
                                    name="uf"
                                    value={filtroUf}
                                    onChange={handleSelectUf}
                                    onInputChange={value => setPesquisaUf(value)}
                                    options={opcoesUf}
                                    placeholder="Selecione"
                                    noOptionsMessage={() => "Nenhuma UF disponível"}
                                    className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                        </div>}
                        <div className="bg-white sm:rounded-lg sm:shadow my-8">
                            <div className="bg-white sm:rounded-t-lg px-4 py-5 border-b border-gray-200 sm:px-6">
                                <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                                    <div className="ml-4 mt-2">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Consulta Cliente
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <ul className="divide-y divide-gray-200">
                                {renderLinhasTabela()}
                            </ul>

                            <LayoutPaginacaoTabela
                                pagina={paginaAtual}
                                totalRegistros={totalRegistros}
                                registrosPorPagina={registrosPorPagina}
                                totalPaginas={totalPaginas}
                                className="rounded-b-lg"
                                onClickPaginaAnterior={() => {
                                    setPaginaAtual(paginaAtual - 1)
                                    carregaConsultaClientes(registrosPorPagina, paginaAtual - 1)
                                }}
                                onClickPaginaPosterior={() => {
                                    setPaginaAtual(paginaAtual + 1)
                                    carregaConsultaClientes(registrosPorPagina, paginaAtual + 1)
                                }}
                                onClickPagina={pagina => {
                                    setPaginaAtual(pagina)
                                    carregaConsultaClientes(registrosPorPagina, pagina)
                                }} />
                        </div>
                    </div>
                }
            </>
        )
    }

}