import instance from '../configAxios';

export const getListMDE = async (filtros) => {
    const axios = await instance();

    try {
        const response = await axios.post(`/mde/listagem`, filtros)
        return { sucesso: true, data: response.data }
    } catch (error) {
        if (!error.response) {
            return {
                sucesso: false,
                mensagem: "Não foi possível conectar ao servidor."
            }
        } else {
            return {
                sucesso: false,
                mensagem: error.response.data
            }
        }
    }
}

export const getListResumos = async (filtros) => {
    const axios = await instance();

    try {
        const response = await axios.post(`/mde/resumo`, filtros)
        return { sucesso: true, data: response.data }
    } catch (error) {
        if (!error.response) {
            return {
                sucesso: false,
                mensagem: "Não foi possível conectar ao servidor."
            }
        } else {
            return {
                sucesso: false,
                mensagem: error.response.data
            }
        }
    }
}

export const getListEventos = async (filtros) => {
    const axios = await instance();

    try {
        const response = await axios.post(`/mde/evento`, filtros)
        return { sucesso: true, data: response.data }
    } catch (error) {
        if (!error.response) {
            return {
                sucesso: false,
                mensagem: "Não foi possível conectar ao servidor."
            }
        } else {
            return {
                sucesso: false,
                mensagem: error.response.data
            }
        }
    }
}

export const getListClientes = async (filtros) => {
    const axios = await instance();

    try {
        const response = await axios.post(`/mde/cliente`, filtros)
        return { sucesso: true, data: response.data }
    } catch (error) {
        if (!error.response) {
            return {
                sucesso: false,
                mensagem: "Não foi possível conectar ao servidor."
            }
        } else {
            return {
                sucesso: false,
                mensagem: error.response.data
            }
        }
    }
}

export const getListNotasFiscais = async (filtros) => {
    const axios = await instance();

    try {
        const response = await axios.post(`/mde/notaFiscal`, filtros)
        return { sucesso: true, data: response.data }
    } catch (error) {
        if (!error.response) {
            return {
                sucesso: false,
                mensagem: "Não foi possível conectar ao servidor."
            }
        } else {
            return {
                sucesso: false,
                mensagem: error.response.data
            }
        }
    }
}

export const criarEventosExecucao = async () => {
    const axios = await instance();

    try {
        const response = await axios.get(`/mde/criar`)
        return { sucesso: true, data: response.data }
    } catch (error) {
        if (!error.response) {
            return {
                sucesso: false,
                mensagem: "Não foi possível conectar ao servidor."
            }
        } else {
            return {
                sucesso: false,
                mensagem: error.response.data
            }
        }
    }
}