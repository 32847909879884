import React, { useState, useEffect, Fragment } from 'react'
import { getListMDE, criarEventosExecucao } from '../../../services/mde'
import { getSelectCliente } from '../../../services/cliente'
import { useNavigate } from "react-router-dom";
import LayoutPaginacaoTabela from "../../../components/LayoutPaginacaoTabela";
import EmptyPage from "../../../components/EmptyPage";
import LoadingPage from "../../../components/LoadingPage";
import Modal from "../../../components/Modal";
import verificaAcesso from '../../../utils/verificaAcesso';
import { toast } from 'react-toastify';
import Select from 'react-select';
import useDebounce from '../../../hooks/useDebounce';
import { SearchIcon } from '@heroicons/react/outline'
import { CalendarIcon, CheckCircleIcon, CheckIcon, ClockIcon, DocumentTextIcon, DotsVerticalIcon, ExclamationIcon, MinusCircleIcon, ReceiptTaxIcon, ViewListIcon } from '@heroicons/react/solid';
import { Menu, Transition } from '@headlessui/react';
import MDEResumo from '../MDEResumo';
import MDEEvento from '../MDEEvento';
import MDENotaFiscal from '../MDENotaFiscal';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function MDEExecucao(props) {
    let navigate = useNavigate();
    //acessos
    const desc_funcionalidade = "MDE_EXECUCAO";
    const [acessoVerificado, setAcessoVerificado] = useState(false);
    const [listar, setListar] = useState('')

    const [listaMDE, setListaMDE] = useState([])

    const [loading, setLoading] = useState(true)

    const [modalResumos, setModalResumos] = useState(null);
    const [modalEventos, setModalEventos] = useState(null);
    const [modalNotasFiscais, setModalNotasFiscais] = useState(null);

    //Filtros
    const [filtroPesquisa, setFiltroPesquisa] = useState(null);
    const [filtroTotalizador, setFiltroTotalizador] = useState(null);
    const [quantidadeTotal, setQuantidadeTotal] = useState(0);
    const [quantidadeAtualizado, setQuantidadeAtualizado] = useState(0);
    const [quantidadePendente, setQuantidadePendente] = useState(0);
    const [quantidadeErro, setQuantidadeErro] = useState(0);

    const [filtroCliente, setFiltroCliente] = useState({ value: null, label: "Todas" })
    const [opcoesCliente, setOpcoesCliente] = useState([]);
    const clienteDebounce = useDebounce(carregaOpcoesCliente, 500)
    const [pesquisaCliente, setPesquisaCliente] = useState("")

    const hoje = new Date().toLocaleDateString().split("T")[0].split("/").reverse().join("-");
    const mesPassado = new Date(new Date().setMonth(new Date().getMonth() - 1)).toLocaleDateString().split("T")[0].split("/").reverse().join("-");

    const [inicioPeriodo, setInicioPeriodo] = useState(mesPassado);
    const [fimPeriodo, setFimPeriodo] = useState(hoje);

    useEffect(() => {
        filtroDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtroPesquisa, filtroCliente, filtroTotalizador, inicioPeriodo, fimPeriodo])

    async function carregaOpcoesCliente(pesquisa = pesquisaCliente) {
        const response = await getSelectCliente(pesquisa)
        if (response.sucesso) {
            const opcoes = response.data.map(cliente => {
                return {
                    value: cliente.id,
                    label: cliente.descricao
                }
            })
            setOpcoesCliente([{ value: null, label: 'Todos' }, ...opcoes])
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    async function handleCriarEventoExecucao() {
        await criarEventosExecucao()
    }

    //Paginacao Tabela
    const [paginaAtual, setPaginaAtual] = useState(0)
    const [totalRegistros, setTotalRegistros] = useState(0)
    const [totalPaginas, setTotalPaginas] = useState(0)
    const registrosPorPagina = props.quantidadeResultados || 10

    const ClienteLinha = ({ cliente }) => {
        return (
            <div>
                <div className="text-sm font-medium text-gray-800 truncate flex capitalize">
                    <p className='truncate mr-1'>{cliente.razaoSocial.toLowerCase()}</p>
                </div>
                <div className="text-sm text-gray-500 truncate capitalize">
                    <p className='truncate mr-1'>{cliente.nomeFantasia.toLowerCase()}</p>
                </div>
            </div>
        )
    }

    const DadosMDELinha = ({ dadosLinha }) => {
        const {
            // cliente,
            // clienteId,
            // cnpj,
            // id,
            // maximoNSU,
            // tipoAmbiente,
            // ufAutor,
            // versaoAplicacao,
            codigoStatus,
            dataResposta,
            motivo,
            ultimoNSURequisicao,
            ultimoNSUResposta,
            // usuarioUltimaAlteracao,
            // dataUltimaAlteracao,
            quantidadeResumos,
            quantidadeEventos,
            quantidadeNotasFiscais,
            erro,
            detalhesErro
        } = dadosLinha

        return (
            <>
                <div className='flex items-center'>
                    {erro ?
                        <span className='rounded-lg bg-red-600/20 text-red-600 px-2 py-1 font-semibold text-sm'>{detalhesErro}</span>
                        :
                        dataResposta ?
                            <span className='rounded-lg bg-green-600/20 text-green-600 px-2 py-1 font-semibold text-sm'>Finalizada</span>
                            :
                            <span className='rounded-lg bg-orange-600/20 text-orange-600 px-2 py-1 font-semibold text-sm'>Pendente</span>
                    }
                </div>

                <div className='col-span-2 flex flex-row items-center text-center gap-2 flex-wrap'>
                    <span className={classNames(quantidadeResumos > 0 ? "bg-orange-600/20 text-orange-600 shadow" : "bg-gray-600/10 text-gray-400", 'flex-1 whitespace-nowrap rounded-lg px-2 py-1 font-semibold text-sm')}>{quantidadeResumos} {quantidadeResumos === 1 ? "Resumo" : "Resumos"}</span>
                    <span className={classNames(quantidadeEventos > 0 ? "bg-yellow-600/20 text-yellow-600 shadow" : "bg-gray-600/10 text-gray-400", 'flex-1 whitespace-nowrap rounded-lg px-2 py-1 font-semibold text-sm')}>{quantidadeEventos} {quantidadeEventos === 1 ? "Evento" : "Eventos"}</span>
                    <span className={classNames(quantidadeNotasFiscais > 0 ? "bg-blue-600/20 text-blue-600 shadow px-2" : "bg-gray-600/10 text-gray-400", 'flex-1 whitespace-nowrap rounded-lg px-2 py-1 font-semibold text-sm')}>{quantidadeNotasFiscais} {quantidadeNotasFiscais === 1 ? "Nota Fiscal" : "Notas Fiscais"}</span>
                </div>

                <div>
                    <div className={`text-sm font-medium text-gray-800 truncate flex`}>
                        <p className='truncate flex-1'>
                            {motivo || "Motivo não informado"}
                        </p>
                    </div>
                    <div className="text-sm text-gray-500 truncate">
                        <p className='truncate'>Status: {codigoStatus}</p>
                    </div>
                </div>


                <div className="justify-between flex">
                    <div>
                        <div className={`text-sm font-medium text-gray-800 truncate flex`}>
                            <p className='truncate flex'> NSU Busca: {ultimoNSURequisicao || 0}</p>
                        </div>
                        <div className="text-sm text-gray-500 flex truncate">
                            <p className='truncate mr-1'>NSU Resposta: {ultimoNSUResposta || 0}</p>
                        </div>
                    </div>

                    <Menu as="div" className="relative self-center inline-block text-left">

                        {(quantidadeEventos > 0 || quantidadeResumos > 0 || quantidadeNotasFiscais > 0) &&
                            <div>
                                <Menu.Button className="-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600">
                                    <span className="sr-only">Opções</span>
                                    <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                </Menu.Button>
                            </div>
                        }

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="z-30 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">

                                    {quantidadeResumos > 0 && <Menu.Item>
                                        {({ active }) => (
                                            <div
                                                className={classNames(
                                                    active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'flex px-4 py-2 text-sm'
                                                )}
                                                onClick={() => setModalResumos(dadosLinha)}
                                            >
                                                <DocumentTextIcon className="mr-3 h-5 w-5 m-0 text-gray-400" aria-hidden="true" />
                                                <span>Resumos</span>
                                            </div>
                                        )}
                                    </Menu.Item>}
                                    {quantidadeEventos > 0 && <Menu.Item>
                                        {({ active }) => (
                                            <div
                                                className={classNames(
                                                    active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'flex px-4 py-2 text-sm'
                                                )}
                                                onClick={() => setModalEventos(dadosLinha)}
                                            >
                                                <CalendarIcon className="mr-3 h-5 w-5 m-0 text-gray-400" aria-hidden="true" />
                                                <span>Eventos</span>
                                            </div>
                                        )}
                                    </Menu.Item>}
                                    {quantidadeNotasFiscais > 0 && <Menu.Item>
                                        {({ active }) => (
                                            <div
                                                className={classNames(
                                                    active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'flex px-4 py-2 text-sm'
                                                )}
                                                onClick={() => setModalNotasFiscais(dadosLinha)}
                                            >
                                                <ReceiptTaxIcon className="mr-3 h-5 w-5 m-0 text-gray-400" aria-hidden="true" />
                                                <span>Notas Fiscais</span>
                                            </div>
                                        )}
                                    </Menu.Item>}
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </>
        )
    }

    const LinhaTabela = ({ dadosLinha }) => {
        return (
            <li className="cursor-default">
                <div className="bg-white px-4 py-2 sm:px-6">
                    {props.idCliente ?
                        <div className="grid xl:grid-cols-5 grid-cols-1 gap-2">
                            <DadosMDELinha dadosLinha={dadosLinha} />
                        </div> :
                        <div className="grid xl:grid-cols-6 grid-cols-1 gap-4">
                            <ClienteLinha cliente={dadosLinha.cliente} />
                            <DadosMDELinha dadosLinha={dadosLinha} />
                        </div>
                    }
                </div>
            </li>
        )
    }

    useEffect(() => {
        if (!acessoVerificado) {
            verificaAcesso("mde", //location.pathname.split("/")[1]
                { acao: setListar, nome: desc_funcionalidade + "_LISTAR" },
            )
            setAcessoVerificado(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acessoVerificado])

    useEffect(() => {
        if (acessoVerificado) {
            if (listar) {
                carregaMDEs(registrosPorPagina, paginaAtual)
                carregaOpcoesCliente()
            } else {
                toast.error(`Sem acesso a Listagem de ${desc_funcionalidade}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setTimeout(() => {
                    navigate('/')
                }, 100)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listar])

    const carregaMDEs = async (pageSize = registrosPorPagina, currentPage = 0) => {
        setLoading(true);

        const filtros = {
            pageSize,
            currentPage,
            pesquisa: filtroPesquisa,
            clienteId: props.idCliente || filtroCliente.value,
            filtroTotalizador,
            fimPeriodo: fimPeriodo,
            inicioPeriodo: inicioPeriodo,
        }

        //caso esteja na página de cliente
        if (props.clienteId) filtros.clienteId = props.idCliente

        const response = await getListMDE(filtros)

        if (response.sucesso) {
            setPaginaAtual(response.data.dadosListagem.currentPage)
            setTotalRegistros(response.data.dadosListagem.totalRegisters)
            setTotalPaginas(response.data.dadosListagem.totalPages)


            setQuantidadeTotal(response.data.quantidadeTotal)
            setQuantidadeAtualizado(response.data.quantidadeAtualizado)
            setQuantidadePendente(response.data.quantidadePendente)
            setQuantidadeErro(response.data.quantidadeErro)

            console.log("🚀 ~ file: index.js:349 ~ carregaMDEs ~ response.data", response.data)
            setListaMDE(response.data.dadosListagem.dados)
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setLoading(false)
    }

    useEffect(() => {
        if (pesquisaCliente) clienteDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pesquisaCliente])

    function handleSelectCliente(clienteSelecionado) {
        setFiltroCliente(clienteSelecionado)
    }

    const filtroDebounce = useDebounce(carregaMDEs, 500)

    const renderLinhasTabela = () => {
        return listaMDE.map((dadosLinha, key) => {
            return <LinhaTabela key={key} dadosLinha={dadosLinha} />
        })
    }

    if (loading) {
        return <LoadingPage />
    }
    else {
        return (
            <div className={`flex flex-col`}>
                {props.filtros && <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 mt-8'>
                    <div>
                        <label htmlFor="country" className="text-sm mb-1 font-medium text-gray-700 flex items-center">
                            Pesquisa
                            <i className='text-xs text-gray-400 ml-4'>
                                ( Motivo )
                            </i>
                        </label>
                        <input
                            defaultValue={filtroPesquisa}
                            onChange={e => setFiltroPesquisa(e.target.value)}
                            type="text"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Pesquisa " />
                    </div>
                    <div>
                        <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                            Cliente
                        </label>
                        <Select
                            isDisabled={loading}
                            name="cliente"
                            value={filtroCliente}
                            onChange={handleSelectCliente}
                            onInputChange={value => setPesquisaCliente(value)}
                            options={opcoesCliente}
                            placeholder="Selecione"
                            noOptionsMessage={() => "Nenhuma Cliente disponível"}
                            className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div>
                        <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                            Início Perídodo
                        </label>
                        <input
                            defaultValue={inicioPeriodo}
                            onChange={e => {
                                setInicioPeriodo(e.target.value)
                            }}
                            type="date"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                    <div>
                        <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                            Fim Perídodo
                        </label>
                        <input
                            defaultValue={fimPeriodo}
                            onChange={e => {
                                setFimPeriodo(e.target.value)
                            }}
                            type="date"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                </div>}

                {props.filtros && <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-4 mt-8'>
                    <div onClick={() => setFiltroTotalizador(null)}
                        className={classNames(
                            filtroTotalizador == null ? 'border-2 border-gray-500' : 'border-2 border-white',
                            "relative bg-white p-4 shadow rounded-lg overflow-hidden transition duration-200"
                        )}>
                        <dt>
                            <div className="absolute bg-gray-500 rounded-md p-3">
                                <ViewListIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <div className={classNames(filtroTotalizador == null ? "text-gray-500" : "text-gray-500", "flex items-center justify-between ml-16 text-sm font-medium truncate")}>
                                <p className='truncate'>Todos</p>
                                {filtroTotalizador == null ?
                                    <CheckCircleIcon className="shrink-0 text-gray-500 h-5 w-5 m-0" aria-hidden="true" />
                                    : <MinusCircleIcon className="shrink-0 text-gray-400 h-5 w-5 m-0" aria-hidden="true" />
                                }
                            </div>
                        </dt>
                        <dd className="ml-16">
                            <p className="text-2xl font-semibold text-gray-900">{quantidadeTotal}</p>
                        </dd>
                    </div>
                    <div onClick={() => setFiltroTotalizador('atualizado')}
                        className={classNames(
                            filtroTotalizador === "atualizado" ? 'border-2 border-green-500' : 'border-2 border-white',
                            "relative bg-white p-4 shadow rounded-lg overflow-hidden transition duration-200"
                        )}>
                        <dt>
                            <div className="absolute bg-green-500 rounded-md p-3">
                                <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <div className={classNames(filtroTotalizador === "atualizado" ? "text-green-500" : "text-gray-500", "flex items-center justify-between ml-16 text-sm font-medium truncate")}>
                                <p className='truncate'>Finalizadas</p>
                                {filtroTotalizador === "atualizado" ?
                                    <CheckCircleIcon className="shrink-0 text-green-500 h-5 w-5 m-0" aria-hidden="true" />
                                    : <MinusCircleIcon className="shrink-0 text-gray-400 h-5 w-5 m-0" aria-hidden="true" />
                                }
                            </div>
                        </dt>
                        <dd className="ml-16">
                            <p className="text-2xl font-semibold text-gray-900">{quantidadeAtualizado}</p>
                        </dd>
                    </div>
                    <div onClick={() => setFiltroTotalizador('pendente')}
                        className={classNames(
                            filtroTotalizador === "pendente" ? 'border-2 border-orange-500' : 'border-2 border-white',
                            "relative bg-white p-4 shadow rounded-lg overflow-hidden transition duration-200"
                        )}>
                        <dt>
                            <div className="absolute bg-orange-500 rounded-md p-3">
                                <ClockIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <div className={classNames(filtroTotalizador === "pendente" ? "text-orange-500" : "text-gray-500", "flex items-center justify-between ml-16 text-sm font-medium truncate")}>
                                <p className='truncate'>Pendentes</p>
                                {filtroTotalizador === "pendente" ?
                                    <CheckCircleIcon className="shrink-0 text-orange-500 h-5 w-5 m-0" aria-hidden="true" />
                                    : <MinusCircleIcon className="shrink-0 text-gray-400 h-5 w-5 m-0" aria-hidden="true" />
                                }
                            </div>
                        </dt>
                        <dd className="ml-16">
                            <p className="text-2xl font-semibold text-gray-900">{quantidadePendente}</p>
                        </dd>
                    </div>
                    <div onClick={() => setFiltroTotalizador('erro')}
                        className={classNames(
                            filtroTotalizador === "erro" ? 'border-2 border-red-500' : 'border-2 border-white',
                            "relative bg-white p-4 shadow rounded-lg overflow-hidden transition duration-200"
                        )}>
                        <dt>
                            <div className="absolute bg-red-500 rounded-md p-3">
                                <ExclamationIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <div className={classNames(filtroTotalizador === "erro" ? "text-red-500" : "text-gray-500", "flex items-center justify-between ml-16 text-sm font-medium truncate")}>
                                <p className='truncate'>Erros</p>
                                {filtroTotalizador === "erro" ?
                                    <CheckCircleIcon className="shrink-0 text-red-500 h-5 w-5 m-0" aria-hidden="true" />
                                    : <MinusCircleIcon className="shrink-0 text-gray-400 h-5 w-5 m-0" aria-hidden="true" />
                                }
                            </div>
                        </dt>
                        <dd className="ml-16">
                            <p className="text-2xl font-semibold text-gray-900">{quantidadeErro}</p>
                        </dd>
                    </div>
                </div>}

                <div className="bg-white sm:rounded-lg sm:shadow my-8">
                    <div className="bg-white sm:rounded-t-lg px-4 py-5 border-b border-gray-200 sm:px-6">
                        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                            <div className="ml-4 mt-2">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    Execuções
                                </h3>
                            </div>

                            <div className="ml-4 mt-2 flex-shrink-0">
                                <button
                                    onClick={handleCriarEventoExecucao}
                                    type="button"
                                    className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                                    Realizar Consulta
                                </button>
                            </div>
                        </div>
                    </div>

                    {!listaMDE ?
                        <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">
                            <EmptyPage texto="Nenhuma MDE Encontrada" Icone={SearchIcon} />
                        </div>
                        :
                        <ul className="divide-y divide-gray-200">
                            {renderLinhasTabela()}
                        </ul>
                    }

                    <LayoutPaginacaoTabela
                        pagina={paginaAtual}
                        totalRegistros={totalRegistros}
                        registrosPorPagina={registrosPorPagina}
                        totalPaginas={totalPaginas}
                        className="rounded-b-lg"
                        onClickPaginaAnterior={() => {
                            setPaginaAtual(paginaAtual - 1)
                            carregaMDEs(registrosPorPagina, paginaAtual - 1)
                        }}
                        onClickPaginaPosterior={() => {
                            setPaginaAtual(paginaAtual + 1)
                            carregaMDEs(registrosPorPagina, paginaAtual + 1)
                        }}
                        onClickPagina={pagina => {
                            setPaginaAtual(pagina)
                            carregaMDEs(registrosPorPagina, pagina)
                        }} />

                    <Modal
                        alerta={false}
                        open={!!modalResumos}
                        setOpen={setModalResumos}
                    >
                        <MDEResumo MDEId={modalResumos?.id} titulo={`Resumos - ${modalResumos?.motivo}`} />
                    </Modal>

                    <Modal
                        alerta={false}
                        open={!!modalEventos}
                        setOpen={setModalEventos}
                    >
                        <MDEEvento MDEId={modalEventos?.id} titulo={`Eventos - ${modalEventos?.motivo}`} />
                    </Modal>

                    <Modal
                        alerta={false}
                        open={!!modalNotasFiscais}
                        setOpen={setModalNotasFiscais}
                    >
                        <MDENotaFiscal MDEId={modalNotasFiscais?.id} titulo={`Notas Fiscais - ${modalNotasFiscais?.motivo}`} />
                    </Modal>
                </div>
            </div>
        )
    }

}