import { useState, useEffect } from 'react'
import { Switch } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function LayoutSwitch(props) {
  const { nome, descricao, valor, setValor, disabled, className } = props
  const [enabled, setEnabled] = useState(valor)

  useEffect(() => {
    setValor({ "nome": nome, "valor": enabled })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled])

  return (
    <Switch.Group as="li" className={`py-4 flex items-center justify-between ${className}`}>
      <div className="flex flex-col">
        <Switch.Label as="p" className="text-lg font-medium text-gray-900" passive>
          {descricao.charAt(0) + descricao.slice(1).toLowerCase()}
        </Switch.Label>
        {/* <Switch.Description className="text-sm text-gray-500">
          {descricao}
        </Switch.Description> */}
      </div>
      <Switch
        disabled={disabled}
        checked={enabled}
        onChange={setEnabled}
        className={classNames(
          enabled ? 'bg-indigo-500' : 'bg-gray-200',
          'ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </Switch>
    </Switch.Group>
  )
}