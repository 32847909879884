import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { ChevronRightIcon } from '@heroicons/react/solid'
import ModalPerfilAcessoForm from "../../components/ModalPerfilAcessoForm"
import { getPerfilFuncionalidadesByIdPerfil, bloquearAcessosPerfil, liberarAcessosPerfil } from '../../services/perfilFuncionalidade';
import { getPerfilAcessoById } from '../../services/perfilAcesso'
import { LockOpenIcon, LockClosedIcon } from '@heroicons/react/solid'
import LoadingPage from "../../components/LoadingPage";
import verificaAcesso from '../../utils/verificaAcesso';
import { toast } from 'react-toastify';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PerfilAcessoForm() {
    let navigate = useNavigate();
    const location = useLocation();

    let idPerfil = location.state.idPerfil;

    //acessos
    const desc_funcionalidade = "PERFIL_ACESSO_ITEM";
    const [acessoVerificado, setAcessoVerificado] = useState(true);
    const [listar, setListar] = useState(true)
    const [editar, setEditar] = useState(true)

    const [perfilAcesso, setPerfilAcesso] = useState({})
    const [listaPerfisAcesso, setListaPerfisAcesso] = useState([])
    const [modalPerfil, setModalPerfil] = useState(false)
    const tituloModalPerfil = "Permissões"
    const [funcionalidadeSelecionada, setFuncionalidadeSelecioada] = useState({})

    const [loading, setLoading] = useState(true)
    const [salvando, setSalvando] = useState(null)

    useEffect(() => {
        if (!acessoVerificado) {
            verificaAcesso(location.pathname.split("/")[1],
                { acao: setListar, nome: desc_funcionalidade + "_LISTAR" },
                { acao: setEditar, nome: desc_funcionalidade + "_EDITAR" },
            )
            setAcessoVerificado(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acessoVerificado])

    useEffect(() => {
        carregaPerfilAcesso(idPerfil)
        carregaListaFuncionalidadesPerfil(idPerfil)
        if (acessoVerificado) {
            if (listar) {
            } else {
                toast.error(`Sem acesso a Listagem de ${desc_funcionalidade}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setTimeout(() => {
                    navigate('/')
                }, 100)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listar])

    useEffect(() => {
        if (!modalPerfil) {
            setFuncionalidadeSelecioada({})
            if (listar) carregaListaFuncionalidadesPerfil(idPerfil)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalPerfil])

    async function carregaPerfilAcesso(idPerfil) {
        const respostaPerfil = await getPerfilAcessoById(idPerfil)
        if (respostaPerfil.sucesso === true) {
            setPerfilAcesso(respostaPerfil.data)
        } else {
            toast.error(respostaPerfil.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    async function carregaListaFuncionalidadesPerfil(idPerfil) {
        const response = await getPerfilFuncionalidadesByIdPerfil(idPerfil)
        if (response.sucesso === true) {
            setListaPerfisAcesso(response.data)
            setSalvando(null)
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        setLoading(false)
    }

    async function handleLiberarTodos() {
        if (!editar) {
            toast.error(`Sem acesso a Edição de ${desc_funcionalidade}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        setSalvando("liberarTodos")
        const response = await liberarAcessosPerfil(perfilAcesso.id)
        if (response.sucesso) {
            carregaListaFuncionalidadesPerfil(idPerfil)
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        setSalvando(null)
    }

    async function handleBloquearTodos() {
        if (!editar) {
            toast.error(`Sem acesso a Edição de ${desc_funcionalidade}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        setSalvando("bloquearTodos")
        const response = await bloquearAcessosPerfil(perfilAcesso.id)
        if (response.sucesso) {
            carregaListaFuncionalidadesPerfil(idPerfil)
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const LinhaTabela = (dados) => {
        const {
            descricao,
            status
        } = dados.dadosLinha

        let statusColor = 'gray'
        if (status === "ACESSO TOTAL") statusColor = "bg-green-100 text-green-800"
        else if (status === "ACESSO PARCIAL") statusColor = "bg-yellow-100 text-yellow-800"
        else if (status === "SEM ACESSO") statusColor = "bg-red-100 text-red-800"

        return (
            <li onClick={(e) => editar ? handleClickPerfilAcesso(e, dados.dadosLinha) : ''} className={editar ? 'cursor-pointer' : ''}>
                <div className={`bg-white px-4 py-5 sm:px-6 ${editar ? 'hover:bg-gray-100' : ''}`}>
                    <div className="flex space-x-3">
                        <div className="min-w-0 flex-1">
                            <div className="flex items-center justify-between">
                                <div className="text-sm font-medium text-indigo-600 truncate">
                                    {descricao}
                                </div>
                                <div className="ml-2 flex-shrink-0 flex">
                                    <span className={classNames(statusColor, 'px-2 inline-flex text-xs leading-5 font-semibold rounded-full')}>
                                        {status}
                                    </span>
                                </div>
                            </div>
                        </div>

                        {editar && <div className="flex-shrink-0 self-center flex">
                            <ChevronRightIcon className="w-5 text-gray-400" aria-hidden="true" />
                        </div>}
                    </div>
                </div>
            </li>
        )
    }

    function handleClickPerfilAcesso(e, funcSelecionada) {
        if (!editar) {
            toast.error(`Sem acesso a Edição de ${desc_funcionalidade}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        e.preventDefault()
        setFuncionalidadeSelecioada(funcSelecionada)
        setModalPerfil(true)
    }

    const renderLinhasTabela = () => {
        return listaPerfisAcesso.map((dadosLinha, key) => {
            return <LinhaTabela key={key} dadosLinha={dadosLinha} />
        })
    }

    if (loading) {
        return <LoadingPage />
    }

    return (
        <>
            <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">
                <div className="bg-white overflow-hidden sm:rounded-lg sm:shadow my-8">
                    <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                            <div className="ml-4 mt-2">
                                <h4 className="text-lg leading-6 font-medium text-gray-600">
                                    Perfil de Acesso:
                                </h4>
                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    {perfilAcesso.descricao}
                                </h3>
                            </div>
                            <div className="ml-4 mt-2 flex-shrink-0">
                                {editar && <button
                                    onClick={handleBloquearTodos}
                                    type="button"
                                    className="relative inline-flex items-center pl-2 pr-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                                    {salvando === "bloquearTodos" ?
                                        <svg className="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        : <LockClosedIcon className="h-5 w-5 mr-2" />}
                                    Bloquear Todos
                                </button>}

                                {editar && <button
                                    onClick={handleLiberarTodos}
                                    type="button"
                                    className="ml-4 relative inline-flex items-center pl-2 pr-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                                    {salvando === "liberarTodos" ?
                                        <svg className="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        : <LockOpenIcon className="h-5 w-5 mr-2" />}
                                    Liberar Todos
                                </button>}
                            </div>
                        </div>
                    </div>

                    <ul className="divide-y divide-gray-200">
                        {renderLinhasTabela()}
                    </ul>
                </div>
            </div>

            <ModalPerfilAcessoForm
                open={modalPerfil}
                setOpen={setModalPerfil}
                tituloModal={tituloModalPerfil}
                perfilAcesso={perfilAcesso}
                funcionalidadeSelecionada={funcionalidadeSelecionada}
            />
        </>
    )
}