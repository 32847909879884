import React, { useState, useEffect, Fragment } from 'react'
import { getListNotasFiscais, } from '../../../services/mde'
import { useNavigate } from "react-router-dom";
import LayoutPaginacaoTabela from "../../../components/LayoutPaginacaoTabela";
import EmptyPage from "../../../components/EmptyPage";
import LoadingPage from "../../../components/LoadingPage";
import Modal from "../../../components/Modal";
import verificaAcesso from '../../../utils/verificaAcesso';
import { toast } from 'react-toastify';
import { SearchIcon } from '@heroicons/react/outline'
import { CalendarIcon, DotsVerticalIcon } from '@heroicons/react/solid';
import { Menu, Transition } from '@headlessui/react';
import MDEEvento from '../MDEEvento';
import useDebounce from '../../../hooks/useDebounce';
import { formatarDataHora } from '../../../utils/formartar';
import { getSelectCliente } from '../../../services/cliente';
import Select from 'react-select';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
export default function MDENotaFiscal(props) {
    let navigate = useNavigate();
    //acessos
    const desc_funcionalidade = "MDE_NOTA_FISCAL";
    const [acessoVerificado, setAcessoVerificado] = useState(false);
    const [listar, setListar] = useState('')

    const [listaMDENotaFiscal, setListaMDENotaFiscal] = useState([])
    const [modalEventos, setModalEventos] = useState(null);

    const [loading, setLoading] = useState(true)

    //Filtros
    const [filtroPesquisa, setFiltroPesquisa] = useState(null);

    const [filtroCliente, setFiltroCliente] = useState({ value: null, label: "Todas" })
    const [opcoesCliente, setOpcoesCliente] = useState([]);
    const clienteDebounce = useDebounce(carregaOpcoesCliente, 500)
    const [pesquisaCliente, setPesquisaCliente] = useState("")

    const hoje = new Date().toLocaleDateString().split("T")[0].split("/").reverse().join("-");
    const mesPassado = new Date(new Date().setMonth(new Date().getMonth() - 1)).toLocaleDateString().split("T")[0].split("/").reverse().join("-");

    const [inicioPeriodo, setInicioPeriodo] = useState(mesPassado);
    const [fimPeriodo, setFimPeriodo] = useState(hoje);

    useEffect(() => {
        filtroDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtroPesquisa, filtroCliente, inicioPeriodo, fimPeriodo])

    async function carregaOpcoesCliente(pesquisa = pesquisaCliente) {
        const response = await getSelectCliente(pesquisa)
        if (response.sucesso) {
            const opcoes = response.data.map(cliente => {
                return {
                    value: cliente.id,
                    label: cliente.descricao
                }
            })
            setOpcoesCliente([{ value: null, label: 'Todos' }, ...opcoes])
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    //Paginacao Tabela
    const [paginaAtual, setPaginaAtual] = useState(0)
    const [totalRegistros, setTotalRegistros] = useState(0)
    const [totalPaginas, setTotalPaginas] = useState(0)
    const registrosPorPagina = props.quantidadeResultados || 5

    const DadosMDELinha = ({ dadosLinha }) => {
        const {
            // mdeEventoExecucaoId,

            // jsonNotaFiscal,
            // xmlNotaFiscal,

            numeroNotaFiscal,
            chaveNotaFiscal,

            razaoSocialEmitente,
            nomeFantasiaEmitente,

            cnpjEmitente,
            inscricaoEstadualEmitente,

            dataEmissao,

            quantidadeEventos,

            // dataUltimaAlteracao,
            // usuarioUltimaAlteracao,
        } = dadosLinha

        return (
            <>
                <div>
                    <div className={`text-sm font-medium text-gray-800 truncate flex`}>
                        <p className='truncate mr-1'>{razaoSocialEmitente}</p>
                    </div>
                    <div className="text-sm text-gray-500 flex truncate">
                        <p className='truncate mr-1'>{nomeFantasiaEmitente}</p>
                    </div>
                </div>

                <div>
                    <div className={`text-sm font-medium text-gray-800 truncate flex`}>
                        <p className='truncate mr-1'>Número: {numeroNotaFiscal}</p>
                    </div>
                    <div className="text-sm text-gray-500 flex truncate">
                        <p className='truncate mr-1'>Chave: {chaveNotaFiscal}</p>
                    </div>
                </div>


                <div>
                    <div className={`text-sm font-medium text-gray-800 truncate flex`}>
                        <p className='truncate mr-1'>CNPJ: {cnpjEmitente}</p>
                    </div>
                    <div className="text-sm text-gray-500 flex truncate">
                        <p className='truncate mr-1'>I.E.: {inscricaoEstadualEmitente}</p>
                    </div>
                </div>


                <div className="justify-between flex">
                    <div>
                        <div className={`text-sm font-medium text-gray-800 truncate`}>
                            <p className='truncate mr-1'>Emissão: {formatarDataHora(dataEmissao)}</p>
                        </div>
                        <span className={classNames(quantidadeEventos > 0 ? "bg-orange-600/20 text-orange-600" : "bg-gray-600/10 text-gray-400", "w-fit whitespace-nowrap rounded-lg  px-2 py-1 font-semibold text-sm")}>{quantidadeEventos} {quantidadeEventos === 1 ? "evento" : "eventos"}</span>
                    </div>
                    <Menu as="div" className="relative self-center inline-block text-left">
                        {quantidadeEventos > 0 &&
                            <div>
                                <Menu.Button className="-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600">
                                    <span className="sr-only">Opções</span>
                                    <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                </Menu.Button>
                            </div>
                        }

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="z-30 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                    {quantidadeEventos > 0 && <Menu.Item>
                                        {({ active }) => (
                                            <div
                                                className={classNames(
                                                    active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'flex px-4 py-2 text-sm'
                                                )}
                                                onClick={() => setModalEventos(dadosLinha)}
                                            >
                                                <CalendarIcon className="mr-3 h-5 w-5 m-0 text-gray-400" aria-hidden="true" />
                                                <span>Eventos</span>
                                            </div>
                                        )}
                                    </Menu.Item>}
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </>
        )
    }

    const ClienteLinha = ({ cliente }) => {
        return (
            <div>
                <div className="text-sm font-medium text-gray-800 truncate flex capitalize">
                    <p className='truncate mr-1'>{cliente.razaoSocial.toLowerCase()}</p>
                </div>
                <div className="text-sm text-gray-500 truncate capitalize">
                    <p className='truncate mr-1'>{cliente.nomeFantasia.toLowerCase()}</p>
                </div>
            </div>
        )
    }

    const LinhaTabela = ({ dadosLinha }) => {
        return (
            <li className="cursor-default">
                <div className="bg-white px-4 py-2 sm:px-6">
                    {props.idCliente ?
                        <div className="grid xl:grid-cols-4 grid-cols-1 gap-2">
                            <DadosMDELinha dadosLinha={dadosLinha} />
                        </div> :
                        <div className="grid xl:grid-cols-5 grid-cols-1 gap-4">
                            <ClienteLinha cliente={dadosLinha.cliente} />
                            <DadosMDELinha dadosLinha={dadosLinha} />
                        </div>
                    }
                </div>
            </li>
        )
    }

    useEffect(() => {
        if (!acessoVerificado) {
            verificaAcesso("mde", //location.pathname.split("/")[1]
                { acao: setListar, nome: desc_funcionalidade + "_LISTAR" },
            )
            setAcessoVerificado(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acessoVerificado])

    useEffect(() => {
        if (acessoVerificado) {
            if (listar) {
                carregaNotasFiscaisMDE(registrosPorPagina, paginaAtual)
                carregaOpcoesCliente()
            }
            else {
                toast.error(`Sem acesso a Listagem de ${desc_funcionalidade}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setTimeout(() => {
                    navigate('/')
                }, 100)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listar])

    const carregaNotasFiscaisMDE = async (pageSize = registrosPorPagina, currentPage = 0) => {
        setLoading(true);
        
        const filtros = {
            pageSize,
            currentPage,
            pesquisa: filtroPesquisa,
            MDEId: props.MDEId,
            clienteId: props.idCliente || filtroCliente.value,
            inicioPeriodo: inicioPeriodo,
            fimPeriodo: fimPeriodo,
        }

        const response = await getListNotasFiscais(filtros)

        if (response.sucesso) {
            setPaginaAtual(response.data.currentPage)
            setTotalRegistros(response.data.totalRegisters)
            setTotalPaginas(response.data.totalPages)

            setListaMDENotaFiscal(response.data.dados)
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setLoading(false)
    }

    useEffect(() => {
        if (pesquisaCliente) clienteDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pesquisaCliente])

    function handleSelectCliente(clienteSelecionado) {
        setFiltroCliente(clienteSelecionado)
    }

    const filtroDebounce = useDebounce(carregaNotasFiscaisMDE, 500)

    const renderLinhasTabela = () => {
        return listaMDENotaFiscal.map((dadosLinha, key) => {
            return <LinhaTabela key={key} dadosLinha={dadosLinha} />
        })
    }

    if (loading) {
        return <LoadingPage />
    }
    else {
        return (
            <div className={`flex flex-col`}>
                {props.filtros && <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 mt-8'>
                    <div>
                        <label htmlFor="country" className="text-sm mb-1 font-medium text-gray-700 flex items-center">
                            Pesquisa
                            <i className='text-xs text-gray-400 ml-4'>
                                ( Chave Nota Fiscal, Número Nota Fiscal, Emitente, CNPJ, Inscrição Estadual)
                            </i>
                        </label>
                        <input
                            defaultValue={filtroPesquisa}
                            onChange={e => setFiltroPesquisa(e.target.value)}
                            type="text"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Pesquisa " />
                    </div>
                    <div>
                        <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                            Cliente
                        </label>
                        <Select
                            isDisabled={loading}
                            name="cliente"
                            value={filtroCliente}
                            onChange={handleSelectCliente}
                            onInputChange={value => setPesquisaCliente(value)}
                            options={opcoesCliente}
                            placeholder="Selecione"
                            noOptionsMessage={() => "Nenhuma Cliente disponível"}
                            className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div>
                        <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                            Início Perídodo
                        </label>
                        <input
                            defaultValue={inicioPeriodo}
                            onChange={e => {
                                setInicioPeriodo(e.target.value)
                            }}
                            type="date"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                    <div>
                        <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                            Fim Perídodo
                        </label>
                        <input
                            defaultValue={fimPeriodo}
                            onChange={e => {
                                setFimPeriodo(e.target.value)
                            }}
                            type="date"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                </div>}

                <div className="bg-white sm:rounded-lg sm:shadow my-8">
                    <div className="bg-white sm:rounded-t-lg px-4 py-5 border-b border-gray-200 sm:px-6">
                        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                            <div className="ml-4 mt-2">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    {props.titulo || "Notas Fiscais"}
                                </h3>
                            </div>
                        </div>
                    </div>

                    {!listaMDENotaFiscal ?
                        <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">
                            <EmptyPage texto="Nenhuma MDE Encontrada" Icone={SearchIcon} />
                        </div>
                        :
                        <ul className="divide-y divide-gray-200">
                            {renderLinhasTabela()}
                        </ul>
                    }

                    <LayoutPaginacaoTabela
                        pagina={paginaAtual}
                        totalRegistros={totalRegistros}
                        registrosPorPagina={registrosPorPagina}
                        totalPaginas={totalPaginas}
                        className="rounded-b-lg"
                        onClickPaginaAnterior={() => {
                            setPaginaAtual(paginaAtual - 1)
                            carregaNotasFiscaisMDE(registrosPorPagina, paginaAtual - 1)
                        }}
                        onClickPaginaPosterior={() => {
                            setPaginaAtual(paginaAtual + 1)
                            carregaNotasFiscaisMDE(registrosPorPagina, paginaAtual + 1)
                        }}
                        onClickPagina={pagina => {
                            setPaginaAtual(pagina)
                            carregaNotasFiscaisMDE(registrosPorPagina, pagina)
                        }} />
                </div>

                <Modal
                    alerta={false}
                    open={!!modalEventos}
                    setOpen={setModalEventos}
                >
                    <MDEEvento
                        titulo={`Eventos - ${modalEventos?.numeroNotaFiscal}`}
                        chaveNotaFiscal={modalEventos?.chaveNotaFiscal}
                    />
                </Modal>
            </div>
        )
    }

}