import instance from '../configAxios';

export const getClienteById = async (id) => {
    const axios = await instance();

    try {
        const response = await axios.get(`/cliente/${id}`)
        return { sucesso: response.status, data: response.data }
    } catch (error) {
        return { sucesso: false, mensagem: error.response.data };
    }
}

export const getClienteLogado = async (id) => {
    const axios = await instance();

    try {
        const response = await axios.get(`/cliente/logado`)
        return { sucesso: response.status, data: response.data }
    } catch (error) {
        if (!error.response) {
            return {
                sucesso: false,
                mensagem: "Não foi possível conectar ao servidor."
            }
        } else {
            return {
                sucesso: false,
                mensagem: error.response.data
            }
        }
    }
}

export const getListCliente = async (filtros) => {
    const axios = await instance();

    try {
        const response = await axios.post(`/cliente/listagem`, filtros)
        return { sucesso: true, data: response.data }
    } catch (error) {
        if (!error.response) {
            return {
                sucesso: false,
                mensagem: "Não foi possível conectar ao servidor."
            }
        } else {
            return {
                sucesso: false,
                mensagem: error.response.data
            }
        }
    }
}

export const postCliente = async novoCliente => {
    const axios = await instance();

    try {
        const response = await axios.post(`/cliente`, novoCliente)
        return { sucesso: true, data: response.data }
    } catch (error) {
        if (!error.response) {
            return {
                sucesso: false,
                mensagem: "Não foi possível conectar ao servidor."
            }
        } else {
            return {
                sucesso: false,
                mensagem: error.response.data
            }
        }
    }
}

export const putCliente = async (idCliente, clienteEditado) => {
    const axios = await instance();

    try {
        const response = await axios.put(`/cliente/${idCliente}`, clienteEditado)
        return { sucesso: true, data: response.data }
    } catch (error) {
        if (!error.response) {
            return {
                sucesso: false,
                mensagem: "Não foi possível conectar ao servidor."
            }
        } else {
            return {
                sucesso: false,
                mensagem: error.response.data
            }
        }
    }
}

export const alterarSenhaCliente = async (id, novaSenha) => {
    const axios = await instance();

    try {
        const response = await axios.patch(`/cliente/alterarSenha/${id}`, novaSenha)
        return { sucesso: true, data: response.data }
    } catch (error) {
        if (!error.response) {
            return {
                sucesso: false,
                mensagem: "Não foi possível conectar ao servidor."
            }
        } else {
            return {
                sucesso: false,
                mensagem: error.response.data
            }
        }
    }
}

export const deletarCliente = async idCliente => {
    const axios = await instance();

    try {
        const response = await axios.delete(`/cliente/${idCliente}`)
        return { sucesso: true, data: response.data }
    } catch (error) {
        if (!error.response) {
            return {
                sucesso: false,
                mensagem: "Não foi possível conectar ao servidor."
            }
        } else {
            return {
                sucesso: false,
                mensagem: error.response.data
            }
        }
    }
}

export const getSelectCliente = async (pesquisa, idRevenda) => {
    const axios = await instance();
    try {
        const response = await axios.get(`/Cliente/select?pesquisa=${pesquisa}&idRevenda=${idRevenda}`)
        return { sucesso: true, data: response.data }
    } catch (error) {
        if (!error.response) {
            return {
                sucesso: false,
                mensagem: "Não foi possível conectar ao servidor."
            }
        } else {
            return {
                sucesso: false,
                mensagem: error.response.data
            }
        }
    }
}

export const getResumoCLiente = async (tipoResumo) => {
    const axios = await instance();

    try {
        const response = await axios.get(`/Cliente/resumo/${tipoResumo}`)
        return { sucesso: true, data: response.data }
    } catch (error) {
        if (!error.response) {
            return {
                sucesso: false,
                mensagem: "Não foi possível conectar ao servidor."
            }
        } else {
            return {
                sucesso: false,
                mensagem: error.response.data
            }
        }
    }
}

export const getResumoMensalCLiente = async () => {
    const axios = await instance();

    try {
        const response = await axios.get(`/Cliente/resumo/mensal`)
        return { sucesso: true, data: response.data }
    } catch (error) {
        if (!error.response) {
            return {
                sucesso: false,
                mensagem: "Não foi possível conectar ao servidor."
            }
        } else {
            return {
                sucesso: false,
                mensagem: error.response.data
            }
        }
    }
}

export const getListAnaliseDeUso = async (filtros) => {
    const axios = await instance();

    try {
        const response = await axios.post(`/cliente/AnaliseDeUso`, filtros)
        return { sucesso: true, data: response.data }
    } catch (error) {
        if (!error.response) {
            return {
                sucesso: false,
                mensagem: "Não foi possível conectar ao servidor."
            }
        } else {
            return {
                sucesso: false,
                mensagem: error.response.data
            }
        }
    }
}