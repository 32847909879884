import instance from '../configAxios';

export const getItensMenu = async controller => {
  const axios = await instance();

  try {
    const request = {
      ControllerAtual: controller,
      ActionAtual: ""
    }
    const response = await axios.post(`/Menu`, request)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}