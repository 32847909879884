import React, { useState, useEffect } from 'react'
import { ClipboardIcon, DownloadIcon } from '@heroicons/react/solid'
import { getListSolicitacaoDownload } from '../../services/solicitacaoDownload.js'
import { useNavigate } from "react-router-dom";
import EmptyPage from "../../components/EmptyPage";
import LoadingPage from "../../components/LoadingPage";
import verificaAcesso from '../../utils/verificaAcesso';
import { toast } from 'react-toastify';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function SolicitacaoDownloadListagem(props) {
    let navigate = useNavigate();

    //acessos
    const desc_funcionalidade = "SOLICITACAO_DOWNLOAD";
    const [acessoVerificado, setAcessoVerificado] = useState(false);
    const [listar, setListar] = useState('')
    const [listaSolicitacaoDownload, setListaSolicitacaoDownload] = useState([])
    const [loading, setLoading] = useState(true)

    const LinhaTabela = (props) => {
        let {
            arquivoId,
            arquivo,
            dataConclusao,
            dataSolicitacao,
            filtros,
            progresso,
            quantidadeBaixada,
            quantidadeTotal,
            usuario,
        } = props.dadosLinha;

        return (
            <li className="cursor-default">
                <div className="bg-white px-4 py-4 sm:px-6">
                    <div className="grid lg:grid-cols-4 grid-cols-1 gap-4 items-center">
                        <div className='lg:col-span-2'>
                            <div className='flex flex-row items-center text-gray-800 text-sm font-semibold truncate'>
                                <p className='truncate mr-1'>Usuário Solicitação:  </p>{usuario.nome}
                            </div>
                            <div className='text-gray-500 text-sm flex flex-row space-x-1 items-center capitalize'>
                                <p>{filtros}</p>
                            </div>
                        </div>


                        <div className='w-full flex flex-row items-center lg:justify-center gap-1 text-gray-600'>
                            {quantidadeBaixada > 0 && quantidadeBaixada < quantidadeTotal &&
                                <>
                                    <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
                                        <div className="p-2 bg-blue-600 rounded-full dark:bg-blue-500" style={{ width: progresso + '%' }}></div>
                                    </div>
                                    <p className='mr-1 mr-4 font-bold'>
                                        <span className='ml-1 text-xs'>{quantidadeBaixada}/{quantidadeTotal}</span>
                                    </p>
                                </>
                            }
                            {quantidadeBaixada === 0 &&
                                <div className='text-yellow-500 font-semibold flex flex-row gap-2'>
                                    <p>Aguardando processamento</p>
                                </div>
                            }
                            {quantidadeBaixada === quantidadeTotal && arquivo?.dropboxLinkDownload &&
                                <a href={arquivo?.dropboxLinkDownload} target="_blank" rel="noreferrer" className='text-green-500 font-semibold flex flex-row gap-2'>
                                    <p>Arquivo Disponível</p>
                                    <DownloadIcon className='w-5' />
                                </a>
                            }
                            {quantidadeBaixada > 0 && quantidadeBaixada === quantidadeTotal && !arquivo?.dropboxLinkDownload &&
                                <div className='text-yellow-500 font-semibold flex flex-row gap-2'>
                                    <p>Finalizando Arquivo</p>
                                </div>
                            }
                            {arquivoId && !arquivo?.dropboxLinkDownload &&
                                <div className='text-red-500 font-semibold flex flex-row gap-2'>
                                    <p>Arquivo Indisponível</p>
                                </div>
                            }
                        </div>

                        <div className='flex flex-col lg:items-end'>
                            <div className='flex flex-row items-center text-gray-800 text-sm font-semibold truncate'>
                                <p className='truncate mr-1'>Solicitação:  </p>{new Date(dataSolicitacao).toLocaleDateString()}
                            </div>
                            <div className='text-gray-500 text-sm flex flex-row space-x-1 items-center'>
                                <p className='truncate'>{dataConclusao ? "Finalizado: " + new Date(dataConclusao).toLocaleDateString() : "-"}</p>
                            </div>
                        </div>

                    </div>
                </div>
            </li >
        )
    }

    useEffect(() => {
        if (!acessoVerificado) {
            verificaAcesso("solicitacao-download",
                { acao: setListar, nome: desc_funcionalidade + "_LISTAR" },
            )
            setAcessoVerificado(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acessoVerificado])

    useEffect(() => {
        if (acessoVerificado) {
            if (listar) {
                setInterval(() => {
                    carregaSolicitacaoDownloads();
                }, 1000);
            } else {
                toast.error(`Sem acesso a Listagem de ${desc_funcionalidade}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setTimeout(() => {
                    navigate('/')
                }, 100)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listar])

    const carregaSolicitacaoDownloads = async () => {
        const response = await getListSolicitacaoDownload()

        if (response.sucesso) {
            if (response.data.length > 0) setListaSolicitacaoDownload(response.data)
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setLoading(false)
    }

    const renderLinhasTabela = () => {
        return listaSolicitacaoDownload.map((dadosLinha, key) => {
            return <LinhaTabela key={key} dadosLinha={dadosLinha} />
        })
    }

    if (loading) {
        return <LoadingPage />
    }
    else {
        return (
            <>
                {!listaSolicitacaoDownload ?
                    <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">
                        <div className="bg-white overflow-hidden sm:rounded-lg sm:shadow my-8">
                            <EmptyPage texto="Nenhuma Solicitação Download Cadastrada" Icone={ClipboardIcon} />
                        </div>
                    </div>
                    :
                    <div className={classNames(props.className ? props.className : "mx-4 sm:mx-6 md:mx-8", "flex flex-col")}>
                        <div className={classNames(props.className ? "mt-8" : "my-8", "bg-white sm:rounded-lg sm:shadow overflow-hidden")}>
                            <div className="bg-white sm:rounded-t-lg px-4 py-5 border-b border-gray-200 sm:px-6">
                                <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                                    <div className="ml-4 mt-2">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Solicitações Download
                                        </h3>
                                    </div>
                                    <div className="ml-4 mt-2 flex-shrink-0">

                                    </div>
                                </div>
                            </div>

                            <ul className="divide-y divide-gray-200">
                                {renderLinhasTabela()}
                            </ul>
                        </div>
                    </div>
                }
            </>
        )
    }

}