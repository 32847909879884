import React, { useState, useEffect, Fragment } from 'react'
import { baixarTodosArquivosCLiente, getListArquivoCliente, getListSelectTipoDocumentoArquivoCLiente, getListSelectTipoMovimentoArquivoCLiente, updateArquivoCliente } from '../../services/arquivoCliente.js'
import { useNavigate, useLocation } from "react-router-dom";
import LayoutPaginacaoTabela from "../../components/LayoutPaginacaoTabela";
import EmptyPage from "../../components/EmptyPage";
import LoadingPage from "../../components/LoadingPage";
import Loading from "../../components/Loading";
import { toast } from 'react-toastify';
import { UserAddIcon } from '@heroicons/react/outline'
import { CheckIcon, DocumentTextIcon, DotsVerticalIcon, DownloadIcon, EyeIcon, SwitchHorizontalIcon } from '@heroicons/react/solid';
import API_URL from '../../utils/api.js';
import Select from 'react-select';
import useDebounce from '../../hooks/useDebounce';
import verificaAcesso from '../../utils/verificaAcesso';
import { getSelectCliente } from '../../services/cliente.js';
import { Menu, Transition } from '@headlessui/react'
import SolicitacaoDownloadListagem from '../SolicitacaoDownload/index.js';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ArquivoClienteListagem() {
    let navigate = useNavigate();
    const location = useLocation();
    const idRevenda = localStorage.getItem("@IdRevenda");

    //acessos
    const desc_funcionalidade = "DOCUMENTO_FISCAL";
    const [acessoVerificado, setAcessoVerificado] = useState(false);
    const [listar, setListar] = useState('')
    const [download, setDownload] = useState('')
    const [downloadGrupo, setDownloadGrupo] = useState('')

    const [listaArquivoCliente, setListaArquivoCliente] = useState([])
    const [arquivoSelecionado, setArquivoSelecionado] = useState(null)
    const [baixandoArquivo, setBaixandoArquivo] = useState(false)
    const [baixandoTodos, setBaixandoTodos] = useState(false)

    //Filtros
    const [mesSelecionado, setMesSelecionado] = useState(`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10 ? `0${new Date().getMonth() + 1}` : new Date().getMonth() + 1}`);
    const [filtroPeriodo, setFiltroPeriodo] = useState({ value: "competencia", label: "Competência" })
    const opcoesPeriodo = [
        { value: null, label: "Todas" },
        { value: 'emissao', label: "Emissão" },
        { value: 'competencia', label: "Competência" }
    ];
    const [opcoesTipoDocumento, setOpcoesTipoDocumento] = useState([]);
    const [filtroTipoDocumento, setFiltroTipoDocumento] = useState({ value: null, label: "Todas" })
    const [opcoesTipoMovimento, setOpcoesTipoMovimento] = useState([]);
    const [filtroTipoMovimento, setFiltroTipoMovimento] = useState({ value: null, label: "Todas" })

    const [filtroCliente, setFiltroCliente] = useState({ value: null, label: "Todas" })
    const [opcoesCliente, setOpcoesCliente] = useState([]);
    const clienteDebounce = useDebounce(carregaOpcoesCliente, 500)
    const [pesquisaCliente, setPesquisaCliente] = useState("")


    const [loading, setLoading] = useState(true)

    const filtroDebounce = useDebounce(carregaArquivoClientes, 500)

    useEffect(() => {
        filtroDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mesSelecionado, filtroPeriodo, filtroTipoDocumento, filtroTipoMovimento, filtroCliente
    ])

    async function carregaOpcoesCliente(pesquisa = pesquisaCliente) {
        const response = await getSelectCliente(pesquisa, idRevenda)
        if (response.sucesso) {
            const opcoes = response.data.map(cliente => {
                return {
                    value: cliente.id,
                    label: cliente.descricao
                }
            })
            setOpcoesCliente([{ value: null, label: 'Todos' }, ...opcoes])
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    //Paginacao Tabela
    const [paginaAtual, setPaginaAtual] = useState(0)
    const [totalRegistros, setTotalRegistros] = useState(0)
    const [totalPaginas, setTotalPaginas] = useState(0)
    const registrosPorPagina = 10

    async function atualizaDownloadArquivo(id) {
        const response = await updateArquivoCliente(id);
        if (response.sucesso)
            carregaArquivoClientes()
        else {
            toast.error(response.error, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    async function downloadArquivoSelecionado() {
        if (!download) {
            toast.error(`Sem acesso a Download de ${desc_funcionalidade}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        if (baixandoTodos || baixandoArquivo) {
            toast.info(`Download em andamento`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            return;
        }

        setBaixandoArquivo(true)

        try {
            window.open(arquivoSelecionado.dropboxLinkDownload, "_blank");

            toast.success(`Arquivo salvo com sucesso!`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setBaixandoArquivo(false)

            atualizaDownloadArquivo(arquivoSelecionado.id)

            setTimeout(() => {
                setArquivoSelecionado(null)
            }, 5000);
        } catch (error) {
            toast.error(`Erro ao baixar arquivo`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setBaixandoArquivo(false)
            setArquivoSelecionado(null)
        }

    }

    async function downloadTodosAquivos() {
        if (!downloadGrupo) {
            toast.error(`Sem acesso a Download de Grupo de ${desc_funcionalidade}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        if (filtroCliente.value == null) {
            toast.error(`Selecione um cliente para realizar essa operação`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        if (filtroPeriodo.value == null) {
            toast.error("Selecione um tipo de período para realizar essa operação", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        if (listaArquivoCliente == null) {
            toast.error(`Nenhum arquivo na lista`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        setBaixandoTodos(true)

        const filtros = {
            clienteId: filtroCliente.value,
            periodo: new Date(mesSelecionado).toISOString().split("T")[0],
            tipoPeriodo: filtroPeriodo.value,
            tipoDocumento: filtroTipoDocumento.value,
            tipoMovimento: filtroTipoMovimento.value,
        }

        const response = await baixarTodosArquivosCLiente(filtros)

        if (response.sucesso) {
            setTimeout(() => {
                toast.success(`Download Solicitado com suceso!`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setBaixandoTodos(false)
            }, 5000);
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setBaixandoTodos(false)
    }

    const carregaOpcoesTipoDocumento = async () => {
        setLoading(true)

        const response = await getListSelectTipoDocumentoArquivoCLiente()

        if (response.sucesso) {
            const lista = response.data.map(tipoDocumento => { return { value: tipoDocumento, label: tipoDocumento } })
            const opcaoNula = { value: null, label: 'Selecione' }
            setOpcoesTipoDocumento([opcaoNula, ...lista])
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setLoading(false)
    }

    const carregaOpcoesTipoMovimento = async () => {
        setLoading(true)

        const response = await getListSelectTipoMovimentoArquivoCLiente()

        if (response.sucesso) {
            const lista = response.data.map(tipoMovimento => { return { value: tipoMovimento, label: tipoMovimento } })
            const opcaoNula = { value: null, label: 'Selecione' }
            setOpcoesTipoMovimento([opcaoNula, ...lista])
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setLoading(false)
    }

    useEffect(() => {
        if (arquivoSelecionado) downloadArquivoSelecionado()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [arquivoSelecionado])


    const LinhaTabela = ({ dadosLinha }) => {
        const {
            tipoMovimento,
            chaveAcesso,
            tipoDocumento,
            dataEmissao,
            ultimoDownload,
            dataCompetencia,
            arquivoEnvioId,
            arquivoEnvio,
            arquivoRetornoId,
            arquivoRetorno,
            arquivoCancelamentoId,
            arquivoCancelamento,
            arquivoId,
            arquivo,
            cliente,
            documento,
            documentoSerie,
            protocolo,
            contingencia,
            status,
            motivo,
            ambiente,
        } = dadosLinha

        const documentoMask = (d) => {
            if (cliente.tipoPessoa === "PF" || d.length <= 11) {
                return d.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
            } else if (cliente.tipoPessoa === "PJ" || d.length > 11) {
                return d.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{1})/, '$1.$2.$3/$4-$5');
            }
        }

        return (
            <li className="cursor-default">
                <div className="bg-white px-4 py-2 sm:px-6 py-4">
                    <div className={classNames(download ? "lg:grid-cols-7" : "lg:grid-cols-6", "grid grid-cols-1 gap-4")}>

                        <div className="flex flex-row flex-wrap shrink-0 lg:col-span-2 col-span-auto">
                            <img
                                className="h-12 w-12 mb-2 mr-4 rounded-lg object-contain"
                                src={cliente.arquivoLogoId ? `${API_URL}/arquivo/download/${cliente.arquivoLogoId}` : require('../../assets/images/user.png')}
                                alt=""
                            />
                            <div className="grid grid-cols-1 flex-1">
                                <div className="text-sm font-medium text-gray-800 flex capitalize">
                                    <p className='break-words print:text-xs mr-1'>{cliente.id} - {cliente.nomeFantasia}</p>
                                </div>
                                <div className="flex text-sm text-gray-500 flex">
                                    <p className='break-words print:text-xs mr-1'>{documentoMask(cliente.documento)}</p>
                                </div>
                                <div className="text-sm font-medium text-gray-800 capitalize flex">
                                    <p className='break-words print:text-xs mr-1'>{cliente.razaoSocial}</p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='flex flex-row items-center text-gray-800 text-sm font-semibold truncate'>
                                <p className='truncate mr-1'>Documento:  </p>{documento}
                                {documentoSerie && <p className='text-gray-400 ml-1'>Série: {documentoSerie}</p>}
                            </div>
                            <div className='text-gray-500 text-sm flex flex-row space-x-1 items-center'>
                                <p className='truncate'>Chave: {chaveAcesso}</p>
                            </div>
                        </div>

                        <div>
                            {!arquivoId &&
                                <div className={classNames(status === 100 ? 'bg-green-200 text-green-600' : 'bg-red-200 text-red-600', 'mr-2 h-fit space-x-1 text-xs font-semibold rounded-md py-1 px-2 w-fit')}>
                                    <p>{status} - {motivo}</p>
                                </div>
                            }
                        </div>

                        <div className='text-sm'>
                            <p className='text-gray-800 text-sm font-semibold'>Protocolo: {protocolo || " - "}</p>
                            <div className='flex flex-row space-x-2'>
                                <span className={classNames(ambiente === "Produção" ? 'bg-blue-300 text-blue-800' : 'bg-purple-300 text-purple-800', 'text-xs font-semibold rounded-md py-1 px-2')}>
                                    {ambiente}
                                </span>
                                {contingencia &&
                                    <span className='text-xs font-semibold rounded-md py-1 px-2 bg-orange-300 text-orange-600 text-white'>
                                        Contingência
                                    </span>
                                }
                            </div>

                        </div>

                        <div className='lg:justify-self-center'>
                            <div className='text-gray-800 text-sm font-semibold flex flex-row items-center space-x-1'>
                                <SwitchHorizontalIcon className='w-4 h-4 text-gray-600' />
                                <p>{tipoMovimento}</p>
                            </div>
                            <div className='text-gray-500 text-sm font-semibold flex flex-row items-center space-x-1'>
                                <DocumentTextIcon className='w-4 h-4 text-gray-400' />
                                <p>{tipoDocumento}</p>
                            </div>
                        </div>

                        <div className="justify-between flex">
                            <div>
                                <p className='text-gray-800 text-sm font-semibold'>Emissão: {new Date(dataEmissao).getMonth() + 1 < 10 ? `0${new Date(dataEmissao).getMonth() + 1}` : new Date(dataEmissao).getMonth() + 1}/{new Date(dataEmissao).getFullYear()}</p>
                                <p className='text-gray-500 text-sm'>Competência: {new Date(dataCompetencia).getMonth() + 1 < 10 ? `0${new Date(dataCompetencia).getMonth() + 1}` : new Date(dataCompetencia).getMonth() + 1}/{new Date(dataCompetencia).getFullYear()}</p>
                            </div>
                            <Menu as="div" className="relative inline-block text-left self-center">
                                <div>
                                    <Menu.Button className="-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600">
                                        <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                    </Menu.Button>
                                </div>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="z-30 origin-top-right absolute divide-y-2 divide-gray-200 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            {arquivoId && <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href={arquivo.dropboxLinkView}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        disabled={baixandoTodos || baixandoArquivo}
                                                        className={classNames(
                                                            active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'flex px-4 py-2 text-sm justify-between'
                                                        )}
                                                    >
                                                        {arquivoSelecionado?.id !== arquivoId &&
                                                            <div className='items-center flex flex-row justify-between w-full'>
                                                                <p className='text-gray-600 mr-2 font-semibold text-sm'>Visualizar arquivo Pricipal</p>
                                                                <EyeIcon className={classNames(ultimoDownload ? 'text-gray-400' : 'text-green-600', 'h-5')} />
                                                            </div>
                                                        }
                                                        {arquivoSelecionado?.id === arquivoId && baixandoArquivo && <Loading fill={'green'} />}
                                                        {arquivoSelecionado?.id === arquivoId && !baixandoArquivo &&
                                                            <CheckIcon className='h-6 text-green-600' />
                                                        }
                                                    </a>

                                                )}
                                            </Menu.Item>}
                                            {arquivoEnvio && <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href={arquivoEnvio.dropboxLinkView}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        disabled={baixandoTodos || baixandoArquivo}
                                                        className={classNames(
                                                            active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'flex px-4 py-2 text-sm justify-between'
                                                        )}
                                                    >
                                                        {arquivoSelecionado?.id !== arquivoEnvioId &&
                                                            <div className='items-center flex flex-row justify-between w-full'>
                                                                <p className='text-gray-600 mr-2 font-semibold text-sm'>Visualizar arquivo Envio</p>
                                                                <EyeIcon className={classNames(ultimoDownload ? 'text-gray-400' : 'text-green-600', 'h-5 h-5')} />
                                                            </div>
                                                        }
                                                        {arquivoSelecionado?.id === arquivoEnvioId && baixandoArquivo && <Loading fill={'green'} />}
                                                        {arquivoSelecionado?.id === arquivoEnvioId && !baixandoArquivo &&
                                                            <CheckIcon className='h-6 h-6 text-green-600' />
                                                        }
                                                    </a>

                                                )}
                                            </Menu.Item>}
                                            {arquivoRetornoId && <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href={arquivoRetorno.dropboxLinkView}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        disabled={baixandoTodos || baixandoArquivo}
                                                        className={classNames(
                                                            active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'flex px-4 py-2 text-sm justify-between'
                                                        )}
                                                    >
                                                        {arquivoSelecionado?.id !== arquivoRetornoId &&
                                                            <div className='items-center flex flex-row justify-between w-full'>
                                                                <p className='text-gray-600 mr-2 font-semibold text-sm'>Visualizar arquivo Retorno</p>
                                                                <EyeIcon className={classNames(ultimoDownload ? 'text-gray-400' : 'text-green-600', 'h-5 h-5')} />
                                                            </div>
                                                        }
                                                        {arquivoSelecionado?.id === arquivoRetornoId && baixandoArquivo && <Loading fill={'green'} />}
                                                        {arquivoSelecionado?.id === arquivoRetornoId && !baixandoArquivo &&
                                                            <CheckIcon className='h-6 h-6 text-green-600' />
                                                        }
                                                    </a>

                                                )}
                                            </Menu.Item>}
                                            {arquivoCancelamentoId && <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href={arquivoCancelamento.dropboxLinkView}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        disabled={baixandoTodos || baixandoArquivo}
                                                        className={classNames(
                                                            active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'flex px-4 py-2 text-sm justify-between'
                                                        )}
                                                    >
                                                        {arquivoSelecionado?.id !== arquivoCancelamentoId &&
                                                            <div className='items-center flex flex-row justify-between w-full'>
                                                                <p className='text-gray-600 mr-2 font-semibold text-sm'>Visualizar arquivo Cancelamento</p>
                                                                <EyeIcon className={classNames(ultimoDownload ? 'text-gray-400' : 'text-green-600', 'h-5 h-5')} />
                                                            </div>
                                                        }
                                                        {arquivoSelecionado?.id === arquivoCancelamentoId && baixandoArquivo && <Loading fill={'green'} />}
                                                        {arquivoSelecionado?.id === arquivoCancelamentoId && !baixandoArquivo &&
                                                            <CheckIcon className='h-6 h-6 text-green-600' />
                                                        }
                                                    </a>

                                                )}
                                            </Menu.Item>}
                                        </div>

                                        <div className="py-1">
                                            {arquivoId && <Menu.Item>
                                                {({ active }) => (
                                                    <div
                                                        onClick={() => { setArquivoSelecionado(arquivo) }}
                                                        disabled={baixandoTodos || baixandoArquivo}
                                                        className={classNames(
                                                            active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'flex px-4 py-2 text-sm justify-between'
                                                        )}
                                                    >
                                                        {arquivoSelecionado?.id !== arquivoId &&
                                                            <div className='items-center flex flex-row justify-between w-full'>
                                                                <p className='text-gray-600 mr-2 font-semibold text-sm'>Baixar arquivo Pricipal</p>
                                                                <DownloadIcon className={classNames(ultimoDownload ? 'text-gray-400' : 'text-green-600', 'h-5 h-5')} />
                                                            </div>
                                                        }
                                                        {arquivoSelecionado?.id === arquivoId && baixandoArquivo && <Loading fill={'green'} />}
                                                        {arquivoSelecionado?.id === arquivoId && !baixandoArquivo &&
                                                            <CheckIcon className='h-6 h-6 text-green-600' />
                                                        }
                                                    </div>

                                                )}
                                            </Menu.Item>}
                                            {arquivoEnvio && <Menu.Item>
                                                {({ active }) => (
                                                    <div
                                                        onClick={() => { setArquivoSelecionado(arquivoEnvio) }}
                                                        disabled={baixandoTodos || baixandoArquivo}
                                                        className={classNames(
                                                            active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'flex px-4 py-2 text-sm justify-between'
                                                        )}
                                                    >
                                                        {arquivoSelecionado?.id !== arquivoEnvioId &&
                                                            <div className='items-center flex flex-row justify-between w-full'>
                                                                <p className='text-gray-600 mr-2 font-semibold text-sm'>Baixar arquivo Envio</p>
                                                                <DownloadIcon className={classNames(ultimoDownload ? 'text-gray-400' : 'text-green-600', 'h-5 h-5')} />
                                                            </div>
                                                        }
                                                        {arquivoSelecionado?.id === arquivoEnvioId && baixandoArquivo && <Loading fill={'green'} />}
                                                        {arquivoSelecionado?.id === arquivoEnvioId && !baixandoArquivo &&
                                                            <CheckIcon className='h-6 h-6 text-green-600' />
                                                        }
                                                    </div>

                                                )}
                                            </Menu.Item>}
                                            {arquivoRetornoId && <Menu.Item>
                                                {({ active }) => (
                                                    <div
                                                        onClick={() => { setArquivoSelecionado(arquivoRetorno) }}
                                                        disabled={baixandoTodos || baixandoArquivo}
                                                        className={classNames(
                                                            active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'flex px-4 py-2 text-sm justify-between'
                                                        )}
                                                    >
                                                        {arquivoSelecionado?.id !== arquivoRetornoId &&
                                                            <div className='items-center flex flex-row justify-between w-full'>
                                                                <p className='text-gray-600 mr-2 font-semibold text-sm'>Baixar arquivo Retorno</p>
                                                                <DownloadIcon className={classNames(ultimoDownload ? 'text-gray-400' : 'text-green-600', 'h-5 h-5')} />
                                                            </div>
                                                        }
                                                        {arquivoSelecionado?.id === arquivoRetornoId && baixandoArquivo && <Loading fill={'green'} />}
                                                        {arquivoSelecionado?.id === arquivoRetornoId && !baixandoArquivo &&
                                                            <CheckIcon className='h-6 h-6 text-green-600' />
                                                        }
                                                    </div>

                                                )}
                                            </Menu.Item>}
                                            {arquivoCancelamentoId && <Menu.Item>
                                                {({ active }) => (
                                                    <div
                                                        onClick={() => { setArquivoSelecionado(arquivoCancelamento) }}
                                                        disabled={baixandoTodos || baixandoArquivo}
                                                        className={classNames(
                                                            active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'flex px-4 py-2 text-sm justify-between'
                                                        )}
                                                    >
                                                        {arquivoSelecionado?.id !== arquivoCancelamentoId &&
                                                            <div className='items-center flex flex-row justify-between w-full'>
                                                                <p className='text-gray-600 mr-2 font-semibold text-sm'>Baixar arquivo Cancelamento</p>
                                                                <DownloadIcon className={classNames(ultimoDownload ? 'text-gray-400' : 'text-green-600', 'h-5 h-5')} />
                                                            </div>
                                                        }
                                                        {arquivoSelecionado?.id === arquivoCancelamentoId && baixandoArquivo && <Loading fill={'green'} />}
                                                        {arquivoSelecionado?.id === arquivoCancelamentoId && !baixandoArquivo &&
                                                            <CheckIcon className='h-6 h-6 text-green-600' />
                                                        }
                                                    </div>

                                                )}
                                            </Menu.Item>}
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>

                    </div>
                </div>
            </li>
        )
    }

    useEffect(() => {
        if (!acessoVerificado) {
            verificaAcesso(location.pathname.split("/")[1],
                { acao: setListar, nome: desc_funcionalidade + "_LISTAR" },
                { acao: setDownload, nome: desc_funcionalidade + "_DOWNLOAD" },
                { acao: setDownloadGrupo, nome: desc_funcionalidade + "_DOWNLOAD_GRUPO" },
            )
            setAcessoVerificado(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acessoVerificado])

    useEffect(() => {
        if (acessoVerificado) {
            if (listar) {
                carregaArquivoClientes(registrosPorPagina, paginaAtual)
                carregaOpcoesTipoDocumento()
                carregaOpcoesTipoMovimento()
                carregaOpcoesCliente()
            } else {
                toast.error(`Sem acesso a Listagem de ${desc_funcionalidade}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setTimeout(() => {
                    navigate('/')
                }, 100)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listar])

    async function carregaArquivoClientes(pageSize = registrosPorPagina, currentPage = 0) {
        let filtros = {
            clienteId: filtroCliente.value,
            pageSize,
            periodo: new Date(mesSelecionado).toISOString().split("T")[0],
            tipoPeriodo: filtroPeriodo.value,
            tipoDocumento: filtroTipoDocumento.value,
            tipoMovimento: filtroTipoMovimento.value,
            currentPage,
            revendaId: idRevenda,
        }

        const response = await getListArquivoCliente(filtros)

        if (response.sucesso) {
            setPaginaAtual(response.data.currentPage)
            setTotalRegistros(response.data.totalRegisters)
            setTotalPaginas(response.data.totalPages)

            setListaArquivoCliente(response.data.dados)
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setLoading(false)
    }

    const renderLinhasTabela = () => {
        return listaArquivoCliente.map((dadosLinha, key) => {
            return <LinhaTabela key={key} dadosLinha={dadosLinha} />
        })
    }

    useEffect(() => {
        if (pesquisaCliente) clienteDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pesquisaCliente])

    function handleSelectCliente(clienteSelecionado) {
        setFiltroCliente(clienteSelecionado)
    }

    if (loading) {
        return <LoadingPage />
    }

    else {
        return (
            <>
                {!listaArquivoCliente ?
                    <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">
                        <div className="bg-white overflow-hidden sm:rounded-md sm:shadow my-8">
                            <EmptyPage texto="Nenhuma ArquivoCliente Cadastrado" Icone={UserAddIcon} />
                        </div>
                    </div>
                    :
                    <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">
                        <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 mt-8'>
                            <div className='lg:col-span-2'>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                                    Cliente
                                </label>
                                <Select
                                    isDisabled={loading}
                                    name="cliente"
                                    value={filtroCliente}
                                    onChange={handleSelectCliente}
                                    onInputChange={value => setPesquisaCliente(value)}
                                    options={opcoesCliente}
                                    placeholder="Selecione"
                                    noOptionsMessage={() => "Nenhuma Cliente disponível"}
                                    className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>


                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                                    Tipo Documento
                                </label>
                                <Select
                                    isDisabled={loading}
                                    value={filtroTipoDocumento}
                                    onChange={filtro => setFiltroTipoDocumento(filtro)}
                                    options={opcoesTipoDocumento}
                                    placeholder="Selecione"
                                    noOptionsMessage={() => "Nenhuma opção disponível"}
                                    className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>

                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                                    Tipo Movimento
                                </label>
                                <Select
                                    isDisabled={loading}
                                    value={filtroTipoMovimento}
                                    onChange={filtro => setFiltroTipoMovimento(filtro)}
                                    options={opcoesTipoMovimento}
                                    placeholder="Selecione"
                                    noOptionsMessage={() => "Nenhuma opção disponível"}
                                    className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>

                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                                    Tipo Perídodo
                                </label>
                                <Select
                                    isDisabled={loading}
                                    value={filtroPeriodo}
                                    onChange={filtro => {
                                        setFiltroPeriodo(filtro)
                                    }}
                                    options={opcoesPeriodo}
                                    placeholder="Selecione"
                                    noOptionsMessage={() => "Nenhuma opção disponível"}
                                    className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                                    Mês
                                </label>
                                <input
                                    defaultValue={mesSelecionado}
                                    onChange={e => {
                                        setMesSelecionado(e.target.value)
                                    }}
                                    type="month"
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                            </div>
                        </div>

                        <SolicitacaoDownloadListagem className="mx-0" />

                        <div className="bg-white overflow-hidden sm:rounded-md sm:shadow my-8">
                            <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                                <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                                    <div className="ml-4 mt-2">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Documentos Fiscais
                                        </h3>
                                    </div>
                                    {listaArquivoCliente.length > 0 && downloadGrupo && <div className="ml-4 mt-2 flex-shrink-0">
                                        <button
                                            type="button"
                                            onClick={downloadTodosAquivos}
                                            disabled={(baixandoArquivo || baixandoTodos)}
                                            className={`${(baixandoArquivo || baixandoTodos) ? "cursor-not-allowed" : "cursor-pointer"} inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500`}
                                        >
                                            {baixandoTodos && <Loading fill="white" className="mr-2 w-5 h-5" />}
                                            Baixar Todos
                                        </button>
                                    </div>}
                                </div>
                            </div>

                            <ul className="divide-y divide-gray-200">
                                {renderLinhasTabela()}
                            </ul>

                            <LayoutPaginacaoTabela
                                pagina={paginaAtual}
                                totalRegistros={totalRegistros}
                                registrosPorPagina={registrosPorPagina}
                                totalPaginas={totalPaginas}
                                onClickPaginaAnterior={() => {
                                    setPaginaAtual(paginaAtual - 1)
                                    carregaArquivoClientes(registrosPorPagina, paginaAtual - 1)
                                }}
                                onClickPaginaPosterior={() => {
                                    setPaginaAtual(paginaAtual + 1)
                                    carregaArquivoClientes(registrosPorPagina, paginaAtual + 1)
                                }}
                                onClickPagina={pagina => {
                                    setPaginaAtual(pagina)
                                    carregaArquivoClientes(registrosPorPagina, pagina)
                                }} />
                        </div>
                    </div>
                }

            </>
        )
    }

}