import React, { useState, useEffect, Fragment } from 'react'
import LayoutModal from "../../components/LayoutModal"
import { Menu, Transition } from '@headlessui/react'
import { DotsVerticalIcon, PencilIcon, TrashIcon, ClipboardIcon } from '@heroicons/react/solid'
import { getListUsuario, deletarUsuario } from '../../services/usuario.js'
import { useNavigate, useLocation } from "react-router-dom";
import LayoutPaginacaoTabela from "../../components/LayoutPaginacaoTabela";
import EmptyPage from "../../components/EmptyPage";
import LoadingPage from "../../components/LoadingPage";
import verificaAcesso from '../../utils/verificaAcesso';
import API_URL from '../../utils/api'
import { toast } from 'react-toastify';
import { IdentificationIcon } from '@heroicons/react/outline'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function UsuarioListagem() {
    let navigate = useNavigate();
    const location = useLocation();

    //acessos
    const desc_funcionalidade = "USUARIO";
    const [acessoVerificado, setAcessoVerificado] = useState(false);
    const [listar, setListar] = useState('')
    const [cadastrar, setCadastrar] = useState('')
    const [editar, setEditar] = useState('')
    const [excluir, setExcluir] = useState('')

    const [listaUsuario, setListaUsuario] = useState([])
    const [confirmacaoDeletar, setConfirmacaoDeletar] = useState(false)
    const [usuarioSelecionado, setUsuarioSelecionado] = useState(null)

    const [loading, setLoading] = useState(true)

    const [paginaAtual, setPaginaAtual] = useState(0)
    const [totalRegistros, setTotalRegistros] = useState(0)
    const [totalPaginas, setTotalPaginas] = useState(0)
    const registrosPorPagina = 10

    function handleNovoUsuario() {
        if (!cadastrar) {
            toast.error(`Sem acesso a Cadastro de ${desc_funcionalidade}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        navigate(`/usuario/form`)
    }

    function handleVisualizarUsuario(id) {
        if (!editar) {
            toast.error(`Sem acesso a Visualização de ${desc_funcionalidade}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        navigate(`/usuario/form`, { state: { "idUsuario": id } })
    }

    function handleDeleteUsuario(dados) {
        if (!excluir) {
            toast.error(`Sem acesso a Exclusão de ${desc_funcionalidade}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        setUsuarioSelecionado(dados)
        setConfirmacaoDeletar(true)
    }

    async function confirmDeleteUsuario() {
        await deletarUsuario(usuarioSelecionado.id)
        carregaUsuarios(registrosPorPagina, paginaAtual)

        toast.success('Usuário deletado com sucesso.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const LinhaTabela = (dados) => {
        const {
            nome,
            usuarioUltimaAlteracao,
            dataUltimaAlteracao,
            descricaoPerfilAcesso,
            id,
            arquivoFotoId,
            revenda,
            contador
        } = dados.dadosLinha

        const dataUltimaAlteracaoFormatada = new Date(dataUltimaAlteracao).toLocaleString('pt-BR')

        const documentoMask = (d) => {
            if (d.length <= 11) {
                return d.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
            } else if (d.length > 11) {
                return d.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{1})/, '$1.$2.$3/$4-$5');
            }
        }

        return (
            <li className="cursor-default">
                <div className="bg-white px-4 py-5 sm:px-6">
                    <div className="grid lg:grid-cols-4 grid-cols-1 gap-3">

                        <div className="min-w-0 flex-1 flex flex-row item-center">
                            <img
                                className="h-12 w-12 rounded-full mr-2 object-contain"
                                src={arquivoFotoId ? `${API_URL}/arquivo/download/${arquivoFotoId}` : require('../../assets/images/user.png')}
                                alt="Usuário"
                            />
                            <div>
                                <div className="flex items-center justify-between">
                                    <div className="text-sm font-medium text-indigo-600 truncate">
                                        {nome}
                                    </div>
                                </div>
                                <div className="mt-1 flex justify-between">
                                    <div className="sm:flex">
                                        <div className="flex items-center text-sm text-gray-500">
                                            <ClipboardIcon className="h-5 w-5 flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                            {descricaoPerfilAcesso}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="min-w-0 flex-1 flex flex-row item-center truncate">
                            {contador &&
                                <>
                                    <img
                                        className="h-12 w-12 rounded-lg mr-2 object-contain"
                                        src={contador.arquivoLogoId ? `${API_URL}/arquivo/download/${contador.arquivoLogoId}` : require('../../assets/images/user.png')}
                                        alt="Usuário"
                                    />
                                    <div className='truncate'>
                                        <div className="text-sm font-medium text-indigo-600 truncate">
                                            Contador: {contador.razaoSocial}
                                        </div>
                                        <div className="flex mt-1 items-center text-sm text-gray-500 truncate">
                                            Documento: {documentoMask(contador.documento)}
                                        </div>
                                    </div>
                                </>
                            }
                        </div>

                        <div className="min-w-0 flex-1 flex flex-row item-center truncate">
                            {revenda &&
                                <>
                                    <img
                                        className="h-12 w-12 rounded-lg mr-2 object-contain"
                                        src={revenda.arquivoLogoId ? `${API_URL}/arquivo/download/${revenda.arquivoLogoId}` : require('../../assets/images/user.png')}
                                        alt="Usuário"
                                    />
                                    <div className='truncate'>
                                        <div className="text-sm font-medium text-indigo-600 truncate">
                                            Revenda: {revenda.razaoSocial}
                                        </div>
                                        <div className="flex mt-1 items-center text-sm text-gray-500 truncate">
                                            Documento: {documentoMask(revenda.documento)}
                                        </div>
                                    </div>
                                </>
                            }
                        </div>

                        <div className="flex-shrink-0 self-center flex lg:justify-end justify-between">
                            <div className="mr-5 flex-shrink-0 flex">
                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    <PencilIcon className="mr-1 h-5 w-3 text-green-800" aria-hidden="true" />
                                    {usuarioUltimaAlteracao} - {dataUltimaAlteracaoFormatada}
                                </span>
                            </div>
                            {(editar || excluir) && <Menu as="div" className="relative inline-block text-left">
                                <div>
                                    <Menu.Button className="-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600">
                                        <span className="sr-only">Open options</span>
                                        <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                    </Menu.Button>
                                </div>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="z-30 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            {editar && <Menu.Item>
                                                {({ active }) => (
                                                    <div
                                                        onClick={e => handleVisualizarUsuario(id)}
                                                        className={classNames(
                                                            active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'flex px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        <PencilIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                        <span>Editar Perfil</span>
                                                    </div>

                                                )}
                                            </Menu.Item>}
                                            {excluir && <Menu.Item>
                                                {({ active }) => (
                                                    <div
                                                        className={classNames(
                                                            active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'flex px-4 py-2 text-sm'
                                                        )}
                                                        onClick={() => handleDeleteUsuario(dados.dadosLinha)}
                                                    >
                                                        <TrashIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                        <span>Deletar</span>
                                                    </div>
                                                )}
                                            </Menu.Item>}
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>}
                        </div>
                    </div>
                </div>
            </li>
        )
    }

    useEffect(() => {
        if (!acessoVerificado) {
            verificaAcesso(location.pathname.split("/")[1],
                { acao: setListar, nome: desc_funcionalidade + "_LISTAR" },
                { acao: setCadastrar, nome: desc_funcionalidade + "_CADASTRAR" },
                { acao: setEditar, nome: desc_funcionalidade + "_EDITAR" },
                { acao: setExcluir, nome: desc_funcionalidade + "_EXCLUIR" }
            )
            setAcessoVerificado(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acessoVerificado])

    useEffect(() => {
        if (acessoVerificado) {
            if (listar) {
                carregaUsuarios(registrosPorPagina, paginaAtual)
            } else {
                toast.error(`Sem acesso a Listagem de ${desc_funcionalidade}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setTimeout(() => {
                    navigate('/')
                }, 100)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listar])

    const carregaUsuarios = async (pageSize, currentPage) => {
        const response = await getListUsuario(pageSize, currentPage)

        if (response.sucesso) {
            setPaginaAtual(response.data.currentPage)
            setTotalRegistros(response.data.totalRegisters)
            setTotalPaginas(response.data.totalPages)

            setListaUsuario(response.data.dados)
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setLoading(false)
    }

    const renderLinhasTabela = () => {
        return listaUsuario.map((dadosLinha, key) => {
            return <LinhaTabela key={key} dadosLinha={dadosLinha} />
        })
    }

    if (loading) {
        return <LoadingPage />
    }

    else {
        return (
            <>
                {!listaUsuario.length ?
                    <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">
                        <div className="bg-white overflow-hidden sm:rounded-lg sm:shadow my-8">
                            <EmptyPage texto="Nenhum Usuário Cadastrado" Icone={IdentificationIcon} botao={true} acao={handleNovoUsuario} />
                        </div>
                    </div>
                    :
                    <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">
                        <div className="bg-white overflow-hidden sm:rounded-lg sm:shadow my-8">
                            <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                                <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                                    <div className="ml-4 mt-2">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Usuário
                                        </h3>
                                    </div>
                                    <div className="ml-4 mt-2 flex-shrink-0">
                                        {cadastrar && <button
                                            onClick={handleNovoUsuario}
                                            type="button"
                                            className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                                            Adicionar
                                        </button>}
                                    </div>
                                </div>
                            </div>

                            <ul className="divide-y divide-gray-200">
                                {renderLinhasTabela()}
                            </ul>

                            <LayoutPaginacaoTabela
                                pagina={paginaAtual}
                                totalRegistros={totalRegistros}
                                registrosPorPagina={registrosPorPagina}
                                totalPaginas={totalPaginas}
                                onClickPaginaAnterior={() => {
                                    setPaginaAtual(paginaAtual - 1)
                                    carregaUsuarios(registrosPorPagina, paginaAtual - 1)
                                }}
                                onClickPaginaPosterior={() => {
                                    setPaginaAtual(paginaAtual + 1)
                                    carregaUsuarios(registrosPorPagina, paginaAtual + 1)
                                }}
                                onClickPagina={pagina => {
                                    setPaginaAtual(pagina)
                                    carregaUsuarios(registrosPorPagina, pagina)
                                }} />
                        </div>
                    </div>
                }

                <LayoutModal
                    open={confirmacaoDeletar}
                    setOpen={setConfirmacaoDeletar}
                    titulo={`Deletar ${usuarioSelecionado?.nome}`}
                    descricao={`Deseja realmente deletar o Usuário ${usuarioSelecionado?.nome}?`}
                    textoBotao="Deletar"
                    acaoBotao={confirmDeleteUsuario}
                />
            </>
        )
    }

}