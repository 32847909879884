import React, { useState, useEffect, Fragment } from 'react'
import LayoutModal from "../../components/LayoutModal"
import { Menu, Transition } from '@headlessui/react'
import { DotsVerticalIcon, UsersIcon, PencilIcon, TrashIcon } from '@heroicons/react/solid'
import { TagIcon } from '@heroicons/react/outline'
import { getListPerfilAcesso, deletarPerfilAcesso } from '../../services/perfilAcesso'
import ModalNovoPerfilAcesso from "../../components/ModalNovoPerfilAcesso";
import { useNavigate, useLocation } from "react-router-dom";
import LayoutPaginacaoTabela from "../../components/LayoutPaginacaoTabela";
import EmptyPage from "../../components/EmptyPage";
import LoadingPage from "../../components/LoadingPage";
import verificaAcesso from '../../utils/verificaAcesso';
import { toast } from 'react-toastify';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function PerfilAcesso() {
  let navigate = useNavigate();
  const location = useLocation();

  //acessos
  const desc_funcionalidade = "PERFIL_ACESSO";
  const [acessoVerificado, setAcessoVerificado] = useState(true);
  const [listar, setListar] = useState(true)
  const [cadastrar, setCadastrar] = useState(true)
  const [editar, setEditar] = useState(true)
  const [excluir, setExcluir] = useState(true)

  const [listaPerfilAcesso, setListaPerfilAcesso] = useState([])
  const [confirmacaoDeletar, setConfirmacaoDeletar] = useState(false)
  const [perfilSelecionado, setPerfilSelecionado] = useState(null)
  const [modalNovoPerfil, setModalNovoPerfil] = useState(false)

  const [paginaAtual, setPaginaAtual] = useState(0)
  const [totalRegistros, setTotalRegistros] = useState(0)
  const [totalPaginas, setTotalPaginas] = useState(0)
  const registrosPorPagina = 10

  const [loading, setLoading] = useState(true)

  const handleNovoPerfilAcesso = () => {
    if (!cadastrar) {
      toast.error(`Sem acesso a Cadastro de ${desc_funcionalidade}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    setModalNovoPerfil(true)
  }

  const handleEditarPerfilAcesso = (id) => {
    if (!editar) {
      toast.error(`Sem acesso a Edição de ${desc_funcionalidade}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    navigate(`/perfil-acesso/form/`, { state: { "idPerfil": id } })
  }

  const handleDeletePerfilAcesso = (dados) => {
    if (!excluir) {
      toast.error(`Sem acesso a Exclusão de ${desc_funcionalidade}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    setPerfilSelecionado(dados)
    setConfirmacaoDeletar(true)
  }

  const confirmDeletePerfilAcesso = async () => {
    const response = await deletarPerfilAcesso(perfilSelecionado.id)
    if (response.sucesso) {
      carregaPerfisAccesso(registrosPorPagina, paginaAtual)
    } else {
      toast.error(response.mensagem, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const LinhaTabela = (dados) => {
    const {
      descricao,
      usuarioUltimaAlteracao,
      dataUltimaAlteracao,
      qtdUsuariosVinculados
    } = dados.dadosLinha

    //formatar data e hora pt-br
    const dataUltimaAlteracaoFormatada = new Date(dataUltimaAlteracao).toLocaleString('pt-BR')

    return (
      <li className="cursor-default">
        <div className="bg-white px-4 py-5 sm:px-6">
          <div className="grid sm:grid-cols-2 grid-cols-1 gap-3">

            <div className="min-w-0 flex-1">
              <div className="flex items-center justify-between">
                <div className="text-sm font-medium text-indigo-600 truncate">
                  {descricao}
                </div>
              </div>
              <div className="mt-2 flex justify-between">
                <div className="sm:flex">
                  <div className="flex items-center text-sm text-gray-500">
                    <UsersIcon className="h-5 w-5 flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                    {qtdUsuariosVinculados} Usuários vinculados
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-shrink-0 self-center flex sm:justify-end justify-between">
              <div className="mr-5 flex-shrink-0 flex">
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                  <PencilIcon className="mr-1 h-5 w-3 text-green-800" aria-hidden="true" />
                  {usuarioUltimaAlteracao} - {dataUltimaAlteracaoFormatada}
                </span>
              </div>
              {(excluir || editar) && <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600">
                    <span className="sr-only">Open options</span>
                    <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="z-30 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {editar && <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={() => handleEditarPerfilAcesso(dados.dadosLinha.id)}
                            className={classNames(
                              active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                              'flex px-4 py-2 text-sm'
                            )}
                          >
                            <PencilIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                            <span>Editar</span>
                          </div>
                        )}
                      </Menu.Item>}
                      {excluir && <Menu.Item>
                        {({ active }) => (
                          <div
                            className={classNames(
                              active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                              'flex px-4 py-2 text-sm'
                            )}
                            onClick={() => handleDeletePerfilAcesso(dados.dadosLinha)}
                          >
                            <TrashIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                            <span>Deletar</span>
                          </div>
                        )}
                      </Menu.Item>}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>}
            </div>

          </div>
        </div>
      </li>
    )
  }

  useEffect(() => {
    if (!acessoVerificado) {
      verificaAcesso(location.pathname.split("/")[1],
        { acao: setListar, nome: desc_funcionalidade + "_LISTAR" },
        { acao: setCadastrar, nome: desc_funcionalidade + "_CADASTRAR" },
        { acao: setEditar, nome: desc_funcionalidade + "_EDITAR" },
        { acao: setExcluir, nome: desc_funcionalidade + "_EXCLUIR" }
      )
      setAcessoVerificado(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acessoVerificado])

  useEffect(() => {
    if (acessoVerificado) {
      if (listar) {
        carregaPerfisAccesso(registrosPorPagina, paginaAtual)
      } else {
        toast.error(`Sem acesso a Listagem de ${desc_funcionalidade}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setTimeout(() => {
          navigate('/')
        }, 100)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listar])

  const carregaPerfisAccesso = async (pageSize, currentPage) => {
    const response = await getListPerfilAcesso(pageSize, currentPage)

    if (response.sucesso) {
      const perfis = response.data
      setPaginaAtual(perfis.currentPage)
      setTotalRegistros(perfis.totalRegisters)
      setTotalPaginas(perfis.totalPages)
      setListaPerfilAcesso(perfis.dados)
    } else {
      toast.error(response.mensagem, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setLoading(false)
  }

  const renderLinhasTabela = () => {
    return listaPerfilAcesso.map((dadosLinha, key) => {
      return <LinhaTabela key={key} dadosLinha={dadosLinha} />
    })
  }

  if (loading) {
    return <LoadingPage />
  }

  else {
    return (
      <>
        {!listaPerfilAcesso.length ?
          <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">
            <div className="bg-white overflow-hidden sm:rounded-lg sm:shadow my-8">
              <EmptyPage texto="Nenhum Perfil de Acesso Cadastrado" Icone={TagIcon} botao={true} acao={handleNovoPerfilAcesso} />
            </div>
          </div>
          :
          <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">
            <div className="bg-white overflow-hidden sm:rounded-lg sm:shadow my-8">
              <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                  <div className="ml-4 mt-2">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Perfil de Acesso
                    </h3>
                  </div>
                  <div className="ml-4 mt-2 flex-shrink-0">
                    {cadastrar && <button
                      onClick={handleNovoPerfilAcesso}
                      type="button"
                      className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                      Adicionar
                    </button>}
                  </div>
                </div>
              </div>

              <ul className="divide-y divide-gray-200">
                {renderLinhasTabela()}
              </ul>

              <LayoutPaginacaoTabela
                pagina={paginaAtual}
                totalRegistros={totalRegistros}
                registrosPorPagina={registrosPorPagina}
                totalPaginas={totalPaginas}
                onClickPaginaAnterior={() => {
                  setPaginaAtual(paginaAtual - 1)
                  carregaPerfisAccesso(registrosPorPagina, paginaAtual - 1)
                }}
                onClickPaginaPosterior={() => {
                  setPaginaAtual(paginaAtual + 1)
                  carregaPerfisAccesso(registrosPorPagina, paginaAtual + 1)
                }}
                onClickPagina={pagina => {
                  setPaginaAtual(pagina)
                  carregaPerfisAccesso(registrosPorPagina, pagina)
                }} />
            </div>
          </div>
        }

        <ModalNovoPerfilAcesso
          open={modalNovoPerfil}
          setOpen={setModalNovoPerfil}
          tituloModal={"Novo Perfil de Acesso"}
        />

        <LayoutModal
          open={confirmacaoDeletar}
          setOpen={setConfirmacaoDeletar}
          titulo={`Deletar ${perfilSelecionado?.descricao}`}
          descricao={`Deseja realmente deletar o Perfil de Acesso ${perfilSelecionado?.descricao}?`}
          textoBotao="Deletar"
          acaoBotao={confirmDeletePerfilAcesso}
        />
      </>
    )
  }
}