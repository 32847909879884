import instance from '../configAxios';

export const getConfiguracao = async (id) => {
    const axios = await instance();

    try {
        const response = await axios.get(`/configuracao`)
        return { sucesso: response.status, data: response.data }
    } catch (error) {
        if (!error.response) {
            return {
                sucesso: false,
                mensagem: "Não foi possível conectar ao servidor."
            }
        } else {
            return {
                sucesso: false,
                mensagem: error.response.data
            }
        }
    }
}

export const definirConfiguracao = async configuracao => {
    const axios = await instance();

    try {
        const response = await axios.post(`/configuracao`, configuracao)
        return { sucesso: true, data: response.data }
    } catch (error) {
        if (!error.response) {
            return {
                sucesso: false,
                mensagem: "Não foi possível conectar ao servidor."
            }
        } else {
            return {
                sucesso: false,
                mensagem: error.response.data
            }
        }
    }
}

export const autorizaDropbox = async codigoAutorizacao => {
    const axios = await instance();

    try {
        const response = await axios.get(`/configuracao/dropbox?authorizationCode=${codigoAutorizacao}`)
        return { sucesso: true, data: response.data }
    } catch (error) {
        if (!error.response) {
            return {
                sucesso: false,
                mensagem: "Não foi possível conectar ao servidor."
            }
        } else {
            return {
                sucesso: false,
                mensagem: error.response.data
            }
        }
    }
}