import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ReactECharts from 'echarts-for-react';
import { getResumoCLiente } from '../../services/cliente';
import { Disclosure, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid';
import TabelaResumo from '../TabelaResumo';
import Scrollbars from 'react-custom-scrollbars';

ChartJS.register(ArcElement, Tooltip, Legend);

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const ResumoCLiente = (props) => {
    var [dadosResumo, setDadosResumo] = useState(null);
    var [dadosTabelaResumo, setDadosTabelaResumo] = useState(null);
    const open = false;

    async function carregaDadosResumo() {
        const response = await getResumoCLiente(props.tipoResumo)

        if (response.sucesso) {
            setDadosTabelaResumo(response.data)

            const qtdMaiores = 5
            const maiores = response.data.length > qtdMaiores ? response.data.slice(0, qtdMaiores) : response.data;

            if (response.data.length > qtdMaiores) {
                let quantidadeOutros = 0;

                response.data.slice(qtdMaiores, response.data.length).forEach(item => {
                    quantidadeOutros += item.quantidade;
                });

                maiores.push({ grupo: "Outros", quantidade: quantidadeOutros })
            }

            const data = maiores.map(item => {
                return {
                    name: item.grupo,
                    value: item.quantidade
                }
            })

            const options = {
                legend: {
                    top: '0%',
                    left: 'center'
                },
                series: [
                    {
                        data: data,
                        type: 'pie',
                        radius: ['30%', '60%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '20',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                    }
                ],
                tooltip: {
                    trigger: 'item',
                },
            };

            setDadosResumo(options);
        } else {
            console.log(response.mensagem);
        }
    }

    useEffect(() => {
        carregaDadosResumo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='w-full'>
            {dadosResumo && <ReactECharts option={dadosResumo} />}

            <Disclosure
                as="div" className="px-4 sm:px-6 py-2 bg-gray-50">
                <dt className="text-lg">
                    <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-xs text-gray-600 font-semibold">Lista Completa</span>
                        <span className="ml-6 h-7 flex items-center">
                            <ChevronDownIcon
                                className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                                aria-hidden="true"
                            />
                        </span>
                    </Disclosure.Button>
                </dt>
                <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                >
                    <Disclosure.Panel as="dd" className="mt-2 overflow-y-hidden h-64">
                        <Scrollbars
                            autoHideDuration={200}
                            thumbMinSize={30}
                            universal={true}
                        >
                            <div className='w-full bg-white '>
                                <TabelaResumo listaDados={dadosTabelaResumo} />
                            </div>
                        </Scrollbars>
                    </Disclosure.Panel>
                </Transition>
            </Disclosure>
        </div >
    );
}

export default ResumoCLiente;