/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { getPermissoesFuncPerfil } from '../../services/perfilAcesso'
import { postPerfilFuncionalidades } from '../../services/perfilFuncionalidade'
import LayoutSwitch from "../LayoutSwitch";
import LoadingPage from "../LoadingPage";
import { LockOpenIcon, LockClosedIcon } from '@heroicons/react/solid'
import { toast } from 'react-toastify';

export default function ModalPerfilAcessoForm(props) {
  const {
    open,
    setOpen,
    tituloModal,
    perfilAcesso,
    funcionalidadeSelecionada
  } = props

  const [funcionalidades, setFuncionalidades] = useState([])
  const [loading, setLoaging] = useState(true)
  const [salvando, setSalvando] = useState(false)

  useEffect(() => {
    if (!!funcionalidadeSelecionada.id) carregaPermissoes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perfilAcesso, funcionalidadeSelecionada])

  async function carregaPermissoes() {
    const response = await getPermissoesFuncPerfil(perfilAcesso.id, funcionalidadeSelecionada.id)

    if (response.sucesso) {
      setFuncionalidades(response.data.funcoes)
    } else {
      toast.error(response.mensagem, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setLoaging(false)
  }

  function handleCloseModal() {
    if (!salvando) {
      setOpen(false)

      setTimeout(() => {
        setLoaging(true)
        setFuncionalidades([])
      }, 300);
    }
  }

  async function handleSalvar() {
    setSalvando(true)
    let acessos = funcionalidades.filter(func => {
      return func.acesso
    })

    const nomesAcessos = acessos.map(acesso => { return acesso.nome })

    const req = {
      idPerfilAcesso: perfilAcesso.id,
      idPerfilFuncionalidade: funcionalidadeSelecionada.id,
      acessos: JSON.stringify(nomesAcessos),
    }

    const response = await postPerfilFuncionalidades(req)

    if (response.sucesso) {
      toast.success("Permissões Alteradas Com Sucesso!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setFuncionalidades([])
      handleCloseModal()
    } else {
      toast.error(response.mensagem, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setSalvando(false)
  }

  function handleLiberarTodos() {
    if (salvando) return

    setFuncionalidades(funcionalidades.map(func => {
      func.acesso = true
      return func
    }))
  }

  function handleBloquearTodos() {
    if (salvando) return

    setFuncionalidades(funcionalidades.map(func => {
      func.acesso = false
      return func
    }))
  }

  function handleChangeAcesso(funcionalidade) {
    if (salvando) return

    const { nome, valor } = funcionalidade

    const funcionalidadesAtualizadas = funcionalidades.map(func => {
      if ((func.nome).toUpperCase() === nome) {
        func.acesso = valor
      }

      return func
    })

    setFuncionalidades(funcionalidadesAtualizadas)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden z-40" onClose={handleCloseModal}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                  <div className="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">{tituloModal}</Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            onClick={handleCloseModal}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {loading && <LoadingPage />}

                    {!loading && (
                      <div className="mt-6 relative flex flex-1 px-4 sm:px-6">
                        <div className="col-span-6 flex-1 flex flex-col sm:col-span-3">

                          <div className="mt-6">
                            <div>
                              <h2 className="text-lg leading-6 font-medium text-gray-900">{funcionalidadeSelecionada.descricao}</h2>
                              <p className="mt-1 text-sm text-gray-500">
                                Permissoes de {funcionalidadeSelecionada.descricao} concedidas a {perfilAcesso.descricao}
                              </p>
                            </div>

                            <>
                              <div className="flex justify-items-end">
                                <button
                                  onClick={() => handleBloquearTodos()}
                                  className="mt-4 mx-4 ml-0 flex hover:bg-red-50 text-red-500">
                                  <LockClosedIcon className="h-5 w-5" />
                                  Bloquear Todos
                                </button>
                                <button
                                  onClick={() => handleLiberarTodos()}
                                  className="mt-4 mx-4 flex hover:bg-green-50 text-green-500">
                                  <LockOpenIcon className="h-5 w-5" />

                                  Liberar Todos
                                </button>
                              </div>

                              <ul className="divide-y divide-gray-200">
                                {funcionalidades.map((func, index) => {
                                  return (
                                    <LayoutSwitch
                                      key={`${index}-${func.acesso}`}
                                      nome={func.nome}
                                      descricao={func.descricao}
                                      valor={func.acesso}
                                      setValor={handleChangeAcesso} />
                                  )
                                })}
                              </ul>
                            </>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {!loading && (
                    <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                      <button
                        type="button"
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={handleCloseModal}
                      >
                        Cancelar
                      </button>
                      <button
                        type="submit"
                        onClick={() => handleSalvar()}
                        disabled={salvando}
                        className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        {salvando && <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>}
                        Salvar
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}