import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { CheckCircleIcon, XIcon } from '@heroicons/react/solid'
import API_URL from '../../utils/api';
import { toast } from 'react-toastify';

export default function MyDropzone(props) {

    let { anexo, setAnexo, anexoNome, texto, className, children, preview } = props
    const [nomeArquivo, setNomeArquivo] = React.useState(anexoNome)

    preview = preview == null ? true : preview

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            toast.error('Formato de Arquivo não permitido', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        }

        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onload = () => {
                setNomeArquivo(file.name)
                setAnexo(file)
            }
            reader.readAsArrayBuffer(file)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    })

    function handleSelectFile() {
        const preview = document.getElementById('file-preview')
        if (preview) preview.classList.add('opacity-0')

        setTimeout(() => {
            setNomeArquivo('')
            setAnexo(null)
        }, 200)
    }

    return (
        <>
            <div
                {...getRootProps({
                    onClick: handleSelectFile,
                    className: `
          transition
          duration-100
          hover:bg-indigo-50
          border-dashed
          border-4
          border-light-indigo-500
          mt-1
          rounded-md
          py-10
          px-4
          text-center
          cursor-pointer ${className}`
                })}>
                <form id="formularioArquivo" method="post" encType="multipart/form-data" action="/api/arquivo">
                    <input type="file" name="Arquivo" id="arquivo" {...getInputProps()} />
                    {children}
                </form>
                <p className="text-sm font-medium text-gray-500">{texto || "Clique ou Arraste o arquivo desejado até aqui"}</p>
            </div>
            {(anexo && preview) &&
                <div id="file-preview" className="transition-all durarion-75 mt-2 flex justify-between border-solid border-2 border-light-indigo-500 rounded-md p-4">
                    <div className='flex flex-row items-center space-x-4 '>
                        <CheckCircleIcon className="w-5 flex-shrink-0 text-green-400" aria-hidden="true" />
                        <img
                            className="h-8 w-8 rounded-md object-contain"
                            src={`${API_URL}/arquivo/download/${anexo.id}`}
                            alt=""
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = require('../../assets/images/default-image.png');
                            }}
                        />
                        <p className="text-sm font-medium text-gray-500 flex flex-1 items-center truncate">{nomeArquivo || anexoNome || "Anexo Certificado"}</p>
                    </div>
                    <XIcon className="w-5 flex-shrink-0 mr-1 text-red-400 cursor-pointer" onClick={() => handleSelectFile()} aria-hidden="true" />
                </div>
            }
        </>
    )
}