import instance from '../configAxios';

export const getPerfilAcessoById = async (id) => {
  const axios = await instance();

  try {
    const response = await axios.get(`/PerfilAcesso/${id}`)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const getListPerfilAcesso = async (pageSize, currentPage) => {
  const axios = await instance();

  try {
    const response = await axios.get(`/perfilAcesso/?pageSize=${pageSize}&currentPage=${currentPage}`)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const getListSelectPerfilAcesso = async () => {
  const axios = await instance();

  try {
    const response = await axios.get(`/PerfilAcesso/select`)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const getPermissoesFuncPerfil = async (perfilId, funcionalidadeId) => {
  const axios = await instance();

  try {
    const response = await axios.get(`/perfilAcessoItem/perfil/${perfilId}/funcionalidade/${funcionalidadeId}`)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const postPerfilAcesso = async novoPerfilAcesso => {
  const axios = await instance();

  try {
    const response = await axios.post(`/perfilAcesso`, novoPerfilAcesso)
    return { sucesso: true, data: response.data }
  } catch (error) {
    if (!error.response) {
      return {
        sucesso: false,
        mensagem: "Não foi possível conectar ao servidor."
      }
    } else {
      return {
        sucesso: false,
        mensagem: error.response.data
      }
    }
  }
}

export const deletarPerfilAcesso = async idPerfil => {
  const axios = await instance();

  try {
    const response = await axios.delete(`/perfilAcesso/${idPerfil}`)
    return { sucesso: true, data: response.data }
  } catch (error) {
    return { sucesso: false, mensagem: "Erro ao excluir Perfil de Acesso" };
  }
}