import React, { useState, useEffect, Fragment } from 'react'
import LayoutModal from "../../components/LayoutModal"
import { Menu, Transition } from '@headlessui/react'
import { DotsVerticalIcon, PencilIcon, TrashIcon, ClipboardIcon } from '@heroicons/react/solid'
import { getListPlano, deletarPlano } from '../../services/plano.js'
import { useNavigate, useLocation } from "react-router-dom";
import LayoutPaginacaoTabela from "../../components/LayoutPaginacaoTabela";
import EmptyPage from "../../components/EmptyPage";
import LoadingPage from "../../components/LoadingPage";
import verificaAcesso from '../../utils/verificaAcesso';
import { toast } from 'react-toastify';
import useDebounce from '../../hooks/useDebounce';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PlanoListagem() {
    let navigate = useNavigate();
    const location = useLocation();

    //acessos
    const desc_funcionalidade = "PLANO";
    const [acessoVerificado, setAcessoVerificado] = useState(false);
    const [listar, setListar] = useState('')
    const [cadastrar, setCadastrar] = useState('')
    const [editar, setEditar] = useState('')
    const [excluir, setExcluir] = useState('')

    const [listaPlano, setListaPlano] = useState([])
    const [confirmacaoDeletar, setConfirmacaoDeletar] = useState(false)
    const [planoSelecionada, setPlanoSelecionada] = useState(null)

    const [loading, setLoading] = useState(true)

    //Filtros
    const [filtroPesquisa, setFiltroPesquisa] = useState(null);

    useEffect(() => {
        filtroDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtroPesquisa])

    //Paginacao Tabela
    const [paginaAtual, setPaginaAtual] = useState(0)
    const [totalRegistros, setTotalRegistros] = useState(0)
    const [totalPaginas, setTotalPaginas] = useState(0)
    const registrosPorPagina = 10

    function handleNovaPlano() {
        if (!cadastrar) {
            toast.error(`Sem acesso a Cadastro de ${desc_funcionalidade}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        navigate(`/plano/form`)
    }

    function handleVisualizarPlano(id) {
        if (!editar) {
            toast.error(`Sem acesso a Visualização de ${desc_funcionalidade}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        navigate(`/plano/form`, { state: { "idPlano": id } })
    }

    function handleDeletePlano(dados) {
        if (!excluir) {
            toast.error(`Sem acesso a Exclusão de ${desc_funcionalidade}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        setPlanoSelecionada(dados)
        setConfirmacaoDeletar(true)
    }

    async function confirmDeletePlano() {
        await deletarPlano(planoSelecionada.id)
        carregaPlanos(registrosPorPagina, paginaAtual)

        toast.success('Plano deletado com sucesso.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const LinhaTabela = (props) => {
        const {
            id,
            quantidadeClientesAtivos,
            quantidadeClientesInativos,

            descricao,
            valor
        } = props.dadosLinha

        return (
            <li className="cursor-default">
                <div className="bg-white px-4 py-4 sm:px-6">
                    <div className="grid lg:grid-cols-4 grid-cols-1 gap-4">
                        <div className="text-sm font-medium text-gray-800 truncate">
                            <p className='truncate mr-1'>{id} - {descricao}</p>
                        </div>
                        <div className="text-sm font-medium text-gray-800 flex truncate">
                            <p className='truncate'>Clientes Ativos: {quantidadeClientesAtivos}</p>
                        </div>
                        <div className="text-sm font-medium text-gray-800 flex truncate">
                            <p className='truncate'>Clientes Inativos: {quantidadeClientesInativos}</p>
                        </div>
                        <div className="self-center justify-between flex">
                            <div className="flex justify-between">
                                <div className="text-sm font-medium text-gray-800 flex truncate">
                                    <p className='truncate mr-1'>Valor</p>: {valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                                </div>
                            </div>
                            {(editar || excluir) && <Menu as="div" className="relative inline-block text-left">
                                <div>
                                    <Menu.Button className="-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600">
                                        <span className="sr-only">Open options</span>
                                        <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                    </Menu.Button>
                                </div>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="z-30 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            {editar && <Menu.Item>
                                                {({ active }) => (
                                                    <div
                                                        onClick={e => handleVisualizarPlano(id)}
                                                        className={classNames(
                                                            active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'flex px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        <PencilIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                        <span>Editar Plano</span>
                                                    </div>

                                                )}
                                            </Menu.Item>}
                                            {excluir && <Menu.Item>
                                                {({ active }) => (
                                                    <div
                                                        className={classNames(
                                                            active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'flex px-4 py-2 text-sm'
                                                        )}
                                                        onClick={() => handleDeletePlano(props.dadosLinha)}
                                                    >
                                                        <TrashIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                        <span>Deletar</span>
                                                    </div>
                                                )}
                                            </Menu.Item>}
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>}
                        </div>
                    </div>
                </div>
            </li>
        )
    }

    useEffect(() => {
        if (!acessoVerificado) {
            verificaAcesso(location.pathname.split("/")[1],
                { acao: setListar, nome: desc_funcionalidade + "_LISTAR" },
                { acao: setCadastrar, nome: desc_funcionalidade + "_CADASTRAR" },
                { acao: setEditar, nome: desc_funcionalidade + "_EDITAR" },
                { acao: setExcluir, nome: desc_funcionalidade + "_EXCLUIR" }
            )
            setAcessoVerificado(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acessoVerificado])

    useEffect(() => {
        if (acessoVerificado) {
            if (listar) {
                carregaPlanos(registrosPorPagina, paginaAtual)
            } else {
                toast.error(`Sem acesso a Listagem de ${desc_funcionalidade}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setTimeout(() => {
                    navigate('/')
                }, 100)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listar])

    const carregaPlanos = async (pageSize = registrosPorPagina, currentPage = 0) => {
        const filtros = {
            pageSize,
            currentPage,
            pesquisa: filtroPesquisa,
        }

        const response = await getListPlano(filtros)

        if (response.sucesso) {
            setPaginaAtual(response.data.currentPage)
            setTotalRegistros(response.data.totalRegisters)
            setTotalPaginas(response.data.totalPages)

            setListaPlano(response.data.dados)
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setLoading(false)
    }

    const filtroDebounce = useDebounce(carregaPlanos, 500)

    const renderLinhasTabela = () => {
        return listaPlano.map((dadosLinha, key) => {
            return <LinhaTabela key={key} dadosLinha={dadosLinha} />
        })
    }

    if (loading) {
        return <LoadingPage />
    }
    else {
        return (
            <>
                {!listaPlano ?
                    <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">
                        <div className="bg-white overflow-hidden sm:rounded-lg sm:shadow my-8">
                            <EmptyPage texto="Nenhuma Plano Cadastrado" Icone={ClipboardIcon} botao={true} acao={handleNovaPlano} />
                        </div>
                    </div>
                    :
                    <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">
                        <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 mt-8'>
                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700 flex items-center">
                                    Pesquisa
                                    <i className='text-xs text-gray-400 ml-4'>
                                        ( Id,
                                        Descrição )
                                    </i>
                                </label>
                                <input
                                    defaultValue={filtroPesquisa}
                                    onChange={e => setFiltroPesquisa(e.target.value)}
                                    type="text"
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Pesquisa " />
                            </div>
                        </div>
                        <div className="bg-white sm:rounded-lg sm:shadow my-8">
                            <div className="bg-white sm:rounded-t-lg px-4 py-5 border-b border-gray-200 sm:px-6">
                                <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                                    <div className="ml-4 mt-2">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Plano
                                        </h3>
                                    </div>
                                    <div className="ml-4 mt-2 flex-shrink-0">
                                        {cadastrar && <button
                                            onClick={handleNovaPlano}
                                            type="button"
                                            className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                                            Adicionar
                                        </button>}
                                    </div>
                                </div>
                            </div>

                            <ul className="divide-y divide-gray-200">
                                {renderLinhasTabela()}
                            </ul>

                            <LayoutPaginacaoTabela
                                pagina={paginaAtual}
                                totalRegistros={totalRegistros}
                                registrosPorPagina={registrosPorPagina}
                                totalPaginas={totalPaginas}
                                className="rounded-b-lg"
                                onClickPaginaAnterior={() => {
                                    setPaginaAtual(paginaAtual - 1)
                                    carregaPlanos(registrosPorPagina, paginaAtual - 1)
                                }}
                                onClickPaginaPosterior={() => {
                                    setPaginaAtual(paginaAtual + 1)
                                    carregaPlanos(registrosPorPagina, paginaAtual + 1)
                                }}
                                onClickPagina={pagina => {
                                    setPaginaAtual(pagina)
                                    carregaPlanos(registrosPorPagina, pagina)
                                }} />
                        </div>
                    </div>
                }

                <LayoutModal
                    open={confirmacaoDeletar}
                    setOpen={setConfirmacaoDeletar}
                    titulo={`Deletar ${planoSelecionada?.descricao}`}
                    descricao={`Deseja realmente deletar o Plano ${planoSelecionada?.id}?`}
                    textoBotao="Deletar"
                    acaoBotao={confirmDeletePlano}
                />
            </>
        )
    }

}