import { getAcesso } from "../services/acesso";

export default async function verificaAcesso(desc_funcionalidade, ...funcionalidades) {
  const response = await getAcesso(desc_funcionalidade);

  funcionalidades.forEach(func => {
    const possuiAcesso = response.data.includes(func.nome)
    func.acao(possuiAcesso)
  });

  return true
}
