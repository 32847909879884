import React, { useState, useEffect, Fragment, useCallback, useRef } from 'react'
import LayoutModal from "../../components/LayoutModal"
import { Menu, Transition } from '@headlessui/react'
import { DotsVerticalIcon, PencilIcon, TrashIcon, CheckIcon, XIcon, ExclamationIcon, UserIcon, CheckCircleIcon, ViewListIcon, MinusCircleIcon, ClipboardCopyIcon } from '@heroicons/react/solid'
import { getListCliente, deletarCliente } from '../../services/cliente.js'
import { useNavigate, useLocation } from "react-router-dom";
import LayoutPaginacaoTabela from "../../components/LayoutPaginacaoTabela";
import EmptyPage from "../../components/EmptyPage";
import LoadingPage from "../../components/LoadingPage";
import verificaAcesso from '../../utils/verificaAcesso';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { getListMunicipiosPorUf, getListUf } from '../../services/endereco'
import useDebounce from '../../hooks/useDebounce';
import API_URL from '../../utils/api'
import { getSelectRevenda } from '../../services/revenda'
import { getSelectContador } from '../../services/contador'
import { UserAddIcon } from '@heroicons/react/outline'
import ModalConfirmacao from '../../components/ModalConfirmacao'
import Scrollbars from 'react-custom-scrollbars'
import ReactToPrint from "react-to-print";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ClienteListagem() {
    let navigate = useNavigate();
    const location = useLocation();
    const idRevenda = localStorage.getItem("@IdRevenda");

    const imprimirRef = useRef(null);
    const onBeforeGetContentResolve = useRef(null);
    const [text, setText] = useState("old boring text");
    const [loadingImpressao, setLoadingImpressao] = useState(false);

    const handleAfterPrint = useCallback(() => {
        carregaClientes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleBeforePrint = useCallback(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnBeforeGetContent = useCallback(async () => {
        setLoadingImpressao(true);

        await carregaClientes(registrosPorPagina, 0, true)

        setText("Loading new text...");

        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;

            setTimeout(() => {
                setLoadingImpressao(false);
                setText("New, Updated Text!");
                resolve();
            }, 2000);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setLoadingImpressao, setText]);

    React.useEffect(() => {
        if (
            text === "New, Updated Text!" &&
            typeof onBeforeGetContentResolve.current === "function"
        ) {
            onBeforeGetContentResolve.current();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onBeforeGetContentResolve.current, text]);

    const reactToPrintContent = React.useCallback(() => {
        return imprimirRef.current;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imprimirRef.current]);

    const reactToPrintTrigger = React.useCallback(() => {
        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
        // to the root node of the returned component as it will be overwritten.

        // Bad: the `onClick` here will be overwritten by `react-to-print`
        // return <button onClick={() => alert('This will not work')}>Print this out!</button>;

        // Good
        return (
            <button
                type="button"
                className="relative mr-4 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                Imprimir
            </button>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //acessos
    const desc_funcionalidade = "CLIENTE";
    const [acessoVerificado, setAcessoVerificado] = useState(false);
    const [listar, setListar] = useState('')
    const [cadastrar, setCadastrar] = useState('')
    const [editar, setEditar] = useState('')
    const [excluir, setExcluir] = useState('')

    const [listaCliente, setListaCliente] = useState([])
    const [confirmacaoDeletar, setConfirmacaoDeletar] = useState(false)
    const [copiarChaveAC, setCopiarChaveAC] = useState(false)
    const [clienteSelecionado, setClienteSelecionado] = useState(null)

    const [quantidadeTotal, setQuantidadeTotal] = useState(0)
    const [quantidadeAtivo, setQuantidadeAtivo] = useState(0)
    const [quantidadeInativo, setQuantidadeInativo] = useState(0)
    const [quantidadeLicencaValida, setQuantidadeLicencaValida] = useState(0)
    const [quantidadeLicencaInvalida, setQuantidadeLicencaInvalida] = useState(0)

    const [loading, setLoading] = useState(true)

    //Filtros
    const [filtroUf, setFiltroUf] = useState({ value: null, label: "Todas" })
    const [opcoesUf, setOpcoesUf] = useState([]);
    const ufDebounce = useDebounce(carregaOpcoesUf, 500)
    const [pesquisaUf, setPesquisaUf] = useState("")

    const [filtroMunicipio, setFiltroMunicipio] = useState({ value: null, label: "Todas" })
    const [opcoesMunicipio, setOpcoesMunicipio] = useState([]);
    const municipioDebounce = useDebounce(carregaOpcoesMunicipio, 500)
    const [pesquisaMunicipio, setPesquisaMunicipio] = useState("")

    const [filtroRevenda, setFiltroRevenda] = useState({ value: null, label: "Todas" })
    const [opcoesRevenda, setOpcoesRevenda] = useState([]);
    const revendaDebounce = useDebounce(carregaOpcoesRevenda, 500)
    const [pesquisaRevenda, setPesquisaRevenda] = useState("")

    const [filtroContador, setFiltroContador] = useState({ value: null, label: "Todas" })
    const [opcoesContador, setOpcoesContador] = useState([]);
    const contadorDebounce = useDebounce(carregaOpcoesContador, 500)
    const [pesquisaContador, setPesquisaContador] = useState("")

    const [filtroPeriodo, setFiltroPeriodo] = useState({ value: null, label: "Todos" })
    const opcoesPeriodo = [
        { value: null, label: "Todas" },
        { value: 'aplicacao', label: "Aplicação" },
        { value: 'validade', label: "Validade" }
    ];

    const hoje = new Date().toLocaleDateString().split("T")[0].split("/").reverse().join("-");
    const mesSeguinte = new Date(new Date().setMonth(new Date().getMonth() + 1)).toLocaleDateString().split("T")[0].split("/").reverse().join("-");

    const [inicioPeriodo, setInicioPeriodo] = useState(hoje);
    const [fimPeriodo, setFimPeriodo] = useState(mesSeguinte);
    const [filtroPesquisa, setFiltroPesquisa] = useState(null);
    const [filtroTotalizador, setFiltroTotalizador] = useState(null)

    useEffect(() => {
        if (filtroUf.value ||
            filtroMunicipio.value ||
            filtroRevenda.value ||
            filtroContador.value ||
            filtroPeriodo.value ||
            filtroPesquisa
        ) setFiltroTotalizador(null)

        filtroDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtroUf, filtroMunicipio, filtroRevenda, filtroContador, filtroPeriodo, inicioPeriodo, fimPeriodo, filtroPesquisa
    ])

    useEffect(() => {
        filtroDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtroTotalizador])

    //Paginacao Tabela
    const [paginaAtual, setPaginaAtual] = useState(0)
    const [totalRegistros, setTotalRegistros] = useState(0)
    const [totalPaginas, setTotalPaginas] = useState(0)
    const registrosPorPagina = 10

    function handleNovoCliente() {
        if (!cadastrar) {
            toast.error(`Sem acesso a Cadastro de ${desc_funcionalidade}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        navigate(`/cliente/form`)
    }

    function handleVisualizarCliente(id) {
        if (!editar) {
            toast.error(`Sem acesso a Visualização de ${desc_funcionalidade}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        navigate(`/cliente/form`, { state: { "idCliente": id } })
    }

    function handleDeleteCliente(dados) {
        if (!excluir) {
            toast.error(`Sem acesso a Exclusão de ${desc_funcionalidade}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        setClienteSelecionado(dados)
        setConfirmacaoDeletar(true)
    }

    function handleCopiarChaveAC(dados) {
        setClienteSelecionado(dados)
        setCopiarChaveAC(true)
    }

    async function confirmDeleteCliente() {
        await deletarCliente(clienteSelecionado.id)
        carregaClientes(registrosPorPagina, paginaAtual)

        toast.success('Cliente deletado com sucesso.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    async function carregaOpcoesUf(pesquisa = pesquisaUf) {
        const response = await getListUf(pesquisa)
        if (response.sucesso) {
            const opcoes = response.data.map(uf => {
                return {
                    value: uf.id,
                    label: uf.descricao
                }
            })
            setOpcoesUf([{ value: null, label: 'Todos' }, ...opcoes])
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    async function carregaOpcoesMunicipio(pesquisa = pesquisaMunicipio, ufId = filtroUf.value) {
        setFiltroMunicipio({ value: null, label: "Selecione" })

        if (!ufId) {
            setOpcoesMunicipio([])
            return
        }

        const response = await getListMunicipiosPorUf(pesquisa, ufId)
        if (response.sucesso) {
            const opcoes = response.data.map(municipio => {
                return {
                    value: municipio.id,
                    label: municipio.descricao
                }
            })
            setOpcoesMunicipio([{ value: null, label: 'Todos' }, ...opcoes])
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    async function carregaOpcoesRevenda(pesquisa = pesquisaRevenda) {
        const response = await getSelectRevenda(pesquisa)
        if (response.sucesso) {
            const opcoes = response.data.map(revenda => {
                return {
                    value: revenda.id,
                    label: revenda.descricao
                }
            })
            setOpcoesRevenda([{ value: null, label: 'Todos' }, ...opcoes])
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    async function carregaOpcoesContador(pesquisa = pesquisaContador) {
        const response = await getSelectContador(pesquisa)
        if (response.sucesso) {
            const opcoes = response.data.map(contador => {
                return {
                    value: contador.id,
                    label: contador.descricao
                }
            })
            setOpcoesContador([{ value: null, label: 'Todos' }, ...opcoes])
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const LinhaTabela = (props) => {
        const {
            id,
            celular,
            email,
            plano,
            arquivoLogoId,
            nomeFantasia,
            razaoSocial,
            ativo,
            documento,
            tipoPessoa,
            dataValidade,
            municipio,
            chaveAC,
            arquivoRevendaId,
            ultimaConsultaLicenca
        } = props.dadosLinha

        let valido = false;
        const dataAtual = new Date();
        if (new Date(dataValidade) >= dataAtual) {
            valido = true;
        }

        const celularMask = (c) => {
            if (c.length < 11) {
                return c.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
            } else {
                return c.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
            }
        }

        const documentoMask = (d) => {
            if (tipoPessoa === "PF" || d.length <= 11) {
                return d.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
            } else if (tipoPessoa === "PJ" || d.length > 11) {
                return d.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{1})/, '$1.$2.$3/$4-$5');
            }
        }

        return (
            <li className={classNames(loadingImpressao ? "py-4" : "py-2", "cursor-default break-inside-avoid-page")}>
                <div className="bg-white px-4 sm:px-6">
                    <div className={classNames(loadingImpressao ? "flex flex-row justify-between" : "grid lg:grid-cols-5 grid-cols-1 gap-4")}>
                        <div className={classNames(loadingImpressao ? "col-span-1 flex-1" : "lg:col-span-2 col-span-auto", "flex flex-row flex-wrap sm:flex-nowrap shrink-0")}>
                            <img
                                className="h-12 w-12 mb-2 mr-4 rounded-lg object-contain"
                                src={arquivoLogoId ? `${API_URL}/arquivo/download/${arquivoLogoId}` : require('../../assets/images/user.png')}
                                alt=""
                            />
                            <div className={classNames(loadingImpressao ? "grid-cols-1" : "grid-cols-1 lg:grid-cols-2", "grid flex-1")}>
                                <div className="text-sm font-medium text-gray-800 flex capitalize">
                                    <p className='break-words print:text-xs mr-1'>{id} - {nomeFantasia}</p>
                                </div>
                                {!loadingImpressao && <div className="flex text-sm text-gray-500">
                                    <p className='break-words print:text-xs mr-1'>{documentoMask(documento)}</p>
                                </div>}
                                <div className="text-sm font-medium text-gray-800 capitalize flex">
                                    <p className='break-words print:text-xs mr-1'>{razaoSocial}</p>
                                </div>
                                {loadingImpressao && <div className="flex text-sm text-gray-500 truncate">
                                    <p className='truncate mr-1'>{documentoMask(documento)}</p>
                                </div>}
                                {chaveAC && !loadingImpressao &&
                                    <div onClick={() => handleCopiarChaveAC(props.dadosLinha)} className="cursor-pointer text-xs font-medium text-green-600 capitalize flex truncate">
                                        <ClipboardCopyIcon className='h-5 w-5 mr-1' /><p className='mt-0.5'>Chave AC</p>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className='flex-1 shrink'>
                            <div className="flex items-center justify-between">
                                <div className="text-sm font-medium text-gray-800 truncate">
                                    <p className='truncate mr-1'>{email}</p>
                                </div>
                            </div>
                            <div className="mt-1 flex justify-between">
                                <div className="flex items-center text-sm text-gray-500 truncate">
                                    <p className='truncate mr-1'>{celular && celularMask(celular)}</p>
                                </div>
                            </div>
                        </div>

                        {loadingImpressao && <div className='flex shrink flex-1 flex-row space-x-2 sm:grid-cols-2 lg:flex-1 truncate'>
                            <img
                                className="h-12 w-12 mr-4 rounded-lg object-contain"
                                src={arquivoRevendaId ? `${API_URL}/arquivo/download/${arquivoRevendaId}` : require('../../assets/images/user.png')}
                                alt=""
                            />
                            <div className='truncate'>
                                <div className="text-sm font-medium text-gray-800 flex truncate">
                                    <p className='truncate mr-1'>Plano:  {plano}</p>
                                </div>
                                <div className="flex items-center text-sm text-gray-500 flex truncate">
                                    <p className='truncate mr-1'>Cidade:  {municipio}</p>
                                </div>
                            </div>
                        </div>}

                        {ativo ?
                            <div className={classNames(loadingImpressao ? "block" : "flex shrink w-fit", "items-center justify-between")}>
                                <div className="text-sm text-gray-500 flex truncate">
                                    <div data-for={`validade${id}`} data-tip className="flex text-sm text-white font-semibold">
                                        {valido ?
                                            <p className='rounded-full mr-1 text-green-600'>
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </p>
                                            :
                                            <p className='rounded-full mr-1 text-red-600'>
                                                <XIcon className="h-5 w-5" aria-hidden="true" />
                                            </p>
                                        }
                                    </div>
                                    <p className='truncate mr-1'>Validade: </p> {new Date(dataValidade).toLocaleDateString()}


                                </div>
                                {loadingImpressao && ultimaConsultaLicenca && <div className="text-sm text-gray-500 truncate">
                                    <p className='truncate mr-1'>Consulta licença: </p>
                                    <p className='truncate mr-1'>{new Date(ultimaConsultaLicenca).toLocaleDateString()}</p>
                                </div>}
                            </div>
                            :
                            <div className="flex items-center">
                                <p className='rounded-full bg-red-200 h-fit w-fit text-sm font-semibold px-2 text-red-600'>
                                    Contrato cancelado
                                </p>
                            </div>
                        }

                        {!loadingImpressao && <div className="justify-between flex">
                            <div className='flex flex-row space-x-2 items-center sm:grid-cols-2 lg:flex-1 truncate'>
                                <img
                                    className="h-12 w-12 mr-4 rounded-lg object-contain"
                                    src={arquivoRevendaId ? `${API_URL}/arquivo/download/${arquivoRevendaId}` : require('../../assets/images/user.png')}
                                    alt=""
                                />
                                <div className='truncate'>
                                    <div className="text-sm font-medium text-gray-800 flex truncate">
                                        <p className='truncate mr-1'>Plano:  {plano}</p>
                                    </div>
                                    <div className="items-center text-sm text-gray-500 flex truncate">
                                        <p className='truncate mr-1'>Cidade:  {municipio}</p>
                                    </div>
                                </div>
                            </div>
                            {(editar || excluir) && <Menu as="div" className="relative self-center inline-block text-left">
                                <div>
                                    <Menu.Button className="-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600">
                                        <span className="sr-only">Opções</span>
                                        <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                    </Menu.Button>
                                </div>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="z-30 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            {editar && <Menu.Item>
                                                {({ active }) => (
                                                    <div
                                                        onClick={e => handleVisualizarCliente(id)}
                                                        className={classNames(
                                                            active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'flex px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        <PencilIcon className="mr-3 h-5 w-5 m-0 text-gray-400" aria-hidden="true" />
                                                        <span>Editar Cliente</span>
                                                    </div>

                                                )}
                                            </Menu.Item>}
                                            {excluir && <Menu.Item>
                                                {({ active }) => (
                                                    <div
                                                        className={classNames(
                                                            active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'flex px-4 py-2 text-sm'
                                                        )}
                                                        onClick={() => handleDeleteCliente(props.dadosLinha)}
                                                    >
                                                        <TrashIcon className="mr-3 h-5 w-5 m-0 text-gray-400" aria-hidden="true" />
                                                        <span>Deletar</span>
                                                    </div>
                                                )}
                                            </Menu.Item>}
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>}
                        </div>}
                    </div>
                </div>
            </li>
        )
    }

    useEffect(() => {
        if (!acessoVerificado) {
            verificaAcesso(location.pathname.split("/")[1],
                { acao: setListar, nome: desc_funcionalidade + "_LISTAR" },
                { acao: setCadastrar, nome: desc_funcionalidade + "_CADASTRAR" },
                { acao: setEditar, nome: desc_funcionalidade + "_EDITAR" },
                { acao: setExcluir, nome: desc_funcionalidade + "_EXCLUIR" }
            )
            setAcessoVerificado(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acessoVerificado])

    useEffect(() => {
        if (acessoVerificado) {
            if (listar) {
                carregaClientes(registrosPorPagina, paginaAtual)
                carregaOpcoesUf()
                carregaOpcoesRevenda()
                carregaOpcoesContador()
            } else {
                toast.error(`Sem acesso a Listagem de ${desc_funcionalidade}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setTimeout(() => {
                    navigate('/')
                }, 100)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listar])

    const carregaClientes = async (pageSize = registrosPorPagina, currentPage = 0, impressao = false) => {
        let filtros;

        if (filtroTotalizador == null) {
            filtros = {
                pageSize,
                currentPage,
                filtroTotalizador,
                ufId: filtroUf.value,
                municipioId: filtroMunicipio.value,
                revendaId: idRevenda,
                contadorId: filtroContador.value,
                pesquisa: filtroPesquisa,
                filtroPeriodo: filtroPeriodo.value,
                fimPeriodo: fimPeriodo,
                inicioPeriodo: inicioPeriodo,
                impressao
            }
        } else {
            filtros = {
                pageSize,
                currentPage,
                filtroTotalizador,
                impressao
            }
        }

        const response = await getListCliente(filtros)

        if (response.sucesso) {
            setPaginaAtual(response.data.lista.currentPage)
            setTotalRegistros(response.data.lista.totalRegisters)
            setTotalPaginas(response.data.lista.totalPages)

            setQuantidadeTotal(response.data.quantidadeTotal)
            setQuantidadeAtivo(response.data.quantidadeAtivo)
            setQuantidadeInativo(response.data.quantidadeInativo)
            setQuantidadeLicencaValida(response.data.quantidadeLicencaValida)
            setQuantidadeLicencaInvalida(response.data.quantidadeLicencaInvalida)

            setListaCliente(response.data.lista.dados)
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setLoading(false)
    }

    const filtroDebounce = useDebounce(carregaClientes, 500)

    const renderLinhasTabela = () => {
        return listaCliente.map((dadosLinha, key) => {
            return <LinhaTabela key={key} dadosLinha={dadosLinha} />
        })
    }

    useEffect(() => {

        if (pesquisaUf) ufDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pesquisaUf])

    useEffect(() => {

        if (pesquisaMunicipio) municipioDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pesquisaMunicipio])

    useEffect(() => {

        if (pesquisaRevenda) revendaDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pesquisaRevenda])

    useEffect(() => {

        if (pesquisaContador) contadorDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pesquisaContador])

    function handleSelectUf(ufSelecionadda) {
        carregaOpcoesMunicipio('', ufSelecionadda.value || null)
        setFiltroUf(ufSelecionadda)
    }

    function handleSelectMunicipio(municipioSelecionado) {
        setFiltroMunicipio(municipioSelecionado)
    }

    function handleSelectRevenda(revendaSelecionada) {
        setFiltroRevenda(revendaSelecionada)
    }

    function handleSelectContador(contdorSelecionada) {
        setFiltroContador(contdorSelecionada)
    }

    if (loading) {
        return <LoadingPage />
    }
    else {
        return (
            <>
                {!listaCliente ?
                    <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">
                        <div className="bg-white overflow-hidden sm:rounded-lg sm:shadow my-8">
                            <EmptyPage texto="Nenhuma Cliente Cadastrado" Icone={UserAddIcon} botao={true} acao={handleNovoCliente} />
                        </div>
                    </div>
                    :
                    <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">
                        <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 mt-8'>
                            <div className='lg:col-span-2'>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700 flex items-center">
                                    Pesquisa
                                    <i className='text-xs text-gray-400 ml-4'>
                                        ( Id,
                                        Nome Completo,
                                        Razão Social,
                                        Nome Fantasia,
                                        Documento )
                                    </i>
                                </label>
                                <input
                                    defaultValue={filtroPesquisa}
                                    onChange={e => {

                                        setFiltroPesquisa(e.target.value)
                                    }}
                                    type="text"
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Pesquisa " />
                            </div>
                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                                    Uf
                                </label>
                                <Select
                                    isDisabled={loading}
                                    name="uf"
                                    value={filtroUf}
                                    onChange={handleSelectUf}
                                    onInputChange={value => setPesquisaUf(value)}
                                    options={opcoesUf}
                                    placeholder="Selecione"
                                    noOptionsMessage={() => "Nenhuma UF disponível"}
                                    className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                                    Municipio
                                </label>
                                <Select
                                    isDisabled={loading}
                                    name="municipio"
                                    value={filtroMunicipio}
                                    onChange={handleSelectMunicipio}
                                    onInputChange={value => setPesquisaMunicipio(value)}
                                    options={opcoesMunicipio}
                                    placeholder="Selecione"
                                    noOptionsMessage={() => "Nenhum Município disponível"}
                                    className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                                    Revenda
                                </label>
                                <Select
                                    isDisabled={loading}
                                    name="revenda"
                                    value={filtroRevenda}
                                    onChange={handleSelectRevenda}
                                    onInputChange={value => setPesquisaRevenda(value)}
                                    options={opcoesRevenda}
                                    placeholder="Selecione"
                                    noOptionsMessage={() => "Nenhuma Revenda disponível"}
                                    className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                                    Contador
                                </label>
                                <Select
                                    isDisabled={loading}
                                    name="contador"
                                    value={filtroContador}
                                    onChange={handleSelectContador}
                                    onInputChange={value => setPesquisaContador(value)}
                                    options={opcoesContador}
                                    placeholder="Selecione"
                                    noOptionsMessage={() => "Nenhum Contador disponível"}
                                    className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                                    Perídodo
                                </label>
                                <Select
                                    isDisabled={loading}
                                    value={filtroPeriodo}
                                    onChange={filtro => {

                                        setFiltroPeriodo(filtro)
                                    }}
                                    options={opcoesPeriodo}
                                    placeholder="Selecione"
                                    noOptionsMessage={() => "Nenhuma opção disponível"}
                                    className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                                    Início Perídodo
                                </label>
                                <input
                                    disabled={filtroPeriodo.value == null}
                                    defaultValue={filtroPeriodo.value == null ? "" : inicioPeriodo}
                                    onChange={e => {

                                        setInicioPeriodo(e.target.value)
                                    }}
                                    type={filtroPeriodo.value == null ? "text" : "date"}
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                            </div>
                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                                    Fim Perídodo
                                </label>
                                <input
                                    disabled={filtroPeriodo.value == null}
                                    defaultValue={filtroPeriodo.value == null ? "" : fimPeriodo}
                                    onChange={e => {

                                        setFimPeriodo(e.target.value)
                                    }}
                                    type={filtroPeriodo.value == null ? "text" : "date"}
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                            </div>
                        </div>

                        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-4 mt-8'>
                            <div onClick={() => setFiltroTotalizador(null)}
                                className={classNames(
                                    filtroTotalizador == null ? 'border-2 border-gray-500' : 'border-2 border-white',
                                    "relative bg-white p-4 shadow rounded-lg overflow-hidden transition duration-200"
                                )}>
                                <dt>
                                    <div className="absolute bg-gray-500 rounded-md p-3">
                                        <ViewListIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </div>
                                    <div className={classNames(filtroTotalizador == null ? "text-gray-500" : "text-gray-500", "flex items-center justify-between ml-16 text-sm font-medium truncate")}>
                                        <p className='truncate'>Todos</p>
                                        {filtroTotalizador == null ?
                                            <CheckCircleIcon className="shrink-0 text-gray-500 h-5 w-5 m-0" aria-hidden="true" />
                                            : <MinusCircleIcon className="shrink-0 text-gray-400 h-5 w-5 m-0" aria-hidden="true" />
                                        }
                                    </div>
                                </dt>
                                <dd className="ml-16">
                                    <p className="text-2xl font-semibold text-gray-900">{quantidadeTotal}</p>
                                </dd>
                            </div>
                            <div onClick={() => setFiltroTotalizador('ativo')}
                                className={classNames(
                                    filtroTotalizador === "ativo" ? 'border-2 border-blue-500' : 'border-2 border-white',
                                    "relative bg-white p-4 shadow rounded-lg overflow-hidden transition duration-200"
                                )}>
                                <dt>
                                    <div className="absolute bg-blue-500 rounded-md p-3">
                                        <UserIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </div>
                                    <div className={classNames(filtroTotalizador === "ativo" ? "text-blue-500" : "text-gray-500", "flex items-center justify-between ml-16 text-sm font-medium truncate")}>
                                        <p className='truncate'>Ativos</p>
                                        {filtroTotalizador === "ativo" ?
                                            <CheckCircleIcon className="shrink-0 text-blue-500 h-5 w-5 m-0" aria-hidden="true" />
                                            : <MinusCircleIcon className="shrink-0 text-gray-400 h-5 w-5 m-0" aria-hidden="true" />
                                        }
                                    </div>
                                </dt>
                                <dd className="ml-16">
                                    <p className="text-2xl font-semibold text-gray-900">{quantidadeAtivo}</p>
                                </dd>
                            </div>

                            <div onClick={() => setFiltroTotalizador('inativo')}
                                className={classNames(
                                    filtroTotalizador === "inativo" ? 'border-2 border-orange-500' : 'border-2 border-white',
                                    "relative bg-white p-4 shadow rounded-lg overflow-hidden transition duration-200"
                                )}>
                                <dt>
                                    <div className="absolute bg-orange-500 rounded-md p-3">
                                        <ExclamationIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </div>
                                    <div className={classNames(filtroTotalizador === "inativo" ? "text-orange-500" : "text-gray-500", "flex items-center justify-between ml-16 text-sm font-medium truncate")}>
                                        <p className='truncate'>Inativos</p>
                                        {filtroTotalizador === "inativo" ?
                                            <CheckCircleIcon className="shrink-0 text-orange-500 h-5 w-5 m-0" aria-hidden="true" />
                                            : <MinusCircleIcon className="shrink-0 text-gray-400 h-5 w-5 m-0" aria-hidden="true" />
                                        }
                                    </div>
                                </dt>
                                <dd className="ml-16">
                                    <p className="text-2xl font-semibold text-gray-900">{quantidadeInativo}</p>
                                </dd>
                            </div>

                            <div onClick={() => setFiltroTotalizador('licencaValida')}
                                className={classNames(
                                    filtroTotalizador === "licencaValida" ? 'border-2 border-green-500' : 'border-2 border-white',
                                    "relative bg-white p-4 shadow rounded-lg overflow-hidden transition duration-200"
                                )}>
                                <dt>
                                    <div className="absolute bg-green-500 rounded-md p-3">
                                        <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </div>
                                    <div className={classNames(filtroTotalizador === "licencaValida" ? "text-green-500" : "text-gray-500", "flex items-center justify-between ml-16 text-sm font-medium truncate")}>
                                        <p className='truncate'>Licenças Válidas</p>
                                        {filtroTotalizador === "licencaValida" ?
                                            <CheckCircleIcon className="shrink-0 text-green-500 h-5 w-5 m-0" />
                                            : <MinusCircleIcon className="shrink-0 text-gray-400 h-5 w-5 m-0" />
                                        }
                                    </div>
                                </dt>
                                <dd className="ml-16">
                                    <p className="text-2xl font-semibold text-gray-900">{quantidadeLicencaValida}</p>
                                </dd>
                            </div>

                            <div onClick={() => setFiltroTotalizador('licencaInvalida')}
                                className={classNames(
                                    filtroTotalizador === "licencaInvalida" ? 'border-2 border-red-500' : 'border-2 border-white',
                                    "relative bg-white p-4 shadow rounded-lg overflow-hidden transition duration-200"
                                )}>
                                <dt>
                                    <div className="absolute bg-red-500 rounded-md p-3">
                                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </div>
                                    <div className={classNames(filtroTotalizador === "licencaInvalida" ? "text-red-500" : "text-gray-500", "flex items-center justify-between ml-16 text-sm font-medium truncate")}>
                                        <p className='truncate'>Licenças Inválidas</p>
                                        {filtroTotalizador === "licencaInvalida" ?
                                            <CheckCircleIcon className="shrink-0 text-red-500 h-5 w-fit" aria-hidden="true" />
                                            : <MinusCircleIcon className="shrink-0 text-gray-400 h-5 w-fit" aria-hidden="true" />
                                        }
                                    </div>
                                </dt>
                                <dd className="ml-16">
                                    <p className="text-2xl font-semibold text-gray-900">{quantidadeLicencaInvalida}</p>
                                </dd>
                            </div>
                        </div>

                        <div className="bg-white overflow-hidden sm:rounded-lg sm:shadow my-8">
                            <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                                <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                                    <div className="ml-4 mt-2">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Cliente
                                        </h3>
                                    </div>
                                    <div className="ml-4 mt-2 flex-shrink-0">
                                        <ReactToPrint
                                            content={reactToPrintContent}
                                            documentTitle="Clientes"
                                            onAfterPrint={handleAfterPrint}
                                            onBeforeGetContent={handleOnBeforeGetContent}
                                            onBeforePrint={handleBeforePrint}
                                            removeAfterPrint
                                            trigger={reactToPrintTrigger}
                                        />

                                        {cadastrar && <button
                                            onClick={handleNovoCliente}
                                            type="button"
                                            className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                                            Adicionar
                                        </button>}

                                    </div>
                                </div>
                            </div>


                            <div className='relative'>
                                {loadingImpressao &&
                                    <div className='absolute w-full h-full left-0 top-0 bg-white z-50'>
                                        <LoadingPage />
                                    </div>
                                }
                                <div ref={imprimirRef} className="print:mx-[10mm] print:w-[277mm] print:h-[120mm]">
                                    {loadingImpressao && <h3 className="text-lg leading-6 font-medium text-gray-900 ml-4 flex-1 mt-10 pb-5 border-b boder-gray-200">
                                        Clientes
                                    </h3>}
                                    <ul className="divide-y divide-gray-200 ">
                                        {renderLinhasTabela()}
                                    </ul>
                                </div>
                            </div>

                            <LayoutPaginacaoTabela
                                pagina={paginaAtual}
                                totalRegistros={totalRegistros}
                                registrosPorPagina={registrosPorPagina}
                                totalPaginas={totalPaginas}
                                onClickPaginaAnterior={() => {
                                    setPaginaAtual(paginaAtual - 1)
                                    carregaClientes(registrosPorPagina, paginaAtual - 1)
                                }}
                                onClickPaginaPosterior={() => {
                                    setPaginaAtual(paginaAtual + 1)
                                    carregaClientes(registrosPorPagina, paginaAtual + 1)
                                }}
                                onClickPagina={pagina => {
                                    setPaginaAtual(pagina)
                                    carregaClientes(registrosPorPagina, pagina)
                                }} />
                        </div>
                    </div>
                }

                <LayoutModal
                    open={confirmacaoDeletar}
                    setOpen={setConfirmacaoDeletar}
                    titulo={`Deletar ${clienteSelecionado?.nomeCompleto}`}
                    children={`Deseja realmente deletar o Cliente ${clienteSelecionado?.id}?`}
                    textoBotao="Deletar"
                    acaoBotao={confirmDeleteCliente}
                />

                <ModalConfirmacao
                    alerta={false}
                    open={copiarChaveAC}
                    setOpen={setCopiarChaveAC}
                    titulo={`Chave AC de ${clienteSelecionado?.nomeCompleto}`}
                    textoBotao="Copiar chave"
                    acaoBotao={() => {
                        try {
                            navigator.clipboard.writeText(clienteSelecionado?.chaveAC)
                            toast.success(`Chave AC copiada!`, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        } catch (error) {
                            toast.error('Erro ao copiar Chave AC', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    }}
                >

                    <div className='h-80'>
                        <Scrollbars
                            autoHideDuration={200}
                            thumbMinSize={30}
                            universal={true}
                        >
                            <div className='bg-gray-100 rounded-lg p-4 inset-0 shadow-inner break-words overflow-hidden'>
                                {clienteSelecionado?.chaveAC}
                            </div>
                        </Scrollbars>
                    </div>
                </ModalConfirmacao>
            </>
        )
    }

}