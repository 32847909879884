import React, { useState, useEffect, Fragment } from 'react'
import LayoutModal from "../../components/LayoutModal"
import { Menu, Transition } from '@headlessui/react'
import { DotsVerticalIcon, PencilIcon, TrashIcon, DocumentTextIcon } from '@heroicons/react/solid'
import { getListNcm, deletarNcm, getListSelectVersaoNcm } from '../../services/ncm.js'
import { useNavigate, useLocation } from "react-router-dom";
import LayoutPaginacaoTabela from "../../components/LayoutPaginacaoTabela";
import EmptyPage from "../../components/EmptyPage";
import LoadingPage from "../../components/LoadingPage";
import verificaAcesso from '../../utils/verificaAcesso';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { getListUf } from '../../services/endereco'
import useDebounce from '../../hooks/useDebounce';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function NcmListagem() {
    let navigate = useNavigate();
    const location = useLocation();

    //acessos
    const desc_funcionalidade = "NCM";
    const [acessoVerificado, setAcessoVerificado] = useState(false);
    const [listar, setListar] = useState('')
    const [cadastrar, setCadastrar] = useState('')
    const [editar, setEditar] = useState('')
    const [excluir, setExcluir] = useState('')

    const [listaNcm, setListaNcm] = useState([])
    const [confirmacaoDeletar, setConfirmacaoDeletar] = useState(false)
    const [ncmSelecionada, setNcmSelecionada] = useState(null)

    const [loading, setLoading] = useState(true)

    //Filtros
    const [filtroUf, setFiltroUf] = useState({ value: null, label: "Todas" })
    const [opcoesUf, setOpcoesUf] = useState([]);
    const ufDebounce = useDebounce(carregaOpcoesUf, 500)
    const [pesquisaUf, setPesquisaUf] = useState("")

    const [filtroVersao, setFiltroVersao] = useState({ value: null, label: "Todas" })
    const [opcoesVersao, setOpcoesVersao] = useState([]);
    const [filtroPeriodo, setFiltroPeriodo] = useState({ value: null, label: "Todos" })
    const opcoesPeriodo = [
        { value: null, label: "Todos" },
        { value: 'inicio', label: "Início Vigência" },
        { value: 'fim', label: "Fim Vigência" }
    ];

    const hoje = new Date().toLocaleDateString().split("T")[0].split("/").reverse().join("-");
    const mesSeguinte = new Date(new Date().setMonth(new Date().getMonth() + 1)).toLocaleDateString().split("T")[0].split("/").reverse().join("-");

    const [inicioPeriodo, setInicioPeriodo] = useState(hoje);
    const [fimPeriodo, setFimPeriodo] = useState(mesSeguinte);
    const [filtroPesquisa, setFiltroPesquisa] = useState(null);

    useEffect(() => {
        filtroDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtroUf, filtroVersao, filtroPeriodo, inicioPeriodo, fimPeriodo, filtroPesquisa])

    //Paginacao Tabela
    const [paginaAtual, setPaginaAtual] = useState(0)
    const [totalRegistros, setTotalRegistros] = useState(0)
    const [totalPaginas, setTotalPaginas] = useState(0)
    const registrosPorPagina = 10

    function handleNovaNcm() {
        if (!cadastrar) {
            toast.error(`Sem acesso a Cadastro de ${desc_funcionalidade}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        navigate(`/ncm/form`)
    }

    function handleVisualizarNcm(id) {
        if (!editar) {
            toast.error(`Sem acesso a Visualização de ${desc_funcionalidade}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        navigate(`/ncm/form`, { state: { "idNcm": id } })
    }

    function handleDeleteNcm(dados) {
        if (!excluir) {
            toast.error(`Sem acesso a Exclusão de ${desc_funcionalidade}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        setNcmSelecionada(dados)
        setConfirmacaoDeletar(true)
    }

    async function confirmDeleteNcm() {
        await deletarNcm(ncmSelecionada.id)
        carregaNcms(registrosPorPagina, paginaAtual)

        toast.success('NCM deletada com sucesso.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    async function carregaOpcoesUf(pesquisa = pesquisaUf) {
        const response = await getListUf(pesquisa)
        if (response.sucesso) {
            const opcoes = response.data.map(uf => {
                return {
                    value: uf.id,
                    label: uf.descricao
                }
            })
            setOpcoesUf([{ value: null, label: 'Todos' }, ...opcoes])
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const carregaVersaoNcm = async () => {
        setLoading(true)

        const response = await getListSelectVersaoNcm()

        if (response.sucesso) {
            const lista = response.data.map(versao => { return { value: versao, label: versao } })
            const opcaoNula = { value: null, label: 'Selecione' }
            setOpcoesVersao([opcaoNula, ...lista])
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setLoading(false)
    }

    const LinhaTabela = (props) => {
        const {
            id,

            codigo, //"00000000"
            descricao, //"PRODUTO NAO ESPECIFICADO NA LISTA DE NCM"
            vigenciaInicio, //"20/04/2022"
            vigenciaFim, //"31/05/2022"
            versao, //"22.1.F"
            ufDescricao
        } = props.dadosLinha

        let ncmVigente = false;
        const dataAtual = new Date();
        if (new Date(vigenciaInicio) <= dataAtual && new Date(vigenciaFim) >= dataAtual) {
            ncmVigente = true;
        }

        return (
            <li className="cursor-default">
                <div className="bg-white px-4 py-2 sm:px-6">
                    <div className="grid lg:grid-cols-5 grid-cols-1 gap-4">
                        <div className='lg:col-span-2 col-span-auto'>
                            <div className="text-sm font-medium text-gray-800 flex truncate">
                                <p className='truncate mr-1'>Código</p>: {codigo}
                            </div>
                            <div className="flex justify-between text-gray-500 col-span-full">
                                <div className="text-sm truncate capitalize pr-4">
                                    {descricao.toLowerCase()}
                                </div>
                            </div>
                        </div>

                        <div className="text-sm font-medium text-gray-800 flex truncate">
                            <p className='truncate mr-1'>UF</p>: {ufDescricao}
                        </div>

                        {/* <ReactTooltip id={`${id}`} place="top" type="dark" effect="solid" >
                            {new Date(vigenciaInicio).toLocaleDateString()} - {new Date(vigenciaFim).toLocaleDateString()}
                        </ReactTooltip> */}

                        <div data-for={`${id}`} data-tip className="flex items-center lg:justify-center text-sm text-white font-semibold">
                            {ncmVigente ?
                                <p className='rounded-full bg-green-600 px-2'>
                                    Vigente
                                </p>
                                :
                                <p className='rounded-full bg-red-600 px-2'>
                                    Fora da Vigência
                                </p>
                            }
                        </div>
                        <div className="self-center justify-between flex">
                            <div className="flex justify-between">
                                <div className="text-sm font-medium text-gray-800 flex truncate">
                                    <p className='truncate mr-1'>Versão</p>: {versao}
                                </div>
                            </div>
                            {(editar || excluir) && <Menu as="div" className="relative inline-block text-left">
                                <div>
                                    <Menu.Button className="-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600">
                                        <span className="sr-only">Open options</span>
                                        <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                    </Menu.Button>
                                </div>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="z-30 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            {editar && <Menu.Item>
                                                {({ active }) => (
                                                    <div
                                                        onClick={e => handleVisualizarNcm(id)}
                                                        className={classNames(
                                                            active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'flex px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        <PencilIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                        <span>Editar NCM</span>
                                                    </div>

                                                )}
                                            </Menu.Item>}
                                            {excluir && <Menu.Item>
                                                {({ active }) => (
                                                    <div
                                                        className={classNames(
                                                            active ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'flex px-4 py-2 text-sm'
                                                        )}
                                                        onClick={() => handleDeleteNcm(props.dadosLinha)}
                                                    >
                                                        <TrashIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                        <span>Deletar</span>
                                                    </div>
                                                )}
                                            </Menu.Item>}
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>}
                        </div>
                    </div>
                </div>
            </li>
        )
    }

    useEffect(() => {
        if (!acessoVerificado) {
            verificaAcesso(location.pathname.split("/")[1],
                { acao: setListar, nome: desc_funcionalidade + "_LISTAR" },
                { acao: setCadastrar, nome: desc_funcionalidade + "_CADASTRAR" },
                { acao: setEditar, nome: desc_funcionalidade + "_EDITAR" },
                { acao: setExcluir, nome: desc_funcionalidade + "_EXCLUIR" }
            )
            setAcessoVerificado(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acessoVerificado])

    useEffect(() => {
        if (acessoVerificado) {
            if (listar) {
                carregaNcms(registrosPorPagina, paginaAtual)
                carregaOpcoesUf()
                carregaVersaoNcm()
            } else {
                toast.error(`Sem acesso a Listagem de ${desc_funcionalidade}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setTimeout(() => {
                    navigate('/')
                }, 100)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listar])

    const carregaNcms = async (pageSize = registrosPorPagina, currentPage = 0) => {
        const filtros = {
            pageSize,
            currentPage,
            ufId: filtroUf.value,
            pesquisa: filtroPesquisa,
            versao: filtroVersao.value,
            filtroPeriodo: filtroPeriodo.value,
            fimPeriodo: fimPeriodo,
            inicioPeriodo: inicioPeriodo,
        }

        const response = await getListNcm(filtros)

        if (response.sucesso) {
            setPaginaAtual(response.data.currentPage)
            setTotalRegistros(response.data.totalRegisters)
            setTotalPaginas(response.data.totalPages)

            setListaNcm(response.data.dados)
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setLoading(false)
    }

    const filtroDebounce = useDebounce(carregaNcms, 500)

    const renderLinhasTabela = () => {
        return listaNcm.map((dadosLinha, key) => {
            return <LinhaTabela key={key} dadosLinha={dadosLinha} />
        })
    }

    function handleSelectUf(ufSelecionadda) {
        setFiltroUf(ufSelecionadda)
    }

    useEffect(() => {
        if (pesquisaUf) ufDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pesquisaUf])

    if (loading) {
        return <LoadingPage />
    }
    else {
        return (
            <>
                {!listaNcm ?
                    <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">
                        <div className="bg-white overflow-hidden sm:rounded-lg sm:shadow my-8">
                            <EmptyPage texto="Nenhuma NCM Cadastrada" Icone={DocumentTextIcon} botao={true} acao={handleNovaNcm} />
                        </div>
                    </div>
                    :
                    <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">
                        <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 mt-8'>
                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700 flex items-center">
                                    Pesquisa
                                    <i className='text-xs text-gray-400 ml-4'>
                                        ( Id,
                                        Código,
                                        Descrição )
                                    </i>
                                </label>
                                <input
                                    defaultValue={filtroPesquisa}
                                    onChange={e => setFiltroPesquisa(e.target.value)}
                                    type="text"
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Pesquisa " />
                            </div>
                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                                    Uf
                                </label>
                                <Select
                                    isDisabled={loading}
                                    name="uf"
                                    value={filtroUf}
                                    onChange={handleSelectUf}
                                    onInputChange={value => setPesquisaUf(value)}
                                    options={opcoesUf}
                                    placeholder="Selecione"
                                    noOptionsMessage={() => "Nenhuma UF disponível"}
                                    className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                                    Versão
                                </label>
                                <Select
                                    isDisabled={loading}
                                    value={filtroVersao}
                                    onChange={filtro => setFiltroVersao(filtro)}
                                    options={opcoesVersao}
                                    placeholder="Selecione"
                                    noOptionsMessage={() => "Nenhuma opção disponível"}
                                    className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                                    Perídodo
                                </label>
                                <Select
                                    isDisabled={loading}
                                    value={filtroPeriodo}
                                    onChange={filtro => setFiltroPeriodo(filtro)}
                                    options={opcoesPeriodo}
                                    placeholder="Selecione"
                                    noOptionsMessage={() => "Nenhuma opção disponível"}
                                    className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                                    Início Perídodo
                                </label>
                                <input
                                    disabled={filtroPeriodo.value == null}
                                    defaultValue={filtroPeriodo.value == null ? "" : inicioPeriodo}
                                    onChange={e => setInicioPeriodo(e.target.value)}
                                    type={filtroPeriodo.value == null ? "text" : "date"}
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                            </div>
                            <div>
                                <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                                    Fim Perídodo
                                </label>
                                <input
                                    disabled={filtroPeriodo.value == null}
                                    defaultValue={filtroPeriodo.value == null ? "" : fimPeriodo}
                                    onChange={e => setFimPeriodo(e.target.value)}
                                    type={filtroPeriodo.value == null ? "text" : "date"}
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                            </div>
                        </div>
                        <div className="bg-white overflow-hidden sm:rounded-lg sm:shadow my-8">
                            <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                                <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                                    <div className="ml-4 mt-2">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            NCM
                                        </h3>
                                    </div>
                                    <div className="ml-4 mt-2 flex-shrink-0">
                                        {cadastrar && <button
                                            onClick={handleNovaNcm}
                                            type="button"
                                            className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                                            Adicionar
                                        </button>}
                                    </div>
                                </div>
                            </div>

                            <ul className="divide-y divide-gray-200">
                                {renderLinhasTabela()}
                            </ul>

                            <LayoutPaginacaoTabela
                                pagina={paginaAtual}
                                totalRegistros={totalRegistros}
                                registrosPorPagina={registrosPorPagina}
                                totalPaginas={totalPaginas}
                                onClickPaginaAnterior={() => {
                                    setPaginaAtual(paginaAtual - 1)
                                    carregaNcms(registrosPorPagina, paginaAtual - 1)
                                }}
                                onClickPaginaPosterior={() => {
                                    setPaginaAtual(paginaAtual + 1)
                                    carregaNcms(registrosPorPagina, paginaAtual + 1)
                                }}
                                onClickPagina={pagina => {
                                    setPaginaAtual(pagina)
                                    carregaNcms(registrosPorPagina, pagina)
                                }} />
                        </div>
                    </div>
                }

                <LayoutModal
                    open={confirmacaoDeletar}
                    setOpen={setConfirmacaoDeletar}
                    titulo={`Deletar ${ncmSelecionada?.descricao}`}
                    descricao={`Deseja realmente deletar a NCM ${ncmSelecionada?.codigo}?`}
                    textoBotao="Deletar"
                    acaoBotao={confirmDeleteNcm}
                />
            </>
        )
    }

}