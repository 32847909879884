import instance from '../configAxios';

export const getPerfilFuncionalidadesByIdPerfil = async (idPerfil) => {
    const axios = await instance();

    try {
        const response = await axios.get(`/PerfilAcessoItem/perfil/${idPerfil}`)
        return { sucesso: true, data: response.data }
    } catch (error) {
        if (!error.response) {
            return {
                success: false,
                error: "Não foi possível conectar ao servidor."
            }
        } else {
            return {
                success: false,
                error: error.response.data
            }
        }
    }
}

export const postPerfilFuncionalidades = async (perfilFuncionalidades) => {
    const axios = await instance();

    try {
        const response = await axios.post(`/PerfilAcessoItem`, perfilFuncionalidades)
        return { sucesso: true, data: response.data }
    } catch (error) {
        if (!error.response) {
            return {
                success: false,
                error: "Não foi possível conectar ao servidor."
            }
        } else {
            return {
                success: false,
                error: error.response.data
            }
        }
    }
}

export const liberarAcessosPerfil = async idPerfil => {
    const axios = await instance();

    try {
        const response = await axios.put(`/perfilAcessoItem/LiberarAcessos/perfil/${idPerfil}`, null)
        return { sucesso: true, data: response.data }
    } catch (error) {
        if (!error.response) {
            return {
                success: false,
                error: "Não foi possível conectar ao servidor."
            }
        } else {
            return {
                success: false,
                error: error.response.data
            }
        }
    }
}

export const bloquearAcessosPerfil = async idPerfil => {
    const axios = await instance();

    try {
        const response = await axios.delete(`/perfilAcessoItem/BloquearAcessos/perfil/${idPerfil}`)
        return { sucesso: true, data: response.data }
    } catch (error) {
        if (!error.response) {
            return {
                success: false,
                error: "Não foi possível conectar ao servidor."
            }
        } else {
            return {
                success: false,
                error: error.response.data
            }
        }
    }
}