import './App.css';
import Router from "./router";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

    return (
        <BrowserRouter>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                rtl={false}
                draggable
                pauseOnHover
                closeButton={true}
            />
            <div className='bg-gray-100 h-screen'>
                <Router />
            </div>
        </BrowserRouter>
    );
}

export default App;
