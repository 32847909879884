// HeroIcon.tsx
import * as SolidIcons from '@heroicons/react/solid';
import * as OutlineIcons from '@heroicons/react/outline';

export const HeroIcon = (props) => {
  const { icon, className, outline } = props;
  const { ...icons } = outline ? OutlineIcons : SolidIcons;
  const Icon = icons[icon];

  return (
    <Icon className={className} />
  );
};