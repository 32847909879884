import React, { useState, useEffect } from 'react'
import { getListResumos, } from '../../../services/mde'
import { useNavigate } from "react-router-dom";
import LayoutPaginacaoTabela from "../../../components/LayoutPaginacaoTabela";
import EmptyPage from "../../../components/EmptyPage";
import LoadingPage from "../../../components/LoadingPage";
import verificaAcesso from '../../../utils/verificaAcesso';
import { toast } from 'react-toastify';
import { SearchIcon } from '@heroicons/react/outline'
import useDebounce from '../../../hooks/useDebounce';
import { formatarDataHora } from '../../../utils/formartar';
import { getSelectCliente } from '../../../services/cliente'
import Select from 'react-select';
export default function MDEResumo(props) {
    let navigate = useNavigate();
    //acessos
    const desc_funcionalidade = "MDE_RESUMO";
    const [acessoVerificado, setAcessoVerificado] = useState(false);
    const [listar, setListar] = useState('')

    const [listaMDEResumo, setListaMDEResumo] = useState([])

    const [loading, setLoading] = useState(true)

    //Filtros
    const [filtroPesquisa, setFiltroPesquisa] = useState(null);

    const [filtroCliente, setFiltroCliente] = useState({ value: null, label: "Todas" })
    const [opcoesCliente, setOpcoesCliente] = useState([]);
    const clienteDebounce = useDebounce(carregaOpcoesCliente, 500)
    const [pesquisaCliente, setPesquisaCliente] = useState("")

    const hoje = new Date().toLocaleDateString().split("T")[0].split("/").reverse().join("-");
    const mesPassado = new Date(new Date().setMonth(new Date().getMonth() - 1)).toLocaleDateString().split("T")[0].split("/").reverse().join("-");

    const [inicioPeriodo, setInicioPeriodo] = useState(mesPassado);
    const [fimPeriodo, setFimPeriodo] = useState(hoje);

    //Paginacao Tabela
    const [paginaAtual, setPaginaAtual] = useState(0)
    const [totalRegistros, setTotalRegistros] = useState(0)
    const [totalPaginas, setTotalPaginas] = useState(0)
    const registrosPorPagina = props.quantidadeResultados || 5

    useEffect(() => {
        filtroDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtroPesquisa, filtroCliente, inicioPeriodo, fimPeriodo])

    async function carregaOpcoesCliente(pesquisa = pesquisaCliente) {
        const response = await getSelectCliente(pesquisa)
        if (response.sucesso) {
            const opcoes = response.data.map(cliente => {
                return {
                    value: cliente.id,
                    label: cliente.descricao
                }
            })
            setOpcoesCliente([{ value: null, label: 'Todos' }, ...opcoes])
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }


    const DadosMDELinha = ({ dadosLinha }) => {
        const {
            // mdeEventoExecucaoId,
            // digestValue,
            // numeroProtocolo,
            // xmlResumo,
            chaveNFE,
            cnpj,
            nome,
            inscricaoEstadual,
            dataEmissao,
            tipoNotaFiscal,
            valorNotaFiscal,
            dataRecebimento,
            situacaoNotaFiscal,
            codigoTipoEvento,
            // dataUltimaAlteracao,
            // usuarioUltimaAlteracao,
        } = dadosLinha

        return (
            <>
                <div className='col-span-2'>
                    <div className={`text-sm font-medium text-gray-800 truncate flex`}>
                        <p className='truncate flex-1'>
                            {nome || "Nome não informada"}
                        </p>
                    </div>
                    {/* <div className="text-sm text-gray-500 truncate">
                        <p className='truncate'>Protocolo: {numeroProtocolo}</p>
                    </div> */}
                    <div className=" flex flex-row items-center gap-0 flex-wrap text-sm text-gray-500">
                        <p >CNPJ: {cnpj}</p>
                        <p >I.E.: {inscricaoEstadual}</p>
                    </div>
                </div>

                <div>
                    <div className={`text-sm font-medium text-gray-800 truncate flex`}>
                        <p className='truncate mr-1'>Emissão: {formatarDataHora(dataEmissao)}</p>
                    </div>
                    <div className="text-sm text-gray-500 flex truncate">
                        <p className='truncate mr-1'>Recebimento: {formatarDataHora(dataRecebimento)}</p>
                    </div>
                </div>

                <div className='col-span-2 flex flex-row items-center text-center gap-2 flex-wrap'>
                    {codigoTipoEvento === null ?
                        <span className='rounded-lg bg-orange-600/20 text-orange-600 px-2 py-1 font-semibold text-sm'>Manifesto Pendente</span>
                        :
                        <span className='rounded-lg bg-green-600/20 text-green-600 px-2 py-1 font-semibold text-sm'>Manifestado Realizado</span>
                    }

                    {tipoNotaFiscal === 0 ?
                        <span className='rounded-lg bg-green-600/20 text-green-600 px-2 py-1 font-semibold text-sm'>NF-e Entrada</span>
                        :
                        <span className='rounded-lg bg-yellow-600/20 text-yellow-600 px-2 py-1 font-semibold text-sm'>NF-e Saída</span>
                    }

                    {situacaoNotaFiscal === "1" ?
                        <span className='rounded-lg bg-green-600/20 text-green-600 px-2 py-1 font-semibold text-sm'>Uso autorizado</span>
                        :
                        situacaoNotaFiscal === "2" ?
                            <span className='rounded-lg bg-orange-600/20 text-orange-600 px-2 py-1 font-semibold text-sm'>Uso denegado</span>
                            :
                            situacaoNotaFiscal === "3" ?
                                <span className='rounded-lg bg-red-600/20 text-red-600 px-2 py-1 font-semibold text-sm'>NF-e cancelada</span>
                                :
                                <span className='rounded-lg bg-gray-600/20 text-gray-600 px-2 py-1 font-semibold text-sm'>Não informado</span>
                    }
                </div>

                <div className='col-span-2'>
                    <div className="text-sm font-medium text-gray-800 flex">
                        <p className='flex break-all'> Chave NFe: {chaveNFE} </p>
                    </div>
                    <div className="text-sm text-gray-500 truncate">
                        <p className='truncate'>Valor: {valorNotaFiscal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                    </div>
                </div>

                {/* <div className="justify-between flex">
                    <div>
                        {usuarioUltimaAlteracao === "Login inválido" ?
                            <div className="text-sm font-medium text-red-600 truncate flex">
                                <ExclamationCircleIcon className='w-4 mr-1' />
                                <p className='truncate mr-1'>{usuarioUltimaAlteracao}</p>
                            </div>
                            :
                            usuarioUltimaAlteracao === "Tela Bloqueio" ?
                                <div className="text-sm font-medium text-orange-600 truncate flex">
                                    <BanIcon className='w-4 mr-1' />
                                    <p className='truncate mr-1'>{usuarioUltimaAlteracao}</p>
                                </div>
                                :
                                <div className="text-sm font-medium text-gray-800 truncate flex">
                                    <UserIcon className='w-4 mr-1' />
                                    <p className='truncate mr-1'>Usuário: {usuarioUltimaAlteracao}</p>
                                </div>
                        }
                        <div className="text-sm text-gray-500 truncate">
                            <p className='truncate mr-1'>Data: {formatarDataHora(dataUltimaAlteracao)}</p>
                        </div>
                    </div>
                </div> */}
            </>
        )
    }

    const ClienteLinha = ({ cliente }) => {
        return (
            <div>
                <div className="text-sm font-medium text-gray-800 truncate flex capitalize">
                    <p className='truncate mr-1'>{cliente.razaoSocial.toLowerCase()}</p>
                </div>
                <div className="text-sm text-gray-500 truncate capitalize">
                    <p className='truncate mr-1'>{cliente.nomeFantasia.toLowerCase()}</p>
                </div>
            </div>
        )
    }

    const LinhaTabela = ({ dadosLinha }) => {
        return (
            <li className="cursor-default">
                <div className="bg-white px-4 py-2 sm:px-6">
                    {props.idCliente ?
                        <div className="grid xl:grid-cols-7 grid-cols-1 gap-2">
                            <DadosMDELinha dadosLinha={dadosLinha} />
                        </div> :
                        <div className="grid xl:grid-cols-8 grid-cols-1 gap-4">
                            <ClienteLinha cliente={dadosLinha.cliente} />
                            <DadosMDELinha dadosLinha={dadosLinha} />
                        </div>
                    }
                </div>
            </li>
        )
    }

    useEffect(() => {
        if (!acessoVerificado) {
            verificaAcesso("mde", //location.pathname.split("/")[1]
                { acao: setListar, nome: desc_funcionalidade + "_LISTAR" },
            )
            setAcessoVerificado(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acessoVerificado])

    useEffect(() => {
        if (acessoVerificado) {
            if (listar) {
                carregaResumosMDE(registrosPorPagina, paginaAtual)
                carregaOpcoesCliente()
            }
            else {
                toast.error(`Sem acesso a Listagem de ${desc_funcionalidade}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setTimeout(() => {
                    navigate('/')
                }, 100)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listar])

    const carregaResumosMDE = async (pageSize = registrosPorPagina, currentPage = 0) => {
        setLoading(true);

        const filtros = {
            pageSize,
            currentPage,
            pesquisa: filtroPesquisa,
            MDEId: props.MDEId,
            clienteId: props.idCliente || filtroCliente.value,
            fimPeriodo: fimPeriodo,
            inicioPeriodo: inicioPeriodo,
        }

        const response = await getListResumos(filtros)

        if (response.sucesso) {
            setPaginaAtual(response.data.currentPage)
            setTotalRegistros(response.data.totalRegisters)
            setTotalPaginas(response.data.totalPages)

            setListaMDEResumo(response.data.dados)
        } else {
            toast.error(response.mensagem, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setLoading(false)
    }

    useEffect(() => {
        if (pesquisaCliente) clienteDebounce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pesquisaCliente])

    function handleSelectCliente(clienteSelecionado) {
        setFiltroCliente(clienteSelecionado)
    }

    const filtroDebounce = useDebounce(carregaResumosMDE, 500)

    const renderLinhasTabela = () => {
        return listaMDEResumo.map((dadosLinha, key) => {
            return <LinhaTabela key={key} dadosLinha={dadosLinha} />
        })
    }

    if (loading) {
        return <LoadingPage />
    }
    else {
        return (
            <div className={`flex flex-col`}>
                {props.filtros && <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 mt-8'>
                    <div>
                        <label htmlFor="country" className="text-sm mb-1 font-medium text-gray-700 flex items-center">
                            Pesquisa
                            <i className='text-xs text-gray-400 ml-4'>
                                (
                                Chave da Nota Fiscal,
                                CNPJ,
                                Nome,
                                Inscrição Estadual,
                                Número Protocolo
                                )
                            </i>
                        </label>
                        <input
                            defaultValue={filtroPesquisa}
                            onChange={e => setFiltroPesquisa(e.target.value)}
                            type="text"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Pesquisa " />
                    </div>
                    <div>
                        <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                            Cliente
                        </label>
                        <Select
                            isDisabled={loading}
                            name="cliente"
                            value={filtroCliente}
                            onChange={handleSelectCliente}
                            onInputChange={value => setPesquisaCliente(value)}
                            options={opcoesCliente}
                            placeholder="Selecione"
                            noOptionsMessage={() => "Nenhuma Cliente disponível"}
                            className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div>
                        <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                            Início Perídodo
                        </label>
                        <input
                            defaultValue={inicioPeriodo}
                            onChange={e => {
                                setInicioPeriodo(e.target.value)
                            }}
                            type="date"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                    <div>
                        <label htmlFor="country" className="block text-sm mb-1 font-medium text-gray-700">
                            Fim Perídodo
                        </label>
                        <input
                            defaultValue={fimPeriodo}
                            onChange={e => {
                                setFimPeriodo(e.target.value)
                            }}
                            type="date"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                </div>}
                <div className="bg-white sm:rounded-lg sm:shadow my-8">
                    <div className="bg-white sm:rounded-t-lg px-4 py-5 border-b border-gray-200 sm:px-6">
                        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                            <div className="ml-4 mt-2">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    {props.titulo || "Resumos"}
                                </h3>
                            </div>
                        </div>
                    </div>

                    {!listaMDEResumo ?
                        <div className="flex flex-col mx-4 sm:mx-6 md:mx-8">
                            <EmptyPage texto="Nenhuma MDE Encontrada" Icone={SearchIcon} />
                        </div>
                        :
                        <ul className="divide-y divide-gray-200">
                            {renderLinhasTabela()}
                        </ul>
                    }

                    <LayoutPaginacaoTabela
                        pagina={paginaAtual}
                        totalRegistros={totalRegistros}
                        registrosPorPagina={registrosPorPagina}
                        totalPaginas={totalPaginas}
                        className="rounded-b-lg"
                        onClickPaginaAnterior={() => {
                            setPaginaAtual(paginaAtual - 1)
                            carregaResumosMDE(registrosPorPagina, paginaAtual - 1)
                        }}
                        onClickPaginaPosterior={() => {
                            setPaginaAtual(paginaAtual + 1)
                            carregaResumosMDE(registrosPorPagina, paginaAtual + 1)
                        }}
                        onClickPagina={pagina => {
                            setPaginaAtual(pagina)
                            carregaResumosMDE(registrosPorPagina, pagina)
                        }} />
                </div>
            </div>
        )
    }

}