import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import verificaAcesso from '../../utils/verificaAcesso';
import { toast } from 'react-toastify';
import LoadingPage from "../../components/LoadingPage";
import { getPlanoById, postPlano, putPlano } from "../../services/plano";
import useDebounce from "../../hooks/useDebounce";
import Obrigatiorio from "../../components/Obrigatiorio";
import CurrencyInput from 'react-currency-input-field';

export default function PlanoForm() {
  let navigate = useNavigate();
  const location = useLocation();

  let idPlano = location.state ? location.state.idPlano : null;

  const salvarDebounce = useDebounce(handleSalvarPlano, 500)

  //acessos
  const desc_funcionalidade = "PLANO";
  const [acessoVerificado, setAcessoVerificado] = useState(false);
  const [listar, setListar] = useState('')
  const [editar, setEditar] = useState('')

  const [loading, setLoading] = useState(true);
  const [salvandoCadastro, setSalvandoCadastro] = useState(false);

  const [form, setForm] = useState({})

  useEffect(() => {
    if (!acessoVerificado) {
      verificaAcesso(location.pathname.split("/")[1],
        { acao: setListar, nome: desc_funcionalidade + "_LISTAR" },
        { acao: setEditar, nome: desc_funcionalidade + "_EDITAR" },
      )
      setAcessoVerificado(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acessoVerificado])

  useEffect(() => {
    if (acessoVerificado) {
      if (listar) {
        if (idPlano) {
          carregaPlano()
        } else {
          setLoading(false)
        }
      } else {
        toast.error(`Sem acesso a Visualização de ${desc_funcionalidade}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setTimeout(() => {
          navigate('/')
        }, 100)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listar])

  function handleInputChange(event, selectName) {
    const formAtualizado = form;

    if (event.label) {
      formAtualizado[selectName] = event.value;
      setForm(formAtualizado);
      return;
    }

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    formAtualizado[name] = value;
    setForm(formAtualizado);
  }

  async function carregaPlano() {
    setLoading(true)

    const response = await getPlanoById(idPlano)

    if (response.sucesso) {
      setForm(response.data)
    } else {
      toast.error(response.mensagem, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setLoading(false)
  }

  const verificaForm = () => {
    const origatorios = [
      { valor: "valor", nome: "Valor" },
      { valor: "descricao", nome: "Descrição" },
    ]

    let campoInvalido = false;
    origatorios.forEach(campo => {
      if (!form[campo.valor] || form[campo.valor].label === 'Selecione') {
        campoInvalido = campo.nome;
      }
    });

    return campoInvalido
  }

  async function handleSalvarPlano() {
    var campoInvalido = verificaForm()

    if (campoInvalido) {
      toast.error(`Preencha o campo de ${campoInvalido}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return;
    }

    setSalvandoCadastro(true)

    let response = { sucesso: false, mensagem: "Erro ao salvar Plano" }

    if (idPlano) {
      form.id = idPlano;
      response = await putPlano(idPlano, form)
    } else {
      form.ativo = true;
      response = await postPlano(form)
    }

    if (response.sucesso) {
      toast.success('Plano Salvo com sucesso!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      navigate(`/plano`)
    } else {
      toast.error(response.mensagem, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setSalvandoCadastro(false)
  }

  if (loading) {
    return <LoadingPage />
  }

  return (
    <main className="py-8 grid grid-cols-6 sm:grid-cols-12">

      <div className="col-span-full grid grid-cols-1 gap-6 sm:px-6 lg:grid-flow-col-dense lg:grid-cols-3 flex-1">
        <div className="space-y-6 lg:col-start-1 lg:col-span-3">

          <section aria-labelledby="applicant-information-title">
            <div className="bg-white shadow sm:rounded-lg">
              <div className="px-8 py-5 flex flex-row justify-between">
                <div>
                  <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                    Principal
                  </h2>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">Cadastro de Plano</p>
                </div>
              </div>

              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-6 gap-x-4 gap-y-6 sm:grid-cols-12">
                  <>
                    <div className="col-span-6">
                      <label className="block text-sm font-medium text-gray-700">
                        Descrição<Obrigatiorio />
                      </label>
                      <input
                        name="descricao"
                        disabled={salvandoCadastro}
                        defaultValue={form?.descricao}
                        onChange={handleInputChange}
                        type="text"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>

                    <div className="col-span-6">
                      <label className="block text-sm font-medium text-gray-700">
                        Valor<Obrigatiorio />
                      </label>
                      <CurrencyInput
                        name="valor"
                        disabled={salvandoCadastro}
                        defaultValue={form?.valor}
                        decimalsLimit={2}
                        allowNegativeValue={false}
                        prefix="R$"
                        onValueChange={(value, name) => {
                          const valorDecimal = parseFloat(value.replace(',', '.'));
                          const formAtualizado = form;
                          formAtualizado[name] = valorDecimal
                          setForm(formAtualizado)
                        }}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </>
                </dl>
              </div>

              {idPlano && editar && <div className="m-6 pb-6 flex flex-row flex-wrap justify-between space-y-4 items-center">
                {form.usuarioUltimaAlteracao &&
                  <div className="text-sm text-gray-600 grid sm:grid-cols-2 grid-cols-1 gap-4">
                    <div>
                      <span className="text-xs text-gray-400">Cadastro</span>
                      <p>{form.usuarioCadastro}, {new Date(form.dataCadastro).toLocaleDateString()} - {new Date(form.dataCadastro).toLocaleTimeString()}</p>
                    </div>
                    <div className="text-sm text-gray-600">
                      <span className="text-xs text-gray-400">Última alteração</span>
                      <p>{form.usuarioUltimaAlteracao}, {new Date(form.dataUltimaAlteracao).toLocaleDateString()} - {new Date(form.dataUltimaAlteracao).toLocaleTimeString()}</p>
                    </div>
                  </div>
                }
                <button
                  type="button"
                  onClick={salvarDebounce}
                  disabled={salvandoCadastro}
                  className={`${(salvandoCadastro) ? "cursor-not-allowed" : "cursor-pointer"} inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500`}
                >
                  {salvandoCadastro && <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>}
                  Salvar Alterações
                </button>
              </div>}
              {!idPlano && <div className="m-6 pb-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                <button
                  type="button"
                  onClick={() => navigate(`/plano`)}
                  className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  onClick={salvarDebounce}
                  disabled={salvandoCadastro}
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500"
                >
                  Salvar Plano
                </button>
              </div>}
            </div>
          </section>

        </div>
      </div>
    </main>
  )
}
