import instance from '../configAxios';

export const getListClienteBackup = async (filtros) => {
    const axios = await instance();

    try {
        const response = await axios.post(`/ClienteBackup/listagem`, filtros)
        return { sucesso: true, data: response.data }
    } catch (error) {
        if (!error.response) {
            return {
                sucesso: false,
                mensagem: "Não foi possível conectar ao servidor."
            }
        } else {
            return {
                sucesso: false,
                mensagem: error.response.data
            }
        }
    }
}
